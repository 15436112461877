import exhibitionApi from '../../../api/exhibition'

const exhibitorMeetings = {
  namespaced: true,
  state: {
    meetings: [],
    loading: 0,
  },
  mutations: {
    addMeetings(state, payload) {
      state.meetings[payload.id] = payload.meetings
    },
    startLoading(state) {
      state.loading++
    },
    stopLoading(state) {
      state.loading--
    },
  },
  actions: {
    async getMeetingsForId({ commit, state }, id) {
      if (!Object.prototype.hasOwnProperty.call(state.meetings, id)) {
        commit('startLoading', id)
        const meetings = await exhibitionApi.getCompanyAccountExhibitionMeetings(id)
        commit('addMeetings', {
          id,
          meetings,
        })
        commit('stopLoading', id)
      }
      return Object.prototype.hasOwnProperty.call(state.meetings, id) ? state.meetings[id] : []
    },
  },
  getters: {
    loading(state) {
      return state.loading
    },
  },
}
export default exhibitorMeetings
