import userApi from '../../api/user'

const userTicket = {
  namespaced: true,
  state: {
    userTicket: null,
    fetchUserPromise: null,
  },
  mutations: {
    setUserTicket(state, payload) {
      state.userTicket = payload
    },
    setFetchPromise(state, payload) {
      state.fetchUserPromise = payload
    },
    resetState(state) {
      state.userTicket = null
      state.fetchUserPromise = null
    },
  },
  actions: {
    async loadUserTicket({ commit, state }, reload = false) {
      if (state.userTicket !== null && !reload) {
        return state.userTicket
      }
      if (state.fetchUserPromise && !reload) {
        return state.fetchUserPromise
      }
      const fetchUserPromise = userApi.getUserTickets().then((uTicket) => {
        if (!uTicket?.status) commit('setUserTicket', uTicket)
      })
      commit('setFetchPromise', fetchUserPromise)
      return fetchUserPromise
    },

    reset({ commit }) {
      commit('resetState')
    },
  },
  getters: {
    userTicket(state) {
      return state.userTicket
    },
  },
}

export default userTicket
