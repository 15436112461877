import Vue from 'vue'

import store from '~/store'
import userState from '../states/user'

export default async function ({ next, router }) {
  const [backendConfig] = store.getters['config/configForKey']('app_config')?.config
  const tickets = store.getters['userTicket/userTicket']
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const preview = store.getters['instancesState/getPreview']
  const user = await userState.getUser(instance)
  const externalAuthCode = Vue.$cookies.get('externalAuthorizationCode')
  if (!preview) {
    if (!backendConfig.OnboardingV2 && backendConfig.defaults?.ticketSync && externalAuthCode === 'null') {
      next()
    }
    if ((backendConfig.OnboardingV2 && localStorage.getItem('isTerminal') !== 'true' && (!tickets || !tickets.length)) || (externalAuthCode && !user?.hasTicket)) {
      router.push({
        name: 'AuthPage',
        params: {
          instance,
          event,
          language,
        },
      })
    } else {
      next()
    }
  } else {
    next()
  }
}
