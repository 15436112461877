import store from '~/store'

export default async function ({ next, router }) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']

  const showVenueConfig = store.getters['config/configForKey']('industry_config').config.defaults || store.getters['config/configForKey']('app_config').config[0].defaults
  if (showVenueConfig?.showVenuePage) {
    next()
  } else {
    router.push({
      name: 'HomePage',
      params: {
        instance,
        event,
        language,
      },
    })
  }
}
