<template>
  <div>
    <article>
      <h1>We&rsquo;ll be back soon!</h1>
      <div>
        <p>
          Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.
          We&rsquo;ll be back online shortly!
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
}
</script>

<style scoped>
  body { text-align: center; padding: 20px; font: 20px Helvetica, sans-serif; color: #333; }
  @media (min-width: 768px){
    body{ padding-top: 150px; }
  }
  h1 { font-size: 50px; }
  article { display: block; text-align: left; max-width: 650px; margin: 0 auto; }
  a { color: #dc8100; text-decoration: none; }
  a:hover { color: #333; text-decoration: none; }
</style>
