import advertisementAPI from '../../api/advertisement'

const advertisement = {
  namespaced: true,
  state: {
    advertisements: null,
    fetchAdvertisementPromise: null,
  },
  mutations: {
    setAdvertisement(state, payload) {
      state.advertisements = payload
    },
    setFetchPromise(state, payload) {
      state.fetchAdvertisementPromise = payload
    },

  },
  actions: {
    async loadAdvertisement({ commit, state }) {
      if (state.advertisements !== null) {
        return state.advertisements
      }
      if (state.fetchAdvertisementPromise) {
        return state.fetchAdvertisementPromise
      }
      const fetchAdvertisementPromise = advertisementAPI.getEventAdvertisement().then((ad) => {
        commit('setAdvertisement', ad)
      })
      commit('setFetchPromise', fetchAdvertisementPromise)
      return fetchAdvertisementPromise
    },
  },
  getters: {
    advertisement(state) {
      return state.advertisements
    },
  },
}

export default advertisement
