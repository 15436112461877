import exhibitionApi from '../../../api/exhibition'

const exhibitorProducts = {
  namespaced: true,
  state: {
    products: [],
    boothItem: null,
    loading: 0,
  },
  mutations: {
    addProducts(state, payload) {
      state.products[payload.id] = payload.products
    },
    addBoothItem(state, payload) {
      state.boothItem = payload
    },
    startLoading(state) {
      state.loading++
    },
    stopLoading(state) {
      state.loading--
    },
  },
  actions: {
    async getProductsForId({ commit, state }, id) {
      if (!Object.prototype.hasOwnProperty.call(state.products, id)) {
        commit('startLoading', id)
        const products = await exhibitionApi.getCompanyAccountExhibitionProducts(id)
        commit('addProducts', {
          id,
          products,
        })
        commit('stopLoading', id)
      }
      return Object.prototype.hasOwnProperty.call(state.products, id) ? state.products[id] : []
    },
    async getVmxBoothItems({ commit, state }, id) {
      // if (state.boothItem != null) {
       
      //  return state.boothItem
       
      // }
        const items = await exhibitionApi.getVmxBoothData(id)
        commit('addBoothItem', items,
        )
      return items
    },
    async getProductPdfUrl({ commit, state }, id) {      
      const items = await exhibitionApi.getProductPdfUrl(id)       
      return items
    },
  },

  getters: {
    loading(state) {
      return state.loading
    },
  },
}
export default exhibitorProducts
