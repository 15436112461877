import apiBase from './base'
import store from '~/store'

async function getEventAdvertisement() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/EventAdvertisement/${eventId}/${locale}`

  const response = await apiBase.get(url)

  if (response && response.status === 200) {
    return response.data
  }
  return null
}

export default {
  getEventAdvertisement,
}
