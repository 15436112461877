import Fuse from 'fuse.js'
import exhibitionApi from '../../../api/exhibition'

const exhibitorProductsAll = {
  namespaced: true,
  state: {
    products: null,
    loading: 0,
    result: [],
    fuseJs: null,
  },
  mutations: {
    addProducts(state, payload) {
      state.products[payload.id] = payload.products
    },
    setProducts(state, payload) {
      state.products = payload
      state.fuseJs = new Fuse(state.products, {
        keys: ['Name', 'Description', 'Category', 'Short', 'Website', 'Tags', 'CompanyName'],
        maxPatternLength: 32,
        minMatchCharLength: 3,
        threshold: 0.3,
      })
    },
    startLoading(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
  },
  actions: {
    async load({
      commit,
      state,
    }) {
      if (!state.loading && state.products == null) {
        commit('startLoading')
        const products = await exhibitionApi.getAllExhibitorProducts()
        commit('setProducts', products)
        commit('stopLoading')
      }
    },
    async search({
      state,
      dispatch,
    }, payload) {
      if (state.products == null) {
        await dispatch('load')
      }
      let result = []
      if (payload && payload.text) {
        const options = {
          keys: [
            'Name',
            'Description',
            'Category',
            'Short',
            'Website',
            'Tags',
            'CompanyName',
          ],
          maxPatternLength: 32,
          minMatchCharLength: 3,
          threshold: 0.3,

        }
        const fuse = new Fuse(state.products, options)
        const fuseResult = fuse.search(payload.text)
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          result.push(fuseResult[i].item)
        }
      } else {
        result = state.products
      }
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'tags')) {
        const { tags } = payload
        result = result.filter((item) => {
          for (let i = 0, len = tags.length; i < len; i += 1) { // TODO: check if for is really needed
            const tag = tags[i]
            if (!item.Tags.includes(tag)) {
              return false
            }
          }
          return true
        })
      }
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'filterBy')) {
        const { filterBy } = payload

        result = result.filter((item) => {
          for (const prop in filterBy) { // TODO: check if for is really needed
            if (Object.prototype.hasOwnProperty.call(item, filterBy[prop])) {
              let filter = filterBy[prop]
              if (Array.isArray(filter)) {
                filter = {
                  [prop]: filter,
                }
              }
              if (Object.prototype.hasOwnProperty.call(filter, 'include')) {
                let isIncluded = false
                for (let i = 0, len = filter.include.length; i < len; i += 1) { // TODO: check if for is really needed
                  const value = filter.include[i]

                  for (const key in item) { // TODO: check if for is really needed
                    if (key === value) {
                      if (item[key]) {
                        isIncluded = true
                      }
                    }
                  }
                  // if (item[prop] === value) {
                  //   isIncluded = true
                  // }
                }
                if (!isIncluded) {
                  return false
                }
              }
              if (Object.prototype.hasOwnProperty.call(filter, 'exclude')) {
                let isExcluded = false
                for (let i = 0, len = filter.exclude.length; i < len; i += 1) { // TODO: check if for is really needed
                  const value = filter.exclude[i]

                  for (const key in item) { // TODO: check if for is really needed
                    if (key === value) {
                      if (item[key]) {
                        isExcluded = true
                      }
                    }
                  }
                }
                if (isExcluded) {
                  return false
                }
              }
            }
          }

          return true
        })
      }

      return result
    },
  },

  getters: {
    products(state) {
      return state.products
    },
    loading(state) {
      return state.loading
    },
  },
}
export default exhibitorProductsAll
