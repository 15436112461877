<template>
  <div class="mobile_banner">
    <Carousel
      v-bind="carouselProps"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Carousel from 'm-anage.com.vmx-components/src/Carousel/index.vue'

export default {
  components: { Carousel },
  methods: {
    ...mapActions({
      loadConfig: 'config/loadConfig',
    }),
  },
  props: {
    carouselProps: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      user: 'user/user',
    }),
  },
}
</script>

<style lang="scss" scoped>
 .mobile_banner{
    background-color: var(--mobile-banner-bg);
  }
</style>
