export default {
  setUser(instance, user) {
    localStorage.setItem(`${instance}.user`, JSON.stringify(user))
  },
  getUser(instance) {
    return JSON.parse(localStorage.getItem(`${instance}.user`))
  },
  unsetUser(instance) {
    localStorage.removeItem(`${instance}.user`)
  },
}
