const appLoadingData = {
  vmx:
[
  {
    appLoadingLogo: 'https://distribute.m-anage.com/from.storage?filecore=EHWv7agXAJZ-veNT29GB3Q',
    backgroundColor: '#226ce0',
    loaderColor: '#546781',
    appLoaderActive: true,
  },
],
  iuis2023:
[
  {
    appLoadingLogo: 'https://fams-distribution.kit-group.org/from.storage?filecore=SHcNM5zjHX9-4fOIdiU70A',
    backgroundColor: '#009de0',
    appLoaderActive: true,
  },
],
  divi23:
[
  {
    appLoadingLogo: 'https://distribute.m-anage.com/from.storage?filecore=jcwKr9COnUmK0odqhO_j0BJ3v_dRX5rRXQ5UhaR9wF4',
    backgroundColor: '#fff',
    loaderColor: '#8b0e14',
    appLoaderActive: true,
  },
],
  dgn2023:
[
  {
    appLoadingLogo: 'https://dgn-distribution.m-anage.com/from.storage?filecore=wqXrl3gI_RoT7nlcwAehNQ',
    backgroundColor: '#0ba1e2',
    loaderColor: '#21256e',
    appLoaderActive: true,
  },
],
  mgroup:
[
  {
    appLoadingLogo: 'https://distribute.m-anage.com/from.storage?filecore=1Ku6VwU78X7v21HQa4Frdw',
    backgroundColor: 'black',
    appLoaderActive: true,
  },
],
  eadvcongress2023:
[
  {
    appLoadingLogo: 'https://eadvdistribute.m-anage.com/from.storage?filecore=i1DJZLpZSoFy5CExvnLxDw',
    backgroundColor: '#12a19a',
    loaderColor: '#322883',
    appLoaderActive: true,
  },
],
  sigmabc23:
[
  {
    appLoadingLogo: 'https://assets.nursingsociety.org/images/Sigma_GNE_250px.png',
    backgroundColor: '#fff',
    loaderColor: '#5e50a1',
    appLoaderActive: true,
  },
],
}

export default appLoadingData
