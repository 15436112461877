import store from '~/store'

export default async function ({ next, router }) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']

  const showVenueConfig = store.getters['config/configForKey']('industry_config').config.defaults || store.getters['config/configForKey']('app_config').config[0].defaults

  if (showVenueConfig?.showVenuePage && !JSON.parse(localStorage.getItem('hasSeenSplashscreen')) && event !== 'dkk2022') {
    localStorage.removeItem('hasSeenSplashscreen')
    router.push({
      name: 'VenuePage',
      params: {
        instance,
        event,
        language,
      },
    })
  } else if (event === 'dkk2022' && JSON.parse(localStorage.getItem('vmx-loaded')) === false) {
    localStorage.setItem('hasSeenSplashscreen', true)
    localStorage.setItem('vmx-loaded', true)
    router.push({
      name: 'VenuePage',
      params: {
        instance,
        event,
        language,
      },
    })
  } else {
    next()
  }
}
