import VueCookies from 'vue-cookies'
import Vue from 'vue'
import store from '~/store'

Vue.use(VueCookies)

const loadScript = (trackerScript, settings = true) => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  if (settings) {
    script.async = true
    script.defer = true
  }
  script.src = trackerScript

  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(script)

  script.onload = resolve
  script.onerror = reject
})

const getMatomo = () => {
  if (window.Piwik) {
    return window.Piwik.getAsyncTracker()
  }
  return null
}
class Tracking {
  #config

  #matomoConfig

  #googleConfig

  #boothguruConfig

  constructor() {
    this.#config = null
    this.#matomoConfig = null
    this.#googleConfig = null
    this.#boothguruConfig = null
  }

  setConfig(payload) { this.#config = payload }

  setGoogleConfig(payload) { this.#googleConfig = payload }

  setMatomoConfig(payload) { this.#matomoConfig = payload }

  setBoothGuruConfig(payload) { this.#boothguruConfig = payload }

  init(newConfig) {
    this.setConfig(newConfig)
    if (Array.isArray(this.#config)) {
      const matomoConfig = this.#config.find((c) => c.provider === 'matomo')
      const googleConfig = this.#config.find((c) => c.provider === 'google-tag-manager')
      const boothguruConfig = this.#config.find((c) => c.provider === 'boothguru')

      this.setMatomoConfig(matomoConfig)
      this.setGoogleConfig(googleConfig)
      this.setBoothGuruConfig(boothguruConfig)
    } else {
      this.setMatomoConfig(newConfig)
      this.setGoogleConfig(newConfig)
      this.setBoothGuruConfig(newConfig)
    }

    return new Promise((resolve) => {
      if (this.#matomoConfig?.provider === 'matomo') {
        if (window._paq) {
          resolve()
          return
        }

        window._paq = []
        window._paq.push(['enableLinkTracking'])
        window._paq.push(['setTrackerUrl', this.#matomoConfig.settings.trackUrl])
        window._paq.push(['setSiteId', this.#matomoConfig.settings.siteId])
        if (this.#matomoConfig.settings.disableCookies) {
          window._paq.push(['disableCookies'])
        }

        loadScript(this.#matomoConfig.settings.clientScriptUrl).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        })
      }
      if (this.#boothguruConfig?.provider === 'boothguru') {
        store.commit('instancesState/setBoothGuru', true)
        // loadScript('/assets/boothGuru/bg-integration_latest.js').then(() => {
        if (this.checkScript(this.#boothguruConfig.settings.boothGuruUrl)) {
          loadScript(this.#boothguruConfig.settings.boothGuruUrl, false).then(() => {
            resolve()
          }).catch(() => {
            resolve()
          })
        }
        resolve()
      }
      if (this.#googleConfig?.provider === 'google-tag-manager') {
        const gaId = this.#googleConfig.settings.id
        const analyticsCode = this.#googleConfig.settings?.analyticsCode
        const analyticsCodeActive = this.#googleConfig.settings?.analyticsCodeActive

        const disableStr = `ga-disable-${gaId}`
        const dateAdd = new Date(new Date().setDate(new Date().getUTCDate() + 14))
        const cookieExpirationDate = `expires=+${dateAdd}`
        const event = store.getters['instancesState/getEvent']

        if (Vue.$cookies.get(`${event}.cookie`)) {
          if (Vue.$cookies.get(`${event}.cookie`) == null || Vue.$cookies.get(`${event}.cookie`) === 'declined') {
            document.cookie = `${disableStr}=true;  ${cookieExpirationDate}; path=/`
            window[disableStr] = true
          } else {
            document.cookie = `${disableStr}=false; ${cookieExpirationDate}; path=/`
            window[disableStr] = false
          }
          if (document.cookie.indexOf(`${disableStr}=true`) > -1) {
            window[disableStr] = true
          }
        } else {
          window[disableStr] = true
        }
        if (analyticsCode && analyticsCodeActive) {
          loadScript(`https://www.googletagmanager.com/gtag/js?id=${analyticsCode}`).then(() => {
            resolve()
          }).catch(() => {
            resolve()
          })
          window.dataLayer = window.dataLayer || []
          const gtag2 = function () {
            window.dataLayer.push(arguments)
          }
          gtag2('js', new Date())
          gtag2('config', gaId, {
            send_page_view: false,
          })
          window.gtag2 = gtag2
        }
        loadScript(`https://www.googletagmanager.com/gtag/js?id=${gaId}`).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        })
        window.dataLayer = window.dataLayer || []
        const gtag = function () {
          window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', gaId, {
          send_page_view: false,
        })
        window.gtag = gtag
      }
    })
  }

  checkScript(script) {
    return !$(`script[src='${script}']`).length > 0
  }

  trackPageView(customPageTitle, pagePath) {
    if ((typeof this.#config === 'object' || Array.isArray(this.#config)) && this.#config !== null) {
      if (this.#matomoConfig?.provider === 'matomo') {
        const matomo = getMatomo()
        if (matomo) {
          matomo.trackPageView(customPageTitle)
        }
      }

      if (this.#googleConfig?.provider === 'google-tag-manager') {
        const gaId = this.#googleConfig.settings.id
        const analyticsCode = this.#googleConfig.settings?.analyticsCode
        const analyticsCodeActive = this.#googleConfig.settings?.analyticsCodeActive
        if (window.gtag2 && analyticsCodeActive) {
          window.gtag2('config', analyticsCode, {
            send_page_view: true,
            page_path: pagePath,
            page_title: customPageTitle,
          })
        }
        if (window.gtag && !window.gtag2) {
          window.gtag('config', gaId, {
            send_page_view: true,
            page_path: pagePath,
            page_title: customPageTitle,
          })
        }
      }
    }
  }


  trackEvent(category, action, name) {
    if ((typeof this.#config === 'object' || Array.isArray(this.#config)) && this.#config !== null) {
      if (this.#matomoConfig?.provider === 'matomo') {
        const matomo = getMatomo()
        if (matomo) {
          matomo.trackEvent(category, action, name)
        }
      }
      if (this.#googleConfig?.provider === 'google-tag-manager') {
        if (window.gtag) {
          window.gtag('event', action, {
            event_category: category,
            event_label: name,
          })
        }
      }
    }
  }
}

export default new Tracking()
