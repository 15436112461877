import apiBase from './base'
import store from '~/store'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK === 'true' : false
const setName = 'VMXTheme'

async function getTheme() {
  let url = ''
  let themeConfig = null
  if (useApiMockData) {
    url = '/api-mock/theme.json'
    const response = await apiBase.get(url)
    themeConfig = response.data
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/GetResources?eventshort=${store.getters['instancesState/getEvent']}&setname=config_json&language=${store.getters['instancesState/getLanguage']}`
    const response = await apiBase.get(url)
    const filteredConfig = response.data.filter((industry) => industry.resourcename === 'theme')
    themeConfig = JSON.parse(filteredConfig[0].resourcevalue)
  }

  return themeConfig
}

async function getThemeStyles(event, locale) {
  const data = {}
  if (useApiMockData) {
    const eventName = `${store.getters['instancesState/getEvent']}`
    let url = '/api-mock/themes.css'
    url = `/api-mock/${eventName}'/themes.css`
    const response = await apiBase.get(url)
    data.css = response.data
  } else {
    const url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/GetResources?eventshort=${event}&setname=${setName}&language=${locale}`
    const response = await apiBase.get(url)
    const resourcesList = response.data
    for (let i = 0, len = resourcesList.length; i < len; i += 1) {
      data[resourcesList[i].resourcename] = resourcesList[i].resourcevalue
    }
  }
  return data
}

export default {
  getTheme,
  getThemeStyles,
}
