import apiBase from './base'
import store from '../store'

const injectable = () => ({
  language: store.getters['instancesState/getLanguage'],
  eventId: store.getters['instancesState/getEventId'],
  eventShortname: store.getters['instancesState/getEvent'],
  userId: store.getters['user/user']?.UserId,
  apiBase: store.getters['instancesState/getApiBaseUrl'],
  surveyApiBase: store.getters['instancesState/getSurveyApi'],
})

const uniFetch = (pathStructure, ...dynamicParams) => {
  let resolvedParams = []
  if (dynamicParams && dynamicParams.length) {
    resolvedParams = dynamicParams.map((item) => {
      if (typeof item === 'function') {
        return item(injectable())
      }
      return item
    })
  }
  const path = pathStructure.reduce((acc, val, idx, arr) => {
    let mergedPathWithParam = `${val}`
    if (idx !== arr.length - 1 && resolvedParams[idx]) {
      mergedPathWithParam = `${mergedPathWithParam}${resolvedParams[idx]}`
    }
    return `${acc}${mergedPathWithParam}`
  }, '')
  return async ({
    query = {},
    method = 'get',
    data,
  } = {}) => {
    const setQuery = Object.keys(query).map((prop) => {
      if (typeof query[prop] === 'function') {
        return `${prop}=${query[prop](injectable())}`
      }
      return `${prop}=${query[prop]}`
    })
    const url = `${path}?${setQuery.join('&')}`
    const response = await apiBase[method](url, data)
    if (response && response.status === 200) {
      return response.data
    }
    return null
  }
}

export default uniFetch
