import LinkBox from 'm-anage.com.vmx-components/src/LinkBox/index.vue'
import ImageMap from 'm-anage.com.vmx-components/src/ImageMap/index.vue'

import { mapActions, mapGetters } from 'vuex' // mapGetters
import TwitterFeedHome from '~/components/home/TwitterFeedHome.vue'
import ProgramDaysBox from '~/components/home/ProgramDaysBox.vue'
import Carousel from 'm-anage.com.vmx-components/src/Carousel/index.vue'
import CompanyLogoGrid from '~/components/home/CompanyLogoGrid.vue'
import HighlightedSessions from '~/components/home/HighlightedSessions.vue'
import HighlightedSessionsV2 from 'm-anage.com.vmx-components/src/Program/HighlightedSessionsV2.vue'
import UiContentBox from '~/components/UiContentBox.vue'

const componentPageMixins = {
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  methods: {
    ...mapActions({
      loadConfig: 'config/loadConfig',
    }),
    gridProps(grid) {
      if (grid.props) {
        return grid.props
      }
      return {}
    },
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      user: 'user/user',
    }),
  },
  data() {
    return {
      grids: [],
      headline: {},
      name: this.$route.name,
    }
  },
  components: {
    Timer: () => import('~/components/Timer.vue'),
    LinkBox,
    ImageMap,
    ProgramDaysBox,
    ComponentGrid: () => import('m-anage.com.vmx-components/src/GridComponent/ComponentGrid/index.vue'),
    ComponentGridItem: () => import('m-anage.com.vmx-components/src/GridComponent/ComponentGridItem/index.vue'),
    MiniGridComponent: () => import('m-anage.com.vmx-components/src/MiniGridComponent/index.vue'),
    Carousel,
    CompanyLogoGrid,
    HighlightedSessions,
    TwitterFeedHome,
    HighlightedSessionsV2,
    UiContentBox,
    metaData: () => import('m-anage.com.vmx-components/src/MetaDataFilter/index.vue'),
    twitterFeed: () => import('~/components/news/twitterFeed.vue'),
    JWPlayer: () => import('~/components/JWPlayer.vue'),
    rssFeed: () => import('~/components/news/rssFeed.vue'),
    Accordion: () => import('m-anage.com.vmx-components/src/Accordion/index.vue'),
    EsmoVideoPlayer: () => import('~/components/esmoVideoPlayer.vue'),
    EmbeddedVideo: () => import('~/components/EmbeddedVideo.vue'),
    SymposiaSlider: () => import('~/components/industryLandingPage/symposiaListSlider.vue'),
    ExhibitorSlider: () => import('~/components/industryLandingPage/exhibitorListSlider.vue'),
    ProductsSlider: () => import('~/components/industryLandingPage/productListSlider.vue'),
    HighlightedSessionsSliderV2: () => import('~/components/industryLandingPage/highlightedSessionsSliderV2.vue'),
    LinkBoxSlider: () => import('~/components/industryLandingPage/LinkboxSlider.vue'),
    FreshChat: () => import('~/components/FreshChat.vue'),
    CustomTabComponents: () => import('~/components/CustomTabComponents.vue')

  },
}

export default componentPageMixins
