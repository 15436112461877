import pushnotificationsAPi from '../../api/pushnotifications'

const pushnotification = {
  namespaced: true,
  state: {
    pushnotifications: null,
    fetchPushnotificationsPromise: null,
  },
  mutations: {
    setPushnotification(state, payload) {
      state.pushnotifications = payload
    },
    setFetchPromise(state, payload) {
      state.fetchPushnotificationsPromise = payload
    },

  },
  actions: {
    async loadPushnotification({ commit, state }) {
      if (state.pushnotifications !== null) {
        return state.pushnotifications
      }
      if (state.fetchPushnotificationsPromise) {
        return state.fetchPushnotificationsPromise
      }
      const fetchPushnotificationsPromise = pushnotificationsAPi.getEventPushnotifications().then((ad) => {
        commit('setPushnotification', ad)
      })
      commit('setFetchPromise', fetchPushnotificationsPromise)
      return fetchPushnotificationsPromise
    },
  },
  getters: {
    pushnotification(state) {
      return state.pushnotifications
    },
  },
}

export default pushnotification
