import dayjs from 'dayjs'

import EventBus from '~/states/event-bus'
import headerModel from '~/utilities/headerModel'
import config from '../config'
import utils from '../utilities/utils'
import store from '../store'

require('dayjs/locale/de')

// TODO: remove next year, already in config
const eventWithPublicProgram = [
  'otworld20',
  'ers2021',
]

let disableAuthForPages = [
  'ProgramPage',
  'PosterPage',
]

let disableAuthForAppPages = []

export default async function ({ next, to, router }) {
  const { event } = to.params
  const { language } = to.params
  const { companyId } = to.params
  const moduleName = to.name
  const { query } = to
  if (query && query.container && query.container === 'app') {
    localStorage.setItem('isApp', true)
    if (event === 'dkk2022') {
      localStorage.removeItem('vmx-loaded')
    }
  } else if (query && query.container && query.container === 'terminal') {
    localStorage.setItem('isTerminal', true)
  } else {
    localStorage.setItem('isVMX', true)
  }

  if (localStorage.getItem('isApp') === 'true') {
    store.commit('instancesState/setIsAppContainer', true)
  } else if (localStorage.getItem('isTerminal') === 'true') {
    localStorage.setItem('isTerminal', true)
    store.commit('instancesState/setIsTerminalContainer', true)
  } else {
    store.commit('instancesState/setInVMX', true)
  }
  let instanceConfig = null
  const domain = utils.getDomain()
  const domainConfig = config.getDomainConfig(domain)
  let instance = null
  const instanceUrlParam = to.params.instance.toLowerCase()
  if (domainConfig && !instanceUrlParam) {
    instance = domainConfig.instance
  } else {
    instance = instanceUrlParam
  }

  if (instance !== null) {
    instanceConfig = config.getInstanceConfig(instance)
  }

  if (instanceConfig === null) {
    router.push({ name: 'not-found' })
    return
  }
  EventBus.$on('historyBack', (params) => {
    store.commit('instancesState/setHistoryBack', params)
  })
  store.commit('instancesState/setMaintenance', false)
  store.commit('instancesState/setInstanceUrlParam', instanceUrlParam)
  store.commit('instancesState/setInstance', instance)
  store.commit('instancesState/setEvent', event)
  store.commit('instancesState/setLanguage', language)
  store.commit('instancesState/setCompanyId', companyId)
  store.commit('instancesState/setApiBaseUrl', instanceConfig.apiBaseUrl)
  store.commit('instancesState/setIdentityUrl', instanceConfig.identityUrl)
  store.commit('instancesState/setMTVApiBaseUrl', instanceConfig.mtv.baseApi)
  store.commit('instancesState/setSurveyApi', instanceConfig.surveyApi)
  store.commit('instancesState/setModuleName', moduleName)
  if (!store.getters['instancesState/getHistoryBack']) {
    store.commit('instancesState/setHistory', to)
  }
  const { authConfig } = config
  await store.dispatch('config/loadConfig')

  if (language.substring(0, 2) === 'de') require('dayjs/locale/de')
  if (language.substring(0, 2) === 'fr') require('dayjs/locale/fr')
  if (language.substring(0, 2) === 'it') require('dayjs/locale/it')

  if (Object.prototype.hasOwnProperty.call(query, 'preview') || Object.prototype.hasOwnProperty.call(query, 'previewLounge')) {
    const preview = query.preview || query.previewLounge
    store.commit('instancesState/setPreview', (preview === 'true'))
    if (query.previewLounge) {
      store.commit('instancesState/setLoungePreview', (preview === 'true'))
    }
  }

  // TODO: put this in config
  const backendConfig = store.getters['config/configForKey']('app_config')?.config[0]
  let allPagesWithAuth = true
  if (backendConfig.defaults?.allPagesWithAuth) {
    allPagesWithAuth = backendConfig.defaults.allPagesWithAuth
  }
  if (allPagesWithAuth) {
    authConfig.push('HomePage')
  }

  let withPublicProgram = false
  if (backendConfig.defaults?.withPublicProgram) withPublicProgram = backendConfig.defaults.withPublicProgram

  let disableAuth = false
  if (backendConfig.defaults?.disableAuth) disableAuth = backendConfig.defaults.disableAuth

  if (backendConfig.defaults?.disableAuthForPages) disableAuthForPages = backendConfig.defaults.disableAuthForPages

  let disableAuthPagesForApp = ('disableAuthPagesForApp' in backendConfig?.defaults) ?  backendConfig.defaults.disableAuthPagesForApp : false


  let disableAuthForApp = false

  if (backendConfig.defaults?.disableAuthForAppPages) disableAuthForAppPages = backendConfig.defaults.disableAuthForAppPages

  let disableAuthForTerminal = false
  if (backendConfig.defaults?.disableAuthForApp) disableAuthForApp = backendConfig.defaults.disableAuthForApp
  if (backendConfig.defaults?.disableAuthForTerminal) disableAuthForTerminal = backendConfig.defaults.disableAuthForTerminal
  const app = document.getElementById('app')
  await headerModel()

  if (app.classList.contains('app_container')) localStorage.setItem('isApp', app.classList.contains('app_container'))
  if (app.classList.contains('terminal_container')) localStorage.setItem('isTerminal', app.classList.contains('terminal_container'))

  if (eventWithPublicProgram.includes(event)
  || withPublicProgram
  || disableAuth
  || (disableAuthForApp && localStorage.getItem('isApp') === 'true')
  || (disableAuthForTerminal && localStorage.getItem('isTerminal') === 'true')
  ) {
    if (disableAuthPagesForApp && localStorage.getItem('isApp') === 'true') {
      disableAuthForAppPages.forEach((page) => {
        const index = authConfig.indexOf(page)
        if (index > -1) {
          authConfig.splice(index, 1)
        }
      })
    } else {
      disableAuthForPages.forEach((page) => {
        const index = authConfig.indexOf(page)
        if (index > -1) {
          authConfig.splice(index, 1)
        }
      })
    }
  }

  dayjs.locale(language.substring(0, 2))
  store.commit('instancesState/setHistoryBack', false)
  // load header model
  next()
}
