<script>
import styled from '@u-rogel/vue-emotion'

export default styled.div`
  background: ${({ background }) => background || '#fff'};
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    background-size: cover;
    background-repeat: no-repeat;
  }
`({
  background: String,
  breakpoint: Number,
})
</script>
