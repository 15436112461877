<template>
  <div v-if="freshworksUrl" />
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '~/utilities/utils'

export default {
  data() {
    return {}
  },
  mounted() {
    if (this.freshworksUrl) {
      this.loadFreshworksSettings()
      // eslint-disable-next-line max-len
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
      utils.loadScript(this.freshworksUrl, null, { defer: 'defer', async: '' })
      if (this.$route.name === 'IndustryLandingPage' || this.$route.name === 'IndustryLandingPageProfile' || this.$route.name === 'ProfilePage' || this.$route.name === 'IndustryLanding') {
        let time = 1
        const interval = setInterval(() => {
          if (!this.freshworksStatus() && time <= 10) {
            time += 1
          } else {
            document.getElementById('freshworks-container').setAttribute('hidden', 'true')
            clearInterval(interval)
          }
        }, 1000)
      }
    }
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
    }),
    freshworksConfig() {
      const [conf] = this.configForKey('app_config').config
      return conf?.freshworks
    },
    freshworksUrl() {
      if (this.freshworksConfig?.url) {
        return this.freshworksConfig.url
      }
      return null
    },
  },
  methods: {
    loadFreshworksSettings() {
      if (this.freshworksConfig?.id) {
        window.fwSettings = { widget_id: this.freshworksConfig.id }
      }
    },
    freshworksStatus() {
      return !!document.getElementById('freshworks-container')
    },
  },
  watch: {
    $route: {
      async handler() {
        if (this.freshworksConfig) {
          const element = document.getElementById('freshworks-container')
          if (element && (this.$route.name === 'IndustryLandingPage' || this.$route.name === 'IndustryLandingPageProfile' || this.$route.name === 'ProfilePage')) {
            element.setAttribute('hidden', 'true')
          } else if (element) {
            element.removeAttribute('hidden')
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
