import configApi from '~/api/config'

const moduleConfig = {
  namespaced: true,
  state: {
    token: '',
    baseUrl: '',
    oauthUrl: '',
    apiUrl: '',
    clientId: '',
    event: '',
    identityUrl: '',
    instance: '',
    language: '',
    eventShort: '',
    context: '',
    returnUrl: '',
  },
  mutations: {
    setToken: (state, payload) => { state.token = payload.token },
    setEventId: (state, payload) => {
      state.eventId = payload
    },

    setUrlConfigs: (state, payload) => {
      state.baseUrl = payload.baseUrl
      state.oauthUrl = payload.oauthUrl
      state.apiUrl = payload.apiUrl
      state.clientId = payload.clientId
      state.identityUrl = payload.identityUrl
      state.instance = payload.instance
      state.language = payload.language
      state.eventShort = payload.eventShort
      state.context = payload.context
      state.returnUrl = payload.returnUrl
      state.event = payload.event
    },
  },
  actions: {
    async loadEventInformation({ commit }) {
      const eventInfo = configApi.getEventInformation().then((data) => {
        commit('setEventId', data.Id)
      })
      return eventInfo
    },
  },
  getters: {
    moduleConfig(state) {
      return state
    },
  },
}

export default moduleConfig
