import dayjs from 'dayjs'

import Vue from 'vue'
import VueCookies from 'vue-cookies'

import config from '~/config'
import store from '~/store'
import userState from '~/states/user'
import tracking from '~/utilities/tracking'

Vue.use(VueCookies)

export default {
  addProtocolToUrlIfMissing(url, secure = true) {
    const proto = secure ? 'https://' : 'http://'
    let u = url
    if (!url.startsWith('http')) {
      u = proto + url
    }
    return u
  },
  getUrlParameter(queryString, name) {
    const n = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp(`[\\?&]${n}=([^&#]*)`)
    const results = regex.exec(queryString)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  },
  redirectOAuthAuthorize(to) {
    let redirectUri = new URL(window.location.origin + to.path)

    if (to.name === 'LoginPage') {
      redirectUri = new URL(`${window.location.origin}/home/${to.params.instance}/${to.params.event}/${to.params.language}`)
    }
    for (const name in to.query) { // TODO: check if for is really needed
      if (Object.prototype.hasOwnProperty.call(to.query, name)) {
        redirectUri.searchParams.append(name, to.query[name])
      }
    }
    redirectUri.searchParams.append('state', '123')

    const instanceConfig = config.getInstanceConfig(store.getters['instancesState/getInstance'])

    userState.unsetUser(store.getters['instancesState/getInstance'])
    store.commit('instancesState/setToken', null)

    const { authorizeUrl } = instanceConfig.oauth2
    const params = {
      client_id: instanceConfig.oauth2.clientId,
      redirect_uri: redirectUri,
      scope: instanceConfig.oauth2.scope,
      response_type: 'token',
    }
    const queryString = Object.keys(params).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
    window.location.href = `${authorizeUrl}?${queryString}`
  },
  formatConvenorModelList(unformattedList) {
    const formattedList = []
    // TODO: check if for is really needed
    for (let i = 0, len = unformattedList.length; i < len; i += 1) {
      formattedList.push({
        value: unformattedList[i].Id,
        text: unformattedList[i].Name,
      })
    }
    return formattedList
  },
  formatChairList(unformattedList) {
    const formattedList = []
    // TODO: check if for is really needed
    for (let i = 0, len = unformattedList.length; i < len; i += 1) {
      formattedList.push({
        value: unformattedList[i].Key,
        text: unformattedList[i].Name,
      })
    }
    return formattedList
  },
  formatCompanyDatabaseList(unformattedList) {
    const formattedList = []
    // TODO: check if for is really needed
    for (let i = 0, len = unformattedList.length; i < len; i += 1) {
      formattedList.push({
        company: unformattedList[i],
      })
    }
    return formattedList
  },
  formatSearchResult(unformattedList) {
    const formattedList = []
    // TODO: check if for is really needed
    for (let i = 0, len = unformattedList.length; i < len; i += 1) {
      formattedList.push(
        unformattedList[i].item,
      )
    }
    return formattedList
  },
  getEventId(data) {
    const response = data
    return response.EventId
  },
  getSession(eventData) {
    const response = eventData
    return response[0].Id
  },
  getCompanyId(data) {
    const response = data
    return response.CompanyId
  },
  corsProxy() {
    const proxy = ''
    return proxy
  },
  formButtonDeactivate(status) {
    if (status === 'Done' || status === 'Accepted' || status === 'Rejected') {
      const buttons = document.getElementsByTagName('button')
      // TODO: check if for is really needed
      for (let i = 0; i < buttons.length; i += 1) {
        const button = buttons[i]
        button.style.visibility = 'hidden'
      }
    }
  },
  nameToCssClassName(name) {
    return name.toLowerCase().replace(/\s/g, '_').replace(/[^a-z_]/g, '')
  },
  nl2br(str) {
    const breakTag = '<br>'
    return String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`)
  },
  ObjectHasNestedProperty(object, propertyPath) {
    if (!propertyPath) {
      return false
    }

    const properties = propertyPath.split('.')
    let obj = object

    // TODO: check if for is really needed
    for (let i = 0; i < properties.length; i += 1) {
      const prop = properties[i]

      if (!obj || !Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false
      }
      obj = obj[prop]
    }
    return true
  },
  changeFavicon(src) {
    const link = document.createElement('link')
    const oldLink = document.getElementById('dynamic-favicon')
    link.id = 'dynamic-favicon'
    link.rel = 'icon'
    link.href = src
    if (oldLink) {
      document.head.removeChild(oldLink)
    }
    document.head.appendChild(link)
  },
  atoz(a, b) {
    // Use toUpperCase() to ignore character casing

    let comparison = 0
    if (a > b) {
      comparison = 1
    } else if (a < b) {
      comparison = -1
    }
    return comparison
  },
  ascendingLabel(a, b) {
    // Use toUpperCase() to ignore character casing
    let productA
    let productB

    if (a.label != null) {
      productA = a.label.toUpperCase()
    }
    if (b.label != null) {
      productB = b.label.toUpperCase()
    }
    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison
  },
  ascending(a, b) {
    // Use toUpperCase() to ignore character casing
    let productA
    let productB

    if (a.Name != null) {
      productA = a.Name.toUpperCase()
    }
    if (b.Name != null) {
      productB = b.Name.toUpperCase()
    }
    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison
  },
  ascendingCompanyName(a, b) {
    // Use toUpperCase() to ignore character casing
    let productA
    let productB

    if (a.CompanyName != null) {
      productA = a.CompanyName.toUpperCase()
    }
    if (b.CompanyName != null) {
      productB = b.CompanyName.toUpperCase()
    }
    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison
  },
  descending(a, b) {
    // Use toUpperCase() to ignore character casing
    let productA
    let productB

    if (a.Name != null) {
      productA = a.Name.toUpperCase()
    }
    if (b.Name != null) {
      productB = b.Name.toUpperCase()
    }
    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison * -1
  },
  descendingCompanyName(a, b) {
    // Use toUpperCase() to ignore character casing
    let productA
    let productB

    if (a.CompanyName != null) {
      productA = a.CompanyName.toUpperCase()
    }
    if (b.CompanyName != null) {
      productB = b.CompanyName.toUpperCase()
    }
    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison * -1
  },
  rateAscending(a, b) {
    // Use toUpperCase() to ignore character casing
    const productA = a.Rating
    const productB = b.Rating

    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison
  },
  rateDescending(a, b) {
    const productA = a.Rating
    const productB = b.Rating

    let comparison = 0
    if (productA > productB) {
      comparison = 1
    } else if (productA < productB) {
      comparison = -1
    }
    return comparison * -1
  },
  getDomain() {
    return window.location.hostname
  },
  isUrl(link) {
    if (!this.isString(link)) {
      return false
    }
    return link.startsWith('http')
  },
  getRouteWithDefaultParams(name, params = {}, query = {}) {
    const route = {
      name,
      params,
      query,
    }
    return this.appendDefaultParamsToRoute(route)
  },
  appendDefaultParamsToRoute(route) {
    const r = route
    const mergedParams = {
      instance: store.getters['instancesState/getInstanceUrlParam'],
      event: store.getters['instancesState/getEvent'],
      language: store.getters['instancesState/getLanguage'],
    }
    Object.assign(mergedParams, r.params)
    r.params = mergedParams
    return r
  },
  isObject(obj) {
    const type = typeof obj
    return type === 'function' || type === 'object' && !!obj
  },
  isString(str) {
    return Object.prototype.toString.call(str) === '[object String]'
  },
  queryString(params) {
    const queryString = Object.keys(params).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
    return queryString
  },
  // compare 2 objects with without nested objects
  shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false
      }
    }

    return true
  },
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i -= 1) { // TODO: check if for is really needed
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
  },

  getCETorCESTDate() {
    const localDate = new Date()
    const utcOffset = localDate.getTimezoneOffset()
    const cetOffset = utcOffset + 60
    const cestOffset = utcOffset + 120
    const cetOffsetInMilliseconds = cetOffset * 60 * 1000
    const cestOffsetInMilliseconds = cestOffset * 60 * 1000
    const cestDateStart = new Date()
    const cestDateFinish = new Date()
    const localDateTime = localDate.getTime()
    let result
    cestDateStart.setTime(Date.parse(`29 March ${localDate.getFullYear()} 02:00:00 GMT+0100`))
    cestDateFinish.setTime(Date.parse(`25 October ${localDate.getFullYear()} 03:00:00 GMT+0200`))
    const cestDateStartTime = cestDateStart.getTime()
    const cestDateFinishTime = cestDateFinish.getTime()
    if (localDateTime >= cestDateStartTime && localDateTime <= cestDateFinishTime) {
      result = new Date(localDateTime + cestOffsetInMilliseconds)
    } else {
      result = new Date(localDateTime + cetOffsetInMilliseconds)
    }
    return result
  },
  getObjKey(key, obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return obj[key]
    }
    return null
  },

  appendStyletoHead(css) {
    const headerStyle = css

    const style = document.createElement('link')
    style.type = 'text/css'
    style.rel = 'stylesheet'
    style.href = headerStyle
    document.head.appendChild(style)
  },

  addTicketCookie(userTicket) {
    let bookingString = ''
    let isHcp = ''
    const accessDays = []
    function getDaysArray(s, e) {
      const a = []
      const d = new Date(s)
      // TODO: check if for is really needed
      for (; d <= e; d.setDate(d.getDate() + 1)) {
        a.push(new Date(d))
      }
      return a
    }

    if (userTicket && userTicket.length > 0) {
      userTicket.forEach((itemKey) => {
        bookingString = itemKey.TicketTypeName ? `${bookingString}manage_admission_valid_booked_ticket|${itemKey.TicketTypeName};` : ''
        if (itemKey.TicketFrom && itemKey.TicketTo) {
          const daylist = getDaysArray(new Date(itemKey?.TicketFrom.split('T')[0]), new Date(itemKey?.TicketTo.split('T')[0]))
          accessDays.push(daylist.map((v) => v.toISOString().slice(0, 10)).join(',').split(','))
        }
      })
    }

    if (userTicket && userTicket.length > 0 && userTicket[0].EnableHCP) {
      isHcp = userTicket[0].EnableHCP
      Vue.$cookies.set('hcp', isHcp)
    } else {
      Vue.$cookies.set('hcp', false)
    }

    Vue.$cookies.set('accessOnDays', JSON.stringify(accessDays))
    if (bookingString) Vue.$cookies.set('tickets', bookingString)
  },

  advancedTracking() {
    let tConfig = null
    if (store.getters['config/configForKey']('app_config')) {
      [tConfig] = store.getters['config/configForKey']('app_config').config
    }
    if (tConfig[0] && tConfig[0].advancedUserTracking) {
      return tConfig[0].advancedUserTracking
    }
    return false
  },
  advancedTrackingFormat(userId, source, element, elementInfo) {
    let eInfo = ''
    const valueToM = 23571113
    const mUserId = parseInt(userId, 10) + valueToM
    if (elementInfo) {
      eInfo = `, "ElementInfo": "${elementInfo}"`
    }
    return `"UserId": "${mUserId}", "Date": "${dayjs().format('YYYY-MM-DD')}", "Time": "${dayjs().format('hh:mm')
    }", "Source": "${source}", "Element": "${element}"${eInfo}`
  },
  trackPageOpen(user, pageName) {
    if (this.advancedTracking()) {
      tracking.trackEvent(
        pageName,
        'Open',
        this.advancedTrackingFormat(
          user.UserId,
          'Page',
          pageName,
        ),
      )
    }
  },
  trackPageLeave(user, pageName) {
    if (this.advancedTracking()) {
      tracking.trackEvent(
        pageName,
        'Leave',
        this.advancedTrackingFormat(
          user.UserId,
          'Page',
          pageName,
        ),
      )
    }
  },

  setPageTitle(pageName) {
    const newPageTitles = store.getters['config/configForKey']('app_config').config[0].PageTitles
    if (newPageTitles) {
      if (Object.prototype.hasOwnProperty.call(newPageTitles, pageName)) {
        return newPageTitles[pageName]
      }
    }
    return null
  },

  loadScript(url, timeout, additionalProps = null) {
    let time = 0
    if (timeout) {
      time = timeout
    }
    setTimeout(() => {
      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      if (additionalProps && Object.keys(additionalProps).length > 0 && additionalProps.constructor === Object) {
        Object.entries(additionalProps).forEach((item) => {
          script.setAttribute(item[0], item[1])
        })
      }
      script.setAttribute('src', url)
      document.body.appendChild(script)
    }, time)
  },

  loadStylesheet(url) {
    const link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('href', url)
    document.head.appendChild(link)
  },

  deleteScript(url) {
    if (document.querySelector(`script[src="${url}"]`)) {
      document.querySelector(`script[src="${url}"]`).remove()
    }
  },

  showHome() {
    document.getElementById('app').classList.remove('hasSplashscreen')
    if (!document.getElementById('app').classList.contains('par21')) {
      localStorage.setItem('hasSeenSplashscreen', 'true')
    }
    window.openAd()
    window.setImageMapHeader()
  },
  localStringFormatter(string) {
    const languageRegex = /^([a-zA-Z]{2})-([a-zA-Z]{2})$/
    let locale = string
    if (locale && languageRegex.test(locale)) {
      locale = `${locale.substring(0, 2).toLowerCase()}-${locale.substring(3, 5).toUpperCase()}`
      return locale
    }
    return string
  },
}
