import Vue from 'vue'
import VueI18n from 'vue-i18n'

import localeApi from '../api/locale'

const localeDefaultMapping = {
  de: 'de-DE',
  en: 'en-US',
}

Vue.use(VueI18n)

const locale = navigator.language || navigator.userLanguage
const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
})

const loadedLocale = []
const loadedSet = []

function formatLocale(lang) {
  let l
  if (lang.length === 2) {
    if (Object.prototype.hasOwnProperty.call(localeDefaultMapping, lang)) {
      l = localeDefaultMapping[lang]
    } else {
      l = `${lang}-${lang.toUpperCase()}`
    }
  }
  return l
}

function getNavigatorLanguage() {
  // return array of preferred language
  let l = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US'
  if (navigator.languages && navigator.languages.length) {
    l = navigator.languages
    return formatLocale(l[0])
  }
  return formatLocale(l)
}

function loadLocaleAsync(lang, resourceSet) {
  if (loadedLocale.includes(lang) && loadedSet.includes(resourceSet)) {
    return localeApi.getTexts(lang, resourceSet).then((texts) => {
      i18n.setLocaleMessage(lang, texts)
      i18n.locale = lang
    })
  }

  return localeApi.getTexts(lang, resourceSet).then((texts) => {
    if (!loadedLocale.includes(lang)) {
      loadedLocale.push(lang)
    }
    if (!loadedSet.includes(resourceSet)) {
      loadedSet.push(resourceSet)
    }
    i18n.setLocaleMessage(lang, texts)
    i18n.locale = lang
  })
}

export default {
  i18n,
  loadLocaleAsync,
  getNavigatorLanguage,
  formatLocale,
}
