import serverTimeApi from '~/api/serverTime'

const serverTime = {
  namespaced: true,
  state: {
    serverTime: null,
    fetchPromise: null,
  },
  mutations: {
    setServerTime(state, payload) {
      state.serverTime = payload
    },
    setFetchPromise(state, payload) {
      state.fetchPromise = payload
    },
  },
  actions: {
    async loadServerTime({ commit }) {
      const fetchPromise = serverTimeApi.getServerTime().then((time) => {
        commit('setServerTime', time)
      })
      commit('setFetchPromise', fetchPromise)
      return fetchPromise
    },
  },
  getters: {
    getTime(state) {
      return state.serverTime
    },
  },
}

export default serverTime
