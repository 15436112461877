export default async function ({ next, to }) {
  const pagPages = {
    PosterPage: 'PagPosterSearchPage',
    IndustrySymposiaPage: 'PagIndustrySearchPage',
    FacultySearchPage: 'PagFacultySearchPage',
    AbstractSearchPage: 'PagAbstractSearchPage',
    PresentationSearchPage: 'PagPresentationSearchPage',
  }
  let page = to.name
  if (Object.prototype.hasOwnProperty.call(pagPages, to.name)) {
    page = pagPages[to.name]
  }
  localStorage.setItem('nextPage', page)
  next()
}
