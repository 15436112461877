import contactFormApi from '../../api/contactForm'

const contactForm = {
  namespaced: true,
  state: {
    emailSent: null,
    comments: null,
    presentationComment: null,
    sessionComment: null,
    commentLike: null,
    inappropriateComment: null,
  },
  mutations: {
    sendEmailRequest: (state, payload) => {
      state.emailSent = payload
    },
    getCommentRequest: (state, payload) => {
      state.comments = payload
    },
    createCommentRequest: (state, payload) => {
      state.sessionComment = payload
    },
    createPresentationRequest: (state, payload) => {
      state.presentationComment = payload
    },
    getCommentLike: (state, payload) => {
      state.commentLike = payload
    },
    getInappropriateComment: (state, payload) => {
      state.inappropriateComment = payload
    },
  },
  actions: {
    async sendCustomMail({ commit }, payload) {
      const EmailForm = { ...payload }
      const fetchPromise = await contactFormApi.sendCustomMail(EmailForm)

      commit('sendEmailRequest', fetchPromise)
      return fetchPromise
    },
    async getComments({ commit }, payload) {
      const params = { ...payload }
      const fetchPromise = await contactFormApi.getComments(params)

      commit('getCommentRequest', fetchPromise)
      return fetchPromise
    },
    async createSessionComment({ commit }, payload) {
      const params = { ...payload }
      const fetchPromise = await contactFormApi.createSessionComment(params)

      commit('createCommentRequest', fetchPromise)
      return fetchPromise
    },
    async createPresentationComment({ commit }, payload) {
      const params = { ...payload }
      const fetchPromise = await contactFormApi.createPresentationComment(params)

      commit('createPresentationRequest', fetchPromise)
      return fetchPromise
    },
    async getCommentLike({ commit }, payload) {
      const params = { ...payload }
      const fetchPromise = await contactFormApi.getCommentLike(params)

      commit('createPresentationRequest', fetchPromise)
      return fetchPromise
    },
    async getInappropriateComment({ commit }, payload) {
      const params = { ...payload }
      const fetchPromise = await contactFormApi.getInappropriateComment(params)

      commit('createPresentationRequest', fetchPromise)
      return fetchPromise
    },
  },
}

export default contactForm
