<template>
  <b-card
    no-body
    class="session-card"
  >
    <figure
      class="session-card__image image is-16by9"
      style="box-sizing: border-box"
    >
      <img
        v-lazy="thumbNail"
        alt="Session Image"
      />
    </figure>
    <b-card-body class="session-card__body">
      <b-card-title class="session-card__title">
        {{ session.Name }}
      </b-card-title>
      <b-card-sub-title
        class="session-card__sub-title mt-3"
        sub-title-text-variant=""
      >
        {{ formattedDate }}
      </b-card-sub-title>
      <div class="session-card__links mt-4">
        <router-link
          class="link"
          :to="sessionRoute"
        >
          {{ $t("VMX.SessionCard_go_to_session") }}
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </router-link>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import utils from '../utilities/utils'
import utilsComponents from 'm-anage.com.vmx-components/src/utilities/'


import { mapGetters } from 'vuex'

const dateFormat = require('dateformat')
const sessionImage = require('~/assets/sessionImage.png')

export default {
  props: {
    session: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      config: 'config/configForKey',
    }),
    appConfigV2() {
      const appConfigV2 = this.config('app_config')
      if (appConfigV2.configV2) {
        return appConfigV2.configV2
      }
      return null
    },
    sessionRoute() {
      const route = this.appConfigV2.defaultV2.routeV2
        ? { name: 'PagSessionDetailPage', params: { sessionId: this.session.Id } }
        : { name: 'ProgramPage', query: { session: this.session.Id } }
      return utils.appendDefaultParamsToRoute(route)
    },
    thumbNail() {
      if (this.session.ImageThumbPath) {
        return this.session.ImageThumbPath
      }
      return sessionImage
    },
    formattedDate() {
      let date = ''
      if (this.session.StartDateTime && this.session.EndDateTime) {
        const startDate = new Date(this.session.StartDateTime)
        const endtDate = new Date(this.session.EndDateTime)
        date = dateFormat(startDate, `dd mmmm yyyy, ${utilsComponents.timeFormat()}`) + dateFormat(endtDate, ` - ${utilsComponents.timeFormat()}`)
      }
      return date
    },
  },

}
</script>
