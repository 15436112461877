<template>
  <div
    v-if="isActive && hasAccess"
    id="vm-index-mtv-mountpoint"
  >
    <div
      id="vm-index-mtv"
      :class="openClass"
    >
      <div id="vm-index-mtv-player">
        <div id="vm-index-mtv-player-body">
          <img
            class="vm-index-mtv-player-body-img"
            :src="image"
            alt="fallback image"
          />
        </div>
      </div>
      <div
        id="vm-index-mtv-switch"
        @click="slide"
      >
        <div id="vm-index-mtv-switch-icon" />
        <div id="vm-index-mtv-switch-text">
          TV
        </div>
        <div id="vm-index-mtv-switch-arrow">
          <template v-if="!open">
            <font-awesome-icon :icon="['fas', 'angle-right']" />
          </template>
          <template v-else>
            <font-awesome-icon :icon="['fas', 'angle-left']" />
          </template>
        </div>
      </div>
      <div
        id="vm-index-mtv-list"
        class="row"
      >
        <div class="index_tv_link_ean">
          <a
            class="col-lg index_tv_link index_tv_link_ean_button"
            :href="url"
          >
            go to ean tv
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import accessControl from '~/utilities/accessControl'

export default {
  data() {
    return {
      open: false,
    }
  },
  components: {
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
    }),
    openClass() {
      if (this.open) {
        return 'open'
      }
      return null
    },
    config() {
      const { config } = this.configForKey('app_config')
      return config[0]
    },
    image() {
      return this.config.tvWidget?.image
    },
    video() {
      return this.config.tvWidget?.video
    },
    url() {
      return this.config.tvWidget?.url
    },
    isActive() {
      return this.config.tvWidget?.active
    },
    hasAccess() {
      return accessControl.accessControlKey('tvWidget')
    },
  },
  methods: {
    slide() {
      this.open = this.open === false
      this.jwplayerSetup()
    },
    jwplayerSetup() {
      let playerInstance = null
      if (this.video) {
        playerInstance = window.jwplayer('vm-index-mtv-player-body').setup({
          playlist: [{
            image: this.image,
            sources: [
              {
                file: this.video
              }
            ],
            description: "",
            mediaid: "ean-tv-widget-1"
          }],
          aspectratio: '16:9',
          width: '100%',
        })
      }
      
      const handleError = () => {
        playerInstance?.remove()
        playerInstance = void 0
      }
      playerInstance?.on('error', handleError)
      if (!this.open) {
        playerInstance?.remove()
        playerInstance = void 0
      }
    },
  },
}
</script>

<style scoped>
#vm-index-mtv-mountpoint {
  position: relative;
  z-index: 20;
}

#vm-index-mtv {
  position: absolute;
  top: 0;
  left: -1014px;
  width: 1014px;
  background-image: linear-gradient(to right, #00499C, #007dc7);
  z-index: 15;
  max-width: calc(100% - 65px);
  border-bottom-right-radius: 20px;
  transition: left 2s ease-in;
}

#vm-index-mtv.open {
  left: 0;
}

#vm-index-mtv-player{
  margin: 27px 45px 10px 28px;
}

#vm-index-mtv-switch{
  position: absolute;
  right: -65px;
  top: 0;
  width: 65px;
  padding: 23px 18px;
  cursor: pointer;
  background-color: #262626;
  border-radius: 0 20px 20px 0;
}

.vm-index-mtv-player-body-img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

#vm-index-mtv-switch-icon{
  color: red;
  border: 4px solid white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 20px;
  padding-top: 2px;
  background: red;
  margin-bottom: 17px;
}
#vm-index-mtv-switch-text {
  font: 400 21px/28px Nexa;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 22px;
}
#vm-index-mtv-switch-arrow {
  margin: 0;
  color: white;
  font-size: 34px;
}
#vm-index-mtv-list {
  margin: 15px 63px;
}
.index_tv_link_ean {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.index_tv_link_ean_button {
  text-transform: uppercase !important;
  border: 2px solid #FFFFFF;
  padding: 9px 17px;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  max-width: max-content;
}

</style>
