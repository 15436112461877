import base from '~/api/base'
import store from '~/store/'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getUserDocuments() {
  const event = store.getters['instancesState/getEvent']
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/custominterfaces/oauth/LandingPage/UserDocumentsV2?context=Event&shortName=${event}`
  if (useApiMockData) {
    url = '/mock-data/user-document.json'
  }
  const response = await base.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

export default {
  getUserDocuments,
}
