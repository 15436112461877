import dayjs from 'dayjs'
import store from '~/store'
import userState from '../states/user'
import utils from '~/utilities/utils'

export default async function ({ next, router, to }) {
  const routeQueries = to.query
  const instance = store.getters['instancesState/getInstance']
  const eventId = store.getters['instancesState/getEventId']
  const preview = store.getters['instancesState/getPreview']
  const loungePreview = store.getters['instancesState/getLoungePreview']
  const [appConfig] = store.getters['config/configForKey']('app_config')?.config
  const user = userState.getUser(instance)
  const redirectQuery = routeQueries.companyLoungeRedirectDisabled
  let exhibitorId = null

  // eslint-disable-next-line max-len
  if (appConfig?.companyLoungeRedirectDisabled) store.commit('instancesState/setCompanyLoungeRedirectDisabled', appConfig?.companyLoungeRedirectDisabled)
  if (redirectQuery) store.commit('instancesState/setCompanyLoungeRedirectDisabled', redirectQuery === 'true')

  // reset lounge if query is set
  if (routeQueries.lounge) {
    const loungeData = JSON.parse(localStorage.getItem(`exhibitor-lounge-${routeQueries.lounge}`))
    if (loungeData) {
      localStorage.removeItem(`exhibitor-lounge-${routeQueries.lounge}`)
      localStorage.removeItem(`initialLogin-${eventId}`)
    }
    store.commit('instancesState/setCompanyLoungeRedirectDisabled', false)
  }
  const redirectDisabled = store.getters['instancesState/getCompanyLoungeRedirectDisabled']

  // eslint-disable-next-line max-len
  if (user && !localStorage.getItem(`initialLogin-${eventId}`) && (!appConfig?.disableIndustryLounge || loungePreview) && (!redirectDisabled || loungePreview)) {
    const tickets = store.getters['userTicket/userTicket']
    if (tickets) {
      tickets.forEach((t) => {
        if (t.CompanyAccountId) {
          exhibitorId = t.CompanyAccountId
        }
      })
    }
    if (loungePreview) {
      exhibitorId = to.params.companyAccountId
    } else if (preview && !loungePreview) {
      exhibitorId = null
    }

    if (exhibitorId) {
      if (routeQueries.companyLoungeRedirectDisabled) delete routeQueries.companyLoungeRedirectDisabled
      localStorage.setItem(`initialLogin-${eventId}`, dayjs().format())
      router.push(utils.getRouteWithDefaultParams(
        'ProfilePage', { page: 'lounge', companyAccountId: exhibitorId }, routeQueries,
      ))
    } else {
      next()
    }
  } else {
    next()
  }
}
