import termsAndConditionsApi from '../../api/termsAndConditions'

const termsAndConditions = {
  namespaced: true,
  state: {
    termsAndConditions: null,
    dataProtection: null,
    termsAndConditionsOrg: null,
    dataProtectionOrg: null,
    fetchTermsAndConditionsPromise: null,
    fetchTermsAndConditionsPromiseOrg: null,

  },
  mutations: {
    setTermsAndConditions: (state, payload) => {
      state.termsAndConditions = payload
    },
    setDataProtection: (state, payload) => {
      state.dataProtection = payload
    },
    setFetchPromise: (state, payload) => {
      state.fetchTermsAndConditionsPromise = payload
    },
    setTermsAndConditionsOrg: (state, payload) => {
      state.termsAndConditionsOrg = payload
    },
    setDataProtectionOrg: (state, payload) => {
      state.dataProtectionOrg = payload
    },
    setFetchPromiseOrg: (state, payload) => {
      state.fetchTermsAndConditionsPromiseOrg = payload
    },
  },
  actions: {
    async loadTermsAndConditions({ commit, state }) {

      const fetchTermsAndConditionsPromiseOrg = termsAndConditionsApi.getTermsAndConditionsOrg().then((tnc) => {
        tnc.forEach((item) => {
          if (item.IsPrivacyPolicy === true) {
            commit('setDataProtectionOrg', item.Content)
          }
          if (item.IsPrivacyPolicy === false) {
            commit('setTermsAndConditionsOrg', item.Content)
          }
        })
      })
      const fetchTermsAndConditionsPromise = termsAndConditionsApi.getTermsAndConditions().then((tnc) => {
        tnc.forEach((item) => {
          if (item.IsPrivacyPolicy === true) {
            commit('setDataProtection', item.Content)
          }
          if (item.IsPrivacyPolicy === false) {
            commit('setTermsAndConditions', item.Content)
          }
        })
      })

      commit('setFetchPromise', fetchTermsAndConditionsPromise)
      commit('setFetchPromiseOrg', fetchTermsAndConditionsPromiseOrg)

      return { fetchTermsAndConditionsPromise, fetchTermsAndConditionsPromiseOrg }
    },
  },
  getters: {
    tnc(state) {
      return state.termsAndConditions
    },
    data(state) {
      return state.dataProtection
    },
    tncOrg(state) {
      return state.termsAndConditionsOrg
    },
    dataOrg(state) {
      return state.dataProtectionOrg
    },
  },
}

export default termsAndConditions
