import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat' // load on demand

import apiBase from './base'
import store from '../store'

dayjs.extend(advancedFormat)

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getHeaderModel(event, locale) {
  let url = ''

  if (useApiMockData) {
    url = '/api-mock/virtualMeetingHeader-model.json'
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/GetVMXHeader?shortname=${event}&language=${locale}`
  }

  const response = await apiBase.get(url)

  if (response && response.data) {
    return response.data
  }

  return null
}

async function getVMToken() {
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/GetVMToken/?eventid=${store.getters['instancesState/getEventId']}`
  const response = await apiBase.get(url)

  if (response && response.data) {
    return response.data
  }
  return null
}

async function getBuildVersion() {
  const url = `${window.location.origin}/version.json`
  if (window.location.origin.indexOf('localhost') > -1) {
    return {
      Version: 'localhost',
      BuildDateTime: dayjs(),
      BuildDateTimeHuman: dayjs().format('dddd, MMMM Do YYYY, h:mm:ss A'),
    }
  }
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

export default {
  getHeaderModel,
  getVMToken,
  getBuildVersion,
}
