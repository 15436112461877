import apiBase from './base'
import store from '../store'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getConfig() {
  let url = ''
  if (useApiMockData) {
    url = '/api-mock/config.json'
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/GetResources?eventshort=${store.getters['instancesState/getEvent']}&setname=config_json&language=${store.getters['instancesState/getLanguage']}`
  }
  const response = await apiBase.get(url)
  if (response?.data) {
    return response.data
  }
  return null
}

async function getEventInformation() {
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Event/Shortname?shortName=${store.getters['instancesState/getEvent']}`
  
  const response = await apiBase.get(url)
  if (response?.data) {
    return response.data
  }
  return null
}

export default {
  getConfig,
  getEventInformation
}
