import authApi from '../../api/authV2'

const auth = {
  namespaced: true,
  state: {
    token: null,
    status: null,
    password: null,
    confirmation: null,
  },
  mutations: {
    authRequest: (state) => {
      state.status = 'attempting authentication request'
    },
    accountRequest: (state) => {
      state.status = 'attempting account creation'
    },
    authSuccess: (state, authToken) => {
      state.status = 'authentication succeeded'
      state.token = authToken
    },
    authError: (state) => {
      state.status = 'error'
    },
    authLogout: (state) => {
      state.token = ''
    },
    passwordRequest: (state, payload) => {
      state.password = payload
    },
    transferRequest: (state) => {
      state.status = 'attempting account transfer'
    },
    confirmEmail: (state) => {
      state.status = 'attempting confirming email'
    },
    resetPassword: (state) => {
      state.status = 'attempting reset password'
    },
  },
  actions: {
    async authRequest({ commit }, payload) {
      const Credentials = { ...payload }
      const fetchPromise = await authApi.loginUser(Credentials)

      commit('authRequest', fetchPromise)
      return fetchPromise
    },

    async accountRequest({ commit }, payload) {
      const Credentials = { ...payload }
      const fetchPromise = await authApi.createUser(Credentials)

      commit('accountRequest', fetchPromise)
      return fetchPromise
    },

    async passwordRequest({ commit }, payload) {
      const Credentials = { ...payload }
      const fetchPromise = await authApi.forgotPassword(Credentials)

      commit('passwordRequest', fetchPromise)
      return fetchPromise
    },

    async confirmationRequest({ commit }, payload) {
      const Credentials = { ...payload }
      const fetchPromise = await authApi.sendConfirmation(Credentials)

      commit('passwordRequest', fetchPromise)
      return fetchPromise
    },

    async transferRequest({ commit }, payload) {
      const data = { ...payload }
      const fetchPromise = await authApi.transferUser(data)

      commit('transferRequest', fetchPromise)
      return fetchPromise
    },

    async confirmEmail({ commit }, payload) {
      const data = { ...payload }
      const fetchPromise = await authApi.confirmEmail(data)

      commit('confirmEmail', fetchPromise)
      return fetchPromise
    },

    async getExternalUserData({ commit }) {
      const fetchPromise = await authApi.getExternalUserData()

      return fetchPromise
    },


    async resetPassword({ commit }, payload) {
      const data = { ...payload }
      const fetchPromise = await authApi.resetPassword(data)

      commit('resetPassword', fetchPromise)
      return fetchPromise
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    authToken: (state) => state.token,
  },
}

export default auth
