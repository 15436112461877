import configApi from '~/api/visibilityConfig'
import store from '~/store'

const config = {
  namespaced: true,
  state: {
    config: null,
    fetchConfigPromise: null,
  },
  mutations: {
    setConfig(state, payload) {
      state.config = payload
    },
    setFetchPromise(state, payload) {
      state.fetchConfigPromise = payload
    },

  },
  actions: {
    async loadConfig({ commit, state }) {
      if (state.config !== null) {
        return state.config
      }
      if (state.fetchConfigPromise) {
        return state.fetchConfigPromise
      }
      const layer = store.getters['instancesState/getLayer']
      const fetchConfigPromise = configApi.getConfig(`${layer}ComponentVisibility`).then((c) => {
        commit('setConfig', c)
      })
      commit('setFetchPromise', fetchConfigPromise)
      return fetchConfigPromise
    },
  },
  getters: {
    config(state) {
      return state.config
    },
    visibilityForKey: (state) => (key) => {
      if (state.config === null) {
        return null
      }

      const entry = state.config.find((e) => e.resourcename === key)
      if (!entry || !entry.resourcevalue) {
        return null
      }
      try {  
        const json = JSON.parse(entry.resourcevalue)
        return json
      } catch (e) {  
        return null 
      }
    },
  },
}

export default config
