import dayjs from 'dayjs'
import store from '~/store'

export default async function ({ next, to, router }) {
  const route = to
  const { query } = route
  const currentCompanyAccountId = route.params.companyAccountId
  const companyAccount = await store.dispatch('ILPExhibitorProfile/getProfileById', currentCompanyAccountId)
  // const companyAccount = await store.dispatch('ILPExhibitorList/getProfileByIdV2', currentCompanyAccountId)
  const eventId = store.getters['instancesState/getEventId']
  const preview = store.getters['instancesState/getPreview']
  const initLogin = localStorage.getItem(`initialLogin-${eventId}`)
  let initTimeDifference = 5
  if (initLogin) {
    initTimeDifference = dayjs().diff(dayjs(initLogin), 'seconds')
  }

  if (!companyAccount) {
    router.push({
      name: 'ProfilePage',
      params: {
        instance: store.getters['instancesState/getInstance'],
        language: store.getters['instancesState/getLanguage'],
        event: store.getters['instancesState/getEvent'],
        companyAccountId: currentCompanyAccountId.toLowerCase(),
      },
      query,
    })
  }
  // eslint-disable-next-line max-len
  if (route.params.page !== 'comingsoon' && companyAccount?.PublicationState === 1 && (!initLogin || initTimeDifference >= 4) && !preview) {
    router.push({
      name: 'ProfilePage',
      params: {
        instance: store.getters['instancesState/getInstance'],
        language: store.getters['instancesState/getLanguage'],
        event: store.getters['instancesState/getEvent'],
        page: 'comingsoon',
        companyAccountId: currentCompanyAccountId,
      },
      query,
    })
    // eslint-disable-next-line max-len
  } else if (route.params.page !== 'booth' && companyAccount?.PublicationState === 0 && companyAccount?.Enable2DBooth && (!initLogin || initTimeDifference >= 4)) {
    router.push({
      name: 'ProfilePage',
      params: {
        instance: store.getters['instancesState/getInstance'],
        language: store.getters['instancesState/getLanguage'],
        event: store.getters['instancesState/getEvent'],
        page: 'booth',
        companyAccountId: currentCompanyAccountId,
      },
      query,
    })
  } else {
    next()
  }
}
