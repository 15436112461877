import exhibitionApi from '../../api/exhibition'

const meetings = {
  namespaced: true,
  state: {
    list: null,
    fetchListPromise: null,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    setFetchPromise(state, payload) {
      state.fetchListPromise = payload
    },

  },
  actions: {
    async loadList({ commit, state }) {
      if (state.list !== null) {
        return state.list
      }
      if (state.fetchListPromise) {
        return state.fetchListPromise
      }
      const fetchListPromise = exhibitionApi.getExhibitionMeetingsForEvent().then((list) => {
        commit('setList', list)
      })
      commit('setFetchPromise', fetchListPromise)
      return fetchListPromise
    },
  },
  getters: {
    list(state) {
      return state.list
    },
  },
}

export default meetings
