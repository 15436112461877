<template>
  <section>
    <!-- The overlay -->
    <div id="accessDialog" class="overlay" @click="close()">
      <div class="contentDiv d-flex justify-content-center">
        <div class="content">
          <!-- Button to close the overlay navigation -->
          <div class="text-right closebtnDiv">
            <a
              :style="{ opacity: dialogStyle }"
              href="javascript:void(0)"
              class="closebtn"
              @click="close()"
            >&times;
            </a>
          </div>
          <p class="text-center pt-3 pb-3 px-5">
            <span class="font-weight-bold title">
              <span>
                <span>Boothguru</span>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    dialogStyle: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.font-weight-bold img {
  width: 15%;
}

.no-access-text-decoration {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}

.no-access-text-decoration a {
  text-decoration: none;
  padding: 5px;
  background-color: #0057b8;
  color: #fff;
  font-weight: 500;
  border-radius: 2px;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(0, 13, 38, 0.9);
  overflow-x: hidden;
}
.contentDiv {
  position: relative;
  top: 25vh;
}
.content {
  width: 800px;
  min-height: 222px;
  background-color: white;
}
.content .title {
  font-size: 20px;
}
.content .text {
  font-size: 16px;
}
.overlay .closebtnDiv {
  margin: -10px 5px 0 0;
}
.overlay .closebtn {
  color: black;
  font-size: 30px;
  text-decoration: none;
}

@media screen and (max-height: 450px) {
}
</style>
