import store from '../store'

export default async function () {
  await store.dispatch(
    'header/loadModel',
    {
      event: store.getters['instancesState/getEvent'],
      language: store.getters['instancesState/getLanguage'],
    },
  )
  const headerModel = store.getters['header/model']
  if (headerModel && headerModel.EventId) {
    store.commit('instancesState/setEventId', headerModel.EventId)
  }
}
