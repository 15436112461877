<template>
  <div class="advertisement-popup">
    <modal
      :show="modal.show"
      @close="modal.show = false"
      :disable-backdrop-close="false"
    >
      <div
        class="advertisement-popup__container"
      >
        <div
          class="advertisement-popup__content"
          :style="`background-color: ${advertisement.backgroundColor}`"
          v-if="advertisement"
          :class="`ad-${advertisement.id}`"
        >
          <template v-if="advertisement.headlineTop">
            <h5 class="advertisement-popup__headline-top">
              {{ advertisement.headlineTop }}
            </h5>
          </template>
          <div class="advertisement-popup__image-wrapper">
            <template v-if="videoOptions.sources[0].src">
              <video-player
                :key="playerKey"
                :options="videoOptions"
              />
            </template>
            <template v-else-if="advertisement.pictureFilePath">
              <img
                class="advertisement-popup__image"
                v-lazy="advertisement.pictureFilePath"
                :alt="advertisement.headlineTo"
              />
            </template>
          </div>
          <template v-if="advertisement.headlineBottom">
            <h5 class="advertisement-popup__headline-bottom">
              {{ advertisement.headlineBottom }}
            </h5>
          </template>
          <template v-if="advertisement.text">
            <div
              class="advertisement-popup__text"
              v-html="advertisement.text"
            />
          </template>
          <template
            v-if="advertisement.optionalLink"
          >
            <a
              class="advertisement-popup__link"
              :href="formatUrl"
              :target="advertisement.optionalLinkTarget ? advertisement.optionalLinkTarget : '_self'"
              @click="trackAdLinkClick()"
            >
              <template v-if="advertisement.optionalLinkButtonText">
                {{ advertisement.optionalLinkButtonText }}
              </template>
              <template v-else>
                {{ $t('VMX.advertisement_popup_optional_link') }}
              </template>
            </a>
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import modal from 'm-anage.com.vmx-components/src/Modal'
import utils from '~/utilities/utils'
import trackingApi from '~/api/tracking'

export default {
  data() {
    return {
      modal: {
        show: false,
      },
      advertisement: null,
      availableAdvertisements: null,
      videoOptions: {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: '',
            type: 'video/mp4',
          },
        ],
      },
      playerKey: 1,
    }
  },
  async mounted() {
    await this.load()
    window.checkForAdvertisements = () => {
      setTimeout(() => {
        this.getAvailableAdvertisements()
      }, 4500)
    }
    if (this.getAdvertisements) {
      setTimeout(() => {
        this.getAvailableAdvertisements()
        this.setTimerForUpcomingAds()
      }, 4000)
    }
  },
  components: {
    modal,
    videoPlayer: () => import('~/components/industryLandingPage/videoPlayer.vue'),
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      getAdvertisements: 'advertisements/advertisement',
      userTicket: 'userTicket/userTicket',
    }),
    formatUrl() {
      return utils.addProtocolToUrlIfMissing(this.advertisement.optionalLink)
    },
  },
  watch: {
    availableAdvertisements(val) {
      if (val?.length) {
        val.forEach((a) => {
          if (this.modal.show === false) {
            if (a.showInSeconds && a.showInSeconds > 0) {
              setTimeout(() => {
                this.modal.show = false
              }, a.showInSeconds * 1000)
            }
            this.advertisement = a
            this.videoOptions.sources[0].src = a.videoFilePath
            this.modal.show = true
            this.$cookies.set(`advertisement-${a.id}`, a.id, '7d')
          }
        })
      }
    },
    modal: {
      handler(val) {
        if (val.show === false) {
          this.playerKey += 1
          this.getAvailableAdvertisements()
        }
      },
      deep: true,
    },
    $route() {
      this.getAvailableAdvertisements()
    },
  },
  methods: {
    ...mapActions({
      load: 'advertisements/loadAdvertisement',
    }),
    getAvailableAdvertisements() {
      if (this.getAdvertisements) {
        const uTicket = this.userTicket
        const { companyAccountId } = this.$route.params
        const liveStreamRoom = this.$route.name === 'LivePage' ? window.mtvRoomMeta.getPlayingRoom() : null
        // eslint-disable-next-line max-len
        const ads = this.getAdvertisements.filter((a) => dayjs() >= dayjs(a.displayFromDate)
          && dayjs() <= dayjs(a.displayToDate)
          && this.$route.name === a.vmxPage
          && this.$cookies.get(`advertisement-${a.id}`) !== a.id
          // eslint-disable-next-line max-len
          && (companyAccountId && a.CompanyAccountIdsShowOnProfile.length ? a.CompanyAccountIdsShowOnProfile.includes(companyAccountId) : true)
          && (a.channelId ? liveStreamRoom && liveStreamRoom.manage_id?.toString() === a.channelId : true))
        if (uTicket?.some((t) => t.CompanyAccountId)) {
          const [config] = this.configForKey('app_config').config
          const id = uTicket?.find((t) => t.CompanyAccountId).CompanyAccountId
          if (config.networkingWidget) {
            // eslint-disable-next-line max-len
            const ntwOnboardingDone = window.jmeNetworking?.getUserAgreements() ? window.jmeNetworking?.getUserAgreements() : false
            if (ntwOnboardingDone) {
              this.availableAdvertisements = ads.filter((a) => a.CompanyAccountIds.includes(id) || !a.CompanyAccountIds.length)
            }
          } else {
            this.availableAdvertisements = ads.filter((a) => a.CompanyAccountIds.includes(id) || !a.CompanyAccountIds.length)
          }
        } else {
          this.availableAdvertisements = ads.filter((a) => !a.CompanyAccountIds?.length)
        }
      }
    },
    trackAdLinkClick() {
      const { companyAccountId } = this.$route.params
      trackingApi.trackCustomAction('vmx-advertisementpopup-link', this.advertisement.id, companyAccountId)
    },
    setTimer(time) {
      setTimeout(() => {
        this.getAvailableAdvertisements()
      }, time)
    },
    setTimerForUpcomingAds() {
      const upcomingAds = this.getAdvertisements.filter((a) => dayjs() <= dayjs(a.displayFromDate)
        && this.$cookies.get(`advertisement-${a.id}`) !== a.id)
      upcomingAds.forEach((a) => {
        this.setTimer(dayjs(a.displayFromDate).diff(dayjs()))
      })
    },
  },
}
</script>

<style lang="scss">
.advertisement-popup {
  .ipl-modal__content {
    @media (max-width: 767px) {
      width: 90%;
    }
  }
  .advertisement-popup__container {
    .advertisement-popup__content {
      min-width: 750px;
      @media (max-width: 993px) {
        max-width: 100%;
        min-width: unset;
      }
      background-color: #fff;
      padding: 20px 25px;
      .advertisement-popup__text {
        word-break: break-word;
        margin-bottom: 10px;
      }
      .advertisement-popup__image-wrapper {
        text-align: center;
        margin: 10px 0;
        .advertisement-popup__image {
          max-height: 430px;
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
