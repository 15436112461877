import apiBase from './base'
import store from '~/store'
import userState from '../states/user'
import axios from 'axios'
import $ from 'jquery'

async function getEventPushnotifications() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/EventPushnotifications/${eventId}/${locale}`
  // const response = await apiBase.get(url)

  // if (response && response.status === 200) {
  //   return response.data
  // }
  // return null
  const axiosInstance = axios.create()
  axiosInstance.interceptors.response.eject()
  const headers = {}
  const user = userState.getUser(store.getters['instancesState/getInstance'])
  if (user) {
    headers.Authorization = `Bearer ${user.accessToken}`
  }
  const options = { headers }
  const result = axiosInstance.get(url, options).then((response) => {
    return response.data
  }).catch(() => {
    try {
      const vmxUrl = new URL(window.location.href)
      const jsonUrl = `${vmxUrl.origin}/kukm-1480-api-core-exhibition-eventpushnotifications.json`
      const json = $.getJSON(jsonUrl)
      return json
    } catch (err) {
      console.log(err)
      return null
    }
  })
  return result

}

export default {
  getEventPushnotifications,
}
