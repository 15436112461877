import virutalMeetingAPI from '../../api/virtualMeeting'

const header = {
  namespaced: true,
  state: {
    model: null,
    fetchModelPromise: null,
  },
  mutations: {
    setModel(state, payload) {
      state.model = payload
    },
    setFetchPromise(state, payload) {
      state.fetchModelPromise = payload
    },

  },
  actions: {
    async loadModel({ commit, state }, { event, language }) {
      if (state.model !== null) {
        return state.model
      }
      if (state.fetchModelPromise) {
        return state.fetchModelPromise
      }
      const fetchModelPromise = virutalMeetingAPI.getHeaderModel(event, language).then((model) => {
        commit('setModel', model)
      })
      commit('setFetchPromise', fetchModelPromise)
      return fetchModelPromise
    },
  },
  getters: {
    model(state) {
      return state.model
    },
  },
}

export default header
