import userApi from '../../api/user'

const vmUser = {
  namespaced: true,
  state: {
    vmUser: null,
    fetchVmUserPromise: null,
  },
  mutations: {
    setUser(state, payload) {
      state.vmUser = payload
    },
    setFetchPromise(state, payload) {
      state.fetchVmUserPromise = payload
    },
  },
  actions: {
    async loadVmUser({ commit, state }) {
      if (state.vmUser !== null) {
        return state.vmUser
      }
      if (state.fetchUserPromise) {
        return state.fetchVmUserPromise
      }
      const fetchVmUserPromise = userApi.getVmUser().then((vUser) => {
        commit('setUser', vUser)
      })
      commit('setFetchPromise', fetchVmUserPromise)
      return fetchVmUserPromise
    },
  },
  getters: {
    vmUser(state) {
      return state.vmUser
    },
  },
}

export default vmUser
