import Vue from 'vue'
import VueCookies from 'vue-cookies'
import dayjs from 'dayjs'

import store from '~/store'

import userState from '../states/user'
import utils from '../utilities/utils'
import config from '../config'
import userApi from '../api/user'

Vue.use(VueCookies)

async function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

async function addTicketToUser(event, instance, eventsWithTicket) {
  // if events have ticket check
  const [backendConfig] = store.getters['config/configForKey']('app_config')?.config
  let configEventWithTicket = true
  if ('eventWithTicket' in backendConfig.defaults) {
    configEventWithTicket = backendConfig.defaults.eventWithTicket
  }
  if (eventsWithTicket.includes(event) || configEventWithTicket === true) {
    const user = await userState.getUser(instance)
    const userTicket = await userApi.getUserTickets()

    let hasTicket = false
    if (userTicket && userTicket.length > 0) {
      hasTicket = true
      Vue.$cookies.set('participant_type', userTicket[0].ParticipantTypeName)
    } else {
      Vue.$cookies.set('participant_type', null)
    }
    const uuid = await generateUUID()
    localStorage.setItem('uuid', uuid)
    userState.setUser(instance, {
      accessToken: user.accessToken,
      validUntil: user.validUntil,
      uuid: localStorage.getItem('uuid'),
      hasTicket,

    })

    utils.addTicketCookie(userTicket)
  }
}


async function addFakeTicketToUser() {
  Vue.$cookies.set('participant_type', 'UnregisteredVisitor')
}

async function authRedirect(route) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  let language = store.getters['instancesState/getLanguage']
  const [backendConfig] = store.getters['config/configForKey']('app_config')?.config
  const $route = route
  language = utils.localStringFormatter(language)
  const redirectObject = {}
  redirectObject.name = $route.name
  redirectObject.params = {}
  redirectObject.params = $route.params
  redirectObject.query = $route.query
  redirectObject.path = $route.path
  localStorage.setItem('redirectUrl', JSON.stringify(redirectObject))
  let returnPage = `${$route.path}?${utils.queryString($route.query)}`
  if (!returnPage || route.name === 'NoaccessPage') {
    returnPage = `/home/${instance}/${event}/${language}`
  }
  const redirectUri = window.location.origin + returnPage
  // build url for login
  userState.unsetUser(instance)
  store.commit('instancesState/setToken', null)
  if (!config.getInstanceConfig(instance).identityUrl) {
    utils.redirectOAuthAuthorize($route)
    return
  }

  if (!backendConfig.OnboardingV2 && backendConfig.defaults?.ticketSync) {
    localStorage.setItem('externalAuthReturnUrl', JSON.stringify(redirectObject))
    window.location.href = `${window.location.origin}/auth/${instance}/${event}/${language}`
    return
  }

  if (backendConfig.defaults?.externalAuth?.loginUrlV2 && localStorage.getItem('redirectedonce') === 'true') {
    localStorage.removeItem('redirectedonce')
    window.location.replace(backendConfig.defaults.externalAuth.loginUrlV2)
    return
  }
  localStorage.setItem('vmxReturnUrl', redirectUri)
  window.location.href = `${window.location.origin}/login/${instance}/${event}/${language}?returnUrl=${redirectUri}`
}
function SSOLogin(instanceConfig, event, language, query, route, backendConfig) {
  const alternativePath = backendConfig?.SSOReturnUrl ? backendConfig.SSOReturnUrl : null
  const locale = utils.localStringFormatter(language)
  const { identityUrl } = instanceConfig
  const prevQuery = utils.queryString(query)
  const loginUrl = instanceConfig.sso.url


  const defaultParams = {
    shortname: event,
    locale,
    isapp: localStorage.getItem('isApp') === 'true',
    returnUrl: window.location.origin + (alternativePath || route.path)+'?'+prevQuery,
  }
  const defaultQueryStrings = utils.queryString(defaultParams)

  if (instanceConfig.sso && Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'context')) {
    Object.assign(params, { context: instanceConfig.sso.context })
  }

  if (Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'provider')) {
    Object.assign(params, { provider: instanceConfig.sso.provider })
  }

  let SSOUrl = `${identityUrl + loginUrl}?${defaultQueryStrings}`

  if(backendConfig?.defaults?.externalAuth?.loginUrl) {
    const externalLoginUrl = backendConfig.defaults.externalAuth.loginUrl
    const externalLoginParams = {
      returnUrl: window.location.origin + route.path +'?'+prevQuery,
    }
    const externalLoginQueryStrings = utils.queryString(externalLoginParams)
    SSOUrl = `${externalLoginUrl}${externalLoginUrl.includes('returnUrl') ? '' : '&'+externalLoginQueryStrings}`
  }
  localStorage.removeItem('redirectedonce')
  localStorage.setItem('externalLogin', true)

  window.location.href = SSOUrl
}

function removeCookies() {
  Vue.$cookies.remove('accesOnDays')
  Vue.$cookies.remove('tickets')
  Vue.$cookies.remove('hcp')
  Vue.$cookies.remove('userinterests')
  Vue.$cookies.remove('user_country')
  Vue.$cookies.remove('email')
  Vue.$cookies.remove('firstname')
  Vue.$cookies.remove('lastname')
  Vue.$cookies.remove('m_userid')
  Vue.$cookies.remove('profession')
  Vue.$cookies.remove('hcp')
  Vue.$cookies.remove('participant_type')
  Vue.$cookies.remove('data_insight_active')
}

function tryToGetToken(query, instance, route, path) {
  localStorage.setItem('redirectedonce', true)
  const queryString = utils.queryString(query)
  localStorage.removeItem('externalLogin')

  if (!config.getInstanceConfig(instance).identityUrl) {
    utils.redirectOAuthAuthorize(route)
    localStorage.removeItem('redirectedonce')
    return
  }
  // eslint-disable-next-line max-len
  window.location.href = `${config.getInstanceConfig(instance).webformsUrl}/Home/GetToken?returnURL=${window.location.origin}${path}?${queryString}`
}

// To do: dynamic config
const eventsWithTicket = [
  'asia2020',
  'esmo2020',
  'esrs2020',
  'esvs2020',
  'UKE012021',
  'aad2021',
  'eadvsymposium2021',
  'par21',
  'escrs2021',
  'ectrims2021',
  'eanm21',
  'eha2021',
  'escrs2021',
  'easd2021',
  'esmo2021',
  'asia2021',
]
const eventsWithHCP = []

const pageWithPreview = [
  'IndustryLandingPageProfile',
  'SymposiaPage',
]

// function removeCache() {
//   localStorage.removeItem('cachedDate')

//       navigator.serviceWorker.getRegistrations().then((registrations) => {
//         registrations.forEach((registration) => {
//           registration.unregister();
//         });
//       });

//       navigator.serviceWorker.getRegistrations().then(function (registrations) {
//         for (let registration of registrations) {
//           registration.unregister();
//         }
//       });

//       if (window.navigator && navigator.serviceWorker) {
//         navigator.serviceWorker
//           .getRegistrations()
//           .then(function (registrations) {
//             for (let registration of registrations) {
//               registration.unregister();
//             }
//           });
//       }

//       if ("caches" in window) {
//         caches.keys().then(function (keyList) {
//           return Promise.all(
//             keyList.map(function (key) {
//               return caches.delete(key);
//             })
//           );
//         });
//       }

//       if ("serviceWorker" in navigator) {
//         navigator.serviceWorker
//           .getRegistrations()
//           .then(function (registrations) {
//             for (const registration of registrations) {
//               // unregister service worker
//               console.log("serviceWorker unregistered");
//               registration.unregister();
//             }
//           });
//       }
// }

export default async function ({ next, to }) {
  const $route = to
  const instance = store.getters['instancesState/getInstance']
  const instanceConfig = config.getInstanceConfig(instance)
  const event = store.getters['instancesState/getEvent']
  let language = store.getters['instancesState/getLanguage']
  const headerModel = store.getters['header/model']
  const [backendConfig] = store.getters['config/configForKey']('app_config')?.config
  const backendConfigV2 = store.getters['config/configForKey']('app_config')?.configV2
  const pagesWithTicketCheck = await userApi.getPagesWithTicketCheck(event)
  const preview = store.getters['instancesState/getPreview']
  localStorage.removeItem('externalAuthReturnUrl')

  language = utils.localStringFormatter(language)
  if (headerModel && headerModel.Authenticated === false) {
    userState.unsetUser(instance)
    store.commit('instancesState/setToken', null)
  }

  const externalAuthRedirect = 'newExternalAuthRedirect' in backendConfigV2.defaultV2 ? backendConfigV2.defaultV2.newExternalAuthRedirect : false
  if ($route?.query?.externalAuthorizationCode) {
    Vue.$cookies.set('externalAuthorizationCode', $route.query.externalAuthorizationCode)
    // localStorage.setItem('externalAuthorizationCode', $route.query.externalAuthorizationCode)
  } else if (!backendConfig.OnboardingV2 && !externalAuthRedirect) {
    Vue.$cookies.remove('externalAuthorizationCode')
  }

  const params = {
    returnUrl: `${$route.path}?${utils.queryString($route.query)}`,
  }

  let queryString = utils.queryString(params)

  // Get queries
  const query = {}
  Object.keys($route.query).filter((q) => {
    if (!q.includes('jwt_token')) {
      Object.assign(query, {
        [q]: $route.query[q],
      })
    }
    return {}
  })

  const disableQueries = ['preview']
  // eslint-disable-next-line max-len
  const disableCheck = disableQueries.every((prop) => !Object.prototype.hasOwnProperty.call(query, prop))

  if (!pageWithPreview.includes($route.name)) {
    delete query.preview
  }

  // Get JWT token from query
  if (Object.prototype.hasOwnProperty.call($route.query, 'jwt_token')) {
    localStorage.setItem('VMXUtil', 'util')
    store.commit('instancesState/setToken', $route.query.jwt_token)
    userState.setUser(instance, {
      accessToken: $route.query.jwt_token,
      validUntil: new Date().getTime() + (432000 * 1000), // 3600 = 1h,
      uuid: localStorage.getItem('uuid'),
    })
    localStorage.removeItem('hasSeenSplashscreen')

    if (!preview) {
      await store.dispatch('personalProgram/load', { loadScheduleNew: true })
      await addTicketToUser(event, instance, eventsWithTicket)
    }

    if (disableCheck) {
      queryString = utils.queryString(query)
      // add check if service worker active or not
      // if(backendConfigV2?.defaultV2?.serviceWorker?.activateServiceWorker) {
      //   removeCache()
      //   setTimeout(() => {
      //     window.location.replace(`${window.location.origin + $route.path}?${queryString}`)
      //   },1000)
      //   return
      // } else {
        window.location.href = `${window.location.origin + $route.path}?${queryString}`
        return
      // }
      
    }
  }
  // get oauth token from query
  if (Object.prototype.hasOwnProperty.call($route.query, 'state')) {
    const validUntil = new Date().getTime() + (utils.getUrlParameter($route.hash, 'expires_in') * 1000)
    store.commit('instancesState/setToken', utils.getUrlParameter($route.hash, 'access_token'))

    userState.setUser(instance, {
      accessToken: utils.getUrlParameter($route.hash, 'access_token'),
      validUntil,
      uuid: localStorage.getItem('uuid'),
    })

    if (!preview) {
      await store.dispatch('personalProgram/load', { loadScheduleNew: true })
      await addTicketToUser(event, instance, eventsWithTicket)
    }

    const reloadUrl = new URL(window.location.origin + to.path)
    // eslint-disable-next-line no-restricted-syntax
    for (const name in to.query) { // TODO: check if for is really needed
      if (Object.prototype.hasOwnProperty.call(to.query, name)) {
        if (name !== 'state') {
          reloadUrl.searchParams.append(name, to.query[name])
        }
      }
    }

    // if(backendConfigV2?.defaultV2?.serviceWorker?.activateServiceWorker) {
    //   removeCache()
    //   setTimeout(() => {
    //     window.location.replace(reloadUrl.href)
    //   },3000)
    //   return
    // } else {
      settimeout(() =>{
        window.location.href = reloadUrl.href
  
      }, 3000)
      return
    // }
 
  }

  // if no user is set
  if (userState.getUser(instance) === null) {
    store.dispatch('clearAll')
    localStorage.setItem('VMXUtil', 'util')
    localStorage.removeItem('lastUserLogin')

   if (localStorage.getItem('isTerminal') === 'true') {
      next()
      return
    }
    if (localStorage.getItem('isApp') === 'true' && backendConfig.defaults?.forceLoginPageApp) {
       // add check if service worker config is  active or not
      //  if(backendConfigV2?.defaultV2?.serviceWorker?.activateServiceWorker) {
      //   removeCache()
      //   setTimeout(() => {
      //     window.location.replace(`${window.location.origin}/login/${instance}/${event}/${language}`)
      //   },3000)
      //   return
      // } else {
        window.location.href = `${window.location.origin}/login/${instance}/${event}/${language}`
        return
      // }
    }
    if (config.getAuthConfig($route.name) || localStorage.getItem('tryLogin')) {
      if (localStorage.getItem('tryLogin') !== null) {
        localStorage.removeItem('tryLogin')
        window.location.href = `${window.location.origin}/login/${instance}/${event}/${language}`
        return
      }
      // TODO: dynamically
      if (!backendConfig.defaults?.forceLoginPage) {
        if (localStorage.getItem('redirectedonce') == null) {
          localStorage.setItem('redirectedonce', true)
          queryString = utils.queryString(to.query)
          localStorage.removeItem('externalLogin')

          if (!config.getInstanceConfig(instance).identityUrl) {
            utils.redirectOAuthAuthorize(to)
            localStorage.removeItem('redirectedonce')
            return
          }
          // eslint-disable-next-line max-len
          window.location.href = `${config.getInstanceConfig(instance).webformsUrl}/Home/GetToken?returnURL=${window.location.origin}${$route.path}?${queryString}`
          return
        }
      }

      // SSO Login
      if (backendConfig.SSOLogin) {
        SSOLogin(instanceConfig, event, language, query, $route, backendConfig)
        return
      }
      if (!backendConfig && Vue.$cookies.get('externalAuthorizationCode')) {
        next()
        return
      }
      authRedirect($route)
      return
    }

    if (backendConfig.unregisteredTicket) {
      await addFakeTicketToUser(instance)
    }
  } else {
    localStorage.removeItem('tryLogin')
    localStorage.removeItem('redirectedonce')
    const user = userState.getUser(instance)
    const noAccessUrl = `${window.location.origin}/noaccess/${instance}/${event}/${language}`
    // OnboardingV2 stuff
    let hasMembershipTicket = false
    let disableTicketSyncNoAccessRedirect = 'disableTicketSyncNoAccessRedirect' in backendConfig.defaults ? backendConfig.defaults.disableTicketSyncNoAccessRedirect : true
    //

    if (user) {
      await store.dispatch('userTicket/loadUserTicket')
      const tickets = store.getters['userTicket/userTicket']
      const { previewTicket } = backendConfig

      let userTicketResponse = null
      if (disableCheck && backendConfig.defaults?.ticketSync) {
        if(tickets) {
          tickets.forEach((ticket) => {
            if (previewTicket
              && ticket.IsValid
              && ticket.IsPayed
              && ticket.TicketTypeName?.toLowerCase() === previewTicket?.toLowerCase()) {
              store.commit('instancesState/setSuperUserToggle', true)
              if (localStorage.getItem('sUser') === 'true' || backendConfig.defaultPreviewTicketUnlock) {
                store.commit('instancesState/setSuperUser', true)
              }
            }
          })
        }
        if (!tickets) {
          userTicketResponse = await userApi.getUserTickets()
        }
        if(!disableTicketSyncNoAccessRedirect && userTicketResponse === null) {
          next()
          return
        }

        if (userTicketResponse === null || userTicketResponse?.status === 410 || userTicketResponse?.status === 400 ||  userTicketResponse?.status === 204 || tickets?.length === 0) {
          Vue.$cookies.remove('externalAuthorizationCode')
          localStorage.setItem('accessDenyReasons', JSON.stringify(['TicketSyncError']))
          window.location.href = noAccessUrl
          return
        }
      }
      if (tickets) {
        const ticketWithParticipantType = tickets.find((t) => t.ParticipantTypeName)
        if (tickets && tickets.length > 0 && ticketWithParticipantType) {
          Vue.$cookies.set('participant_type', ticketWithParticipantType.ParticipantTypeName)
          userState.setUser(instance, {
            accessToken: user.accessToken,
            validUntil: user.validUntil,
            hasTicket: true,
            uuid: localStorage.getItem('uuid'),
          })
        } else {
          Vue.$cookies.set('participant_type', null)
        }
        tickets.forEach((ticket) => {
          if (previewTicket
            && ticket.IsValid
            && ticket.IsPayed
            && ticket.TicketTypeName?.toLowerCase() === previewTicket?.toLowerCase()) {
            store.commit('instancesState/setSuperUserToggle', true)
            if (localStorage.getItem('sUser') === 'true' || backendConfig.defaultPreviewTicketUnlock) {
              store.commit('instancesState/setSuperUser', true)
            }
          }
          // OnboardingV2 stuff
          if (backendConfig.OnboardingV2 && ticket.TicketGrade === null) {
            hasMembershipTicket = true
          }
          //
        })
      }
      if (!store.getters['instancesState/getSuperUserToggle']) {
        localStorage.removeItem('sUser')
      }
      if (!localStorage.getItem('lastUserLogin')) {
        localStorage.setItem('lastUserLogin', Date.now())
      }
    }
    // OnboardingV2 stuff
    if (backendConfig.OnboardingV2) {
      Vue.$cookies.remove('externalAuthorizationCode')
      if (hasMembershipTicket && $route.name !== 'AssemblyPage') {
        window.location.href = `${window.location.origin}/auth/${instance}/${event}/${language}`
        return
      }
    }
    //
    const tokenValidUntil = user.validUntil
    let uniqueDays = []

    if (Vue.$cookies.get('accessOnDays') && Vue.$cookies.get('accessOnDays').length > 0) {
      const accessdays = Vue.$cookies.get('accessOnDays')
      uniqueDays = [...new Set(accessdays)]
    }

    // check if user has a valid ticket
    // TODO: make event check dynamically and different ticket type?

    if (disableCheck) {
      if (eventsWithTicket.includes(event) && !user.hasTicket && event === 'asia2020') {
        userState.unsetUser(instance)
        removeCookies()
        store.commit('instancesState/setToken', null)
        store.dispatch('clearAll')
        localStorage.removeItem('externalLogin')
        window.location.href = noAccessUrl
        return
      }
      if (eventsWithTicket.includes(event)
        && !user.hasTicket
        && pagesWithTicketCheck.includes(to.name)) {
        userState.unsetUser(instance)
        removeCookies()
        store.commit('instancesState/setToken', null)
        store.dispatch('clearAll')
        localStorage.removeItem('externalLogin')
        window.location.href = noAccessUrl
        return
      }

      if (eventsWithTicket.includes(event)
        && user.hasTicket
        && pagesWithTicketCheck.includes(to.name)
        && eventsWithHCP.includes(event)) {
        if (!uniqueDays.includes(dayjs(utils.getCETorCESTDate()).format().split('T')[0])) {
          userState.unsetUser(instance)
          removeCookies()
          store.commit('instancesState/setToken', null)
          store.dispatch('clearAll')
          window.location.href = noAccessUrl
          return
        }
      }
    }

    // token time validation
    if (tokenValidUntil !== null && tokenValidUntil < new Date().getTime()) {
      userState.unsetUser(instance)
      store.commit('instancesState/setToken', null)
      localStorage.removeItem('externalLogin')
      store.dispatch('clearAll')
      // TODO: dynamically
      if (config.getAuthConfig(to.name) && backendConfig.SSOLogin) {
        SSOLogin(instanceConfig, event, language, query, $route, backendConfig)
        return
      }
      //
      if (config.getAuthConfig(to.name)) {
        if (localStorage.getItem('redirectedonce') == null) {
          tryToGetToken(to.query, instance, to, $route.path)
          return
        }

        if (!config.getInstanceConfig(instance).identityUrl) {
          utils.redirectOAuthAuthorize(to)
          return
        }

        authRedirect($route)
        return
      }
    }
  }


  next()
}
