import Vue from 'vue'
import VueCookies from 'vue-cookies'

import store from '~/store'
// import config from '../config'
// import userState from '../states/user'

Vue.use(VueCookies)

// get dataInsightAuthKey from config
function metricTracking() {
  let tConfig = null
  if (store.getters['config/configForKey']('app_config')) {
    [tConfig] = store.getters['config/configForKey']('app_config').config
  }
  if (tConfig?.metricTracker) {
    return tConfig.metricTracker
  }
  return false
}

function translateStringToData(params) {
  switch (params) {
    case 'null':
      return null
    default:
      return params
  }
}

// get user country type from config or use default user_country
function setUserCountryType() {
  const metricTracker = metricTracking()
  if (metricTracker?.userCountryType) {
    return metricTracker?.userCountryType
  }
  return 'user_country'
}

// data-insight.tracking trackingInit function for setting userdata and dataInsightAuthKey
function trackingInit(UserId) {
  Vue.$cookies.set('data-insight-active', true)
  const UserIsHcp = store.getters['user/user']?.HealthCareProfessional || false
  const metricTracker = metricTracking()
  const userCountryType = setUserCountryType()
  let eventDevice = null
  if (localStorage.getItem('isApp') === 'true') {
    eventDevice = 'vmx-mobile-app'
  } else if (localStorage.getItem('isTerminal') === 'true') {
    eventDevice = 'vmx-terminal'
  }
  const userData = {
    UserId,
    UserIsHcp,
    UserCountryCode: Vue.$cookies.get(userCountryType) === 'null' ? null : Vue.$cookies.get(userCountryType),
    UserVisitorType: Vue.$cookies.get('participant_type') === 'null' ? null : Vue.$cookies.get('participant_type'),
    UserAreaOfInterest: Vue.$cookies.get('userinterests') === 'null' ? null : Vue.$cookies.get('userinterests'),
    UserProfession: Vue.$cookies.get('profession') === 'null' ? null : Vue.$cookies.get('profession'),
    UserCity: Vue.$cookies.get('user_city') === 'null' ? null : Vue.$cookies.get('user_city'),
    UserGender: Vue.$cookies.get('user_gender') === 'null' ? null : Vue.$cookies.get('user_gender'),
    UserExternalId: translateStringToData(Vue.$cookies.get('sso_id')),
    EventDevice: eventDevice,
  }
  const { authKey } = metricTracker
  window.dispatchEvent(new CustomEvent('data-insight.init', {
    detail: {
      dataInsightAuthKey: authKey,
      user: userData,
    },
  }))
}

async function trackProgramPage(Action, Context) {
  window.dispatchEvent(new CustomEvent('data-insight.track', {
    bubbles: true,
    detail: {
      sourceApplicationAction: `${Action}`,
      sourceApplication: 'vmx',
      sourceApplicationContext: Context,
      entityId: null,
      pageTitle: document.title ? document.title : null,
      sourceApplicationUrl: window.location.href,
    },
  }))
}

async function trackPage(Action, pRoute, pSourceContext = null) {
  const trackingContext = (pRoute.page && pSourceContext)
    ? `${pRoute.name} - ${pRoute.page} - ${pSourceContext}`
    : `${pRoute.name} - ${pSourceContext}`
  const routeContext = pRoute || pRoute.name
  window.dispatchEvent(new CustomEvent('data-insight.track', {
    bubbles: true,
    detail: {
      sourceApplicationAction: `${Action}`,
      sourceApplication: 'vmx',
      sourceApplicationContext: pSourceContext ? trackingContext : `${routeContext}`,
      entityId: null,
      pageTitle: document.title ? document.title : null,
      sourceApplicationUrl: window.location.href,
    },
  }))
}
async function trackProgramDetailPage(pEntityId, Action, Context) {
  const EntityId = typeof pEntityId === 'string' ? pEntityId : JSON.stringify(pEntityId)
  window.dispatchEvent(new CustomEvent('data-insight.track', {
    bubbles: true,
    detail: {
      sourceApplicationAction: `${Action}`,
      sourceApplication: 'vmx',
      sourceApplicationContext: Context,
      entityId: EntityId,
      pageTitle: document.title ? document.title : null,
      sourceApplicationUrl: window.location.href,
    },
  }))
}
async function trackProfilePage(pEntityId, Action) {
  const EntityId = typeof pEntityId === 'string' ? pEntityId : JSON.stringify(pEntityId)
  window.dispatchEvent(new CustomEvent('data-insight.track', {
    bubbles: true,
    detail: {
      sourceApplicationAction: `${Action}`,
      sourceApplication: 'vmx',
      sourceApplicationContext: '',
      entityId: EntityId,
      pageTitle: document.title ? document.title : null,
      sourceApplicationUrl: window.location.href,
    },
  }))
}
async function trackProduct(pEntityId, Action, ProductContext, Context) {
  const EntityId = typeof pEntityId === 'string' ? pEntityId : JSON.stringify(pEntityId)
  window.dispatchEvent(new CustomEvent('data-insight.track', {
    bubbles: true,
    detail: {
      sourceApplicationAction: `${Action}`,
      sourceApplication: 'vmx',
      sourceApplicationContext: Context,
      entityId: EntityId,
      pageTitle: document.title ? document.title : null,
      sourceApplicationUrl: ProductContext,
    },
  }))
}
async function trackCustomAction(Action, pEntityId, Context = null) {
  const EntityId = typeof pEntityId === 'string' ? pEntityId : JSON.stringify(pEntityId)
  window.dispatchEvent(new CustomEvent('data-insight.track', {
    bubbles: true,
    detail: {
      sourceApplicationAction: `${Action}`,
      sourceApplication: 'vmx',
      sourceApplicationContext: Context,
      entityId: EntityId,
      pageTitle: document.title ? document.title : null,
      sourceApplicationUrl: window.location.href,
    },
  }))
}
function trackIp(userId, key) {
  // ipify geolocation
  const url = `https://geo.ipify.org/api/v2/country?apiKey=${key}`
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      trackCustomAction('IP-Tracking', userId, `${data.location.country} ${data.ip}`)
    })
}

export default {
  trackPage,
  trackProfilePage,
  trackProduct,
  trackProgramPage,
  trackProgramDetailPage,
  trackCustomAction,
  trackingInit,
  trackIp,
}
