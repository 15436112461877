import Vue from 'vue'
import VueCookies from 'vue-cookies'

import apiBase from './base'
import store from '../store'


Vue.use(VueCookies)

async function loginUser(Credentials) {
  const url = `${store.getters['instancesState/getIdentityUrl']}/api/Token/ValidateLoginFromBody`
  const { Email, Password, AcceptTermsOfUse } = Credentials
  const data = {
    Email,
    Password,
    AcceptTermsOfUse,
  }
  const response = await apiBase.post(url, data)
  if (response?.status === 200) {
    if (response?.data === 'Unconfirmed' || response?.data === 'Lockout' || response?.data === 'Terms') {
      return response.data
    }
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error.length === 0) return 401
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function createUser(Credentials) {
  const url = `${store.getters['instancesState/getIdentityUrl']}/api/Token/CreateAccountFromBody`
  const { Firstname, Surname, Email, Password, ConfirmPassword, AcceptTermsOfUse } = Credentials
  const data = {
    Firstname,
    Surname,
    Email,
    Password,
    ConfirmPassword,
    AcceptTermsOfUse,
  }
  const response = await apiBase.post(url, data)
  if (response?.status === 200) {
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 403
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function forgotPassword(Credentials) {
  const event = store.getters['instancesState/getEvent']
  const locale = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getIdentityUrl']}/api/Token/ForgotPasswordFromBody?context=Event&shortname=${event}&language=${locale}`
  const { Email } = Credentials
  const data = {
    Email,
  }
  const response = await apiBase.post(url, data)

  if (response?.status === 200) {
    return response
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function getExternalUserData() {
  const externalAuthCode = Vue.$cookies.get('externalAuthorizationCode') ? Vue.$cookies.get('externalAuthorizationCode') : ''
  const eventId = store.getters['instancesState/getEventId']

  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/custominterfaces/EventsAir/EventsAir/ExternalUserData?eventid=${eventId}&externalAuthorizationCode=${externalAuthCode}`
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function sendConfirmation(Credentials) {
  const event = store.getters['instancesState/getEvent']
  const locale = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getIdentityUrl']}/api/Token/SendConfirmationFromBody?context=Event&shortname=${event}&language=${locale}`
  const { Email } = Credentials
  const data = {
    Email,
  }
  const response = await apiBase.post(url, data)

  if (response?.status === 200) {
    return response
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

export default {
  loginUser,
  createUser,
  forgotPassword,
  sendConfirmation,
  getExternalUserData,
}
