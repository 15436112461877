import Vue from 'vue'
import VueCookies from 'vue-cookies'
import accessControl from '~/utilities/accessControl'
// import accessControl from 'm-anage.com.access-control/src/index'
import store from '~/store'
import userState from '~/states/user'
import EventBus from '~/states/event-bus'


Vue.use(VueCookies)

export default async function ({ next, to, from }) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  // const policies = store.getters['config/configForKey']('access_policies')
  const user = userState.getUser(instance)
  const isTerminal = localStorage.getItem('isTerminal') === 'true'
  // const userTickets = store.getters['userTicket/userTicket']
  // const backendConfig = store.getters['config/configForKey']('app_config').config[0]
  // const timezone = backendConfig?.EventTimeZone
  // let userLoggedIn = false
  let result = null
  if (user) {
    await store.dispatch('userTicket/loadUserTicket')
    // userLoggedIn = true
  }

  if (to.name !== 'IndustryLandingPageProfile' && to.name !== 'ProfilePage') {
    if (localStorage.getItem('prevList')) {
      localStorage.removeItem('prevList')
      localStorage.setItem('backLink', false)
    }
  }

  if (isTerminal) {
    next()
    return
  }
  if (to.name === 'ContactPage' && store.getters['config/configForKey']('app_config').config[0].defaults.accessContactPage) {
    next()
    return
  }

  // access control check for navigation modal (JMT-9927)
  if (store.getters['config/configForKey']('app_config').config[0].defaults.showNoAccessModal) {
    if (to.path.includes('session')) {
      result = await accessControl.accessControlSession(to.params.sessionId.toString())
    } else if (to.path.includes('profile')) {
      result = await accessControl.accessControlUrl(to.path)
    } else {
      result = await accessControl.accessControlPages(to.name)
    }
    EventBus.$emit('noaccess', !result)
    if (!result) return
  }

  // get server time for time checks
  await store.dispatch('serverTime/loadServerTime')

  if (to.fullPath === `/program/${instance}/${event}/${language}?filter=industry`
    || to.fullPath === `/program/${instance}/${event}/${language}?filter=industryspecial`
    || to.fullPath === `/program/${instance}/${event}/${language}?filter=scientificprogram`
    || to.name === 'IndustryLandingPageProfile'
    || to.name === 'ProfilePage'
    || (to.name === 'ListPage' && to.params.page)
    || (to.name === 'IndustryLandingPage' && to.params.page && to.params.page !== 'list')) {
    result = await accessControl.accessControlUrl(to.fullPath)
  } else if (to.name === 'ContentPage') {
    result = await accessControl.accessControlPages(to.params.pageName)
  } else {
    result = await accessControl.accessControlPages(to.name)
    // standalone access control
    // result = await accessControl.accessControlPages(to.name, policies, userTickets, userLoggedIn, undefined, timezone)
  }

  if('sessionId' in to.params) {
    result = await accessControl.accessControlSession(to.params.sessionId.toString())
  }

  if (!result) {
    window.location.href = `${window.location.origin}/Noaccess/${instance}/${event}/${language}?prevPage=${from.name}`
    return
  }
  next()
}
