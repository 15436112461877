import apiBase from './base'
import store from '../store'

async function getConfig(pSetname) {
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/GetResources?eventshort=${store.getters['instancesState/getEvent']}&setname=${pSetname}&language=${store.getters['instancesState/getLanguage']}`
  
  const response = await apiBase.get(url)
  if (response?.data) {
    return response.data
  }
  return null
}

export default {
  getConfig,
}
