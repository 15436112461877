// import getCompanyAccountsList from '../../../api-mock/exhibitors-list.js';
import Fuse from 'fuse.js'
import exhibitionApi from '../../../api/exhibition'
import store from '~/store'

const exhibitorList = {
  namespaced: true,
  state: {
    list: null,
    result: [],
    loading: false,
    searching: false,
    fuseJs: null,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
      state.fuseJs = new Fuse(state.list, {
        keys: ['Name', 'Tags', 'ExhibitorBooths.BoothNumber'],
        maxPatternLength: 32,
        minMatchCharLength: 3,
        threshold: 0.3,
      })
    },
    startLoading(state) {
      state.loading = true
    },
    stopLoading(state) {
      state.loading = false
    },
    startSearching(state) {
      state.searching = true
    },
    stopSearching(state) {
      state.searching = false
    },
  },
  actions: {
    async load({ commit, state }) {
      if (state.list == null) {
        const useCompanyAccountsV2 = 'useCompanyAccountsV2' in store.getters['config/configForKey']('app_config')?.configV2?.defaultV2 ? store.getters['config/configForKey']('app_config').configV2.defaultV2.useCompanyAccountsV2 : false
        const companyAccounts = useCompanyAccountsV2 ? await exhibitionApi.getCompanyAccountsListV2() : await exhibitionApi.getCompanyAccountsList()
        commit('setList', companyAccounts)
      }
    },
    async search({ commit, state, dispatch }, payload) {
      if (state.list == null) {
        await dispatch('load')
      }
      commit('startSearching')
      let result = []
      if (payload && payload.text) {
        const fuseResult = state.fuseJs.search(payload.text)
        for (let i = 0, len = fuseResult.length; i < len; i += 1) { // TODO: check if for is really needed
          result.push(fuseResult[i].item)
        }
      } else {
        result = state.list
      }
      if (payload && Object.prototype.hasOwnProperty.call(payload, 'filterBy')) {
        const { filterBy } = payload
        result = result.filter((item) => {
          for (const prop in filterBy) { // TODO: check if for is really needed
            // prop: SponsorType oder CompanyType
            if (Object.prototype.hasOwnProperty.call(item, prop)) {
              let filter = filterBy[prop]
              if (Array.isArray(filter)) {
                filter = { include: filter }
              }
              if (Object.prototype.hasOwnProperty.call(filter, 'include')) {
                let isIncluded = false
                for (let i = 0, len = filter.include.length; i < len; i += 1) { // TODO: check if for is really needed
                  const value = filter.include[i]
                  if (item[prop] === value) {
                    isIncluded = true
                  }
                }
                if (!isIncluded) {
                  return false
                }
              }
              if (Object.prototype.hasOwnProperty.call(filter, 'exclude')) {
                let isExcluded = false
                for (let i = 0, len = filter.exclude.length; i < len; i += 1) { // TODO: check if for is really needed
                  const value = filter.exclude[i]
                  // if (item[prop].toLowerCase() === value.toLowerCase()) {
                  if (item[prop] === value) {
                    isExcluded = true
                  }
                }
                if (isExcluded) {
                  return false
                }
              }
            }
          }
          return true
        })
      }

      if (payload && payload.sorting) {
        if (payload.sorting.toLowerCase() === 'alphabetically') {
          result = result.sort((a, b) => {
            const nameA = a.Name.toUpperCase()
            const nameB = b.Name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        }

        if (payload.sorting.toLowerCase() === 'ranking') {
          result = result.sort((a, b) => {
            const rankinga = a.Sort
            const rankingb = b.Sort
            if (rankinga < rankingb) {
              return -1
            }
            if (rankinga > rankingb) {
              return 1
            }
            return 0
          })
        }

        if (payload.sorting.toLowerCase() === 'booth') {
          // eslint-disable-next-line max-len
          const comparator = (a, b) => [...a.BoothNumbers].shift()?.toString().localeCompare([...b.BoothNumbers].shift()?.toString(), 'en', { numeric: true })
          result = result.sort(comparator)
        }
      }

      if (payload?.tags) {
        const { tags } = payload
        if (tags.length) {
          result = result.filter((item) => item.Tags?.some((t) => tags.includes(t)))
        }
      }
      commit('stopSearching')
      return result
    },
    async getProfileByIdV2({ commit, state, dispatch }, payload) {
      if (state.list == null) {
        await dispatch('load')
      }

      return state.list.find((exhibitor) => exhibitor.CompanyAccountId === payload)
    }
  },
  getters: {
    list(state) {
      return state.list
    },
    loading(state) {
      return state.loading
    },
    searching(state) {
      return state.searching
    },
  },
}
export default exhibitorList
