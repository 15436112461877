import uniFetch from '~/api/uniFetch'

const initialState = {
  EventDetails: {
    days: [],
  },
}

const getters = {
  getProgramDays: (state) => (selectedDates) => {
    if (!selectedDates) {
      return state.EventDetails.days
    }
    return state.EventDetails.days.filter((day) => {
      const selectedItem = selectedDates.find((item) => day.date === item.date)
      return selectedItem
    })
  },
}

const actions = {
  fetchConfig({ commit, state }) {
    if (!state.EventDetails.days.length) {
      uniFetch`${(p) => p.apiBase}/api/Public/EventDetails`({
        query: {
          shortname: (p) => p.eventShortname,
          language: (p) => p.language,
        },
      })
        .then((res) => {
          commit('setState', { name: 'EventDetails', data: res })
        })
    }
  },
}

const mutations = {
  setState: (state, payload) => { state[payload.name] = payload.data },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
}
