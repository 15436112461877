import store from '~/store'
import apiBase from './base'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function viewCount(eventId, exhibitorId) {
  if (useApiMockData) {
    return
  }
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/SetCompanyAccountViewCount/${eventId}?id=${exhibitorId}`
  const response = await apiBase.get(url)
  if (response) {
    return response.data
  }
}

export default {
  viewCount,
}
