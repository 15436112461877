<template>
  <div class="social-media-component__container">
    <template v-if="link && (link.twitter || link.instagram)">
      <b-tabs>
        <template v-if="link && link.twitter">
          <b-tab>
            <template #title>
              <!--            {{ $t('VMX.social_media_component_twitter_headline') }}-->
              <div
                v-if="link && link.instagram && link.instagram.url != ''"
                class="twitter-feed__nav"
              >
                <span> Twitter </span> <i class="fab fa-twitter" />
              </div>
            </template>
            <a
              class="twitter-timeline"
              :data-height="boxHeight"
              data-chrome="nofooter"
              :href="link.twitter.url"
            >Tweets</a>
          </b-tab>
        </template>
        <template v-if="link && link.instagram">
          <b-tab>
            <template #title>
              <!--            {{ $t('VMX.social_media_component_instagram_headline') }}-->
              <font-awesome-icon :icon="['fab', 'instagram']" /><span>
                Instagram</span>
            </template>
            <iframe
              :src="`https://www.instagram.com/p/${link.instagram.postId}/embed/captioned`"
              :height="link.instagram.height"
              frameborder="0"
              scrolling="no"
              allowtransparency="true"
            />
          </b-tab>
        </template>
      </b-tabs>
    </template>
    <template v-else>
      <a
        class="twitter-timeline"
        :data-height="boxHeight"
        data-chrome="nofooter"
        :href="link"
      >Tweets</a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TwitterFeedHome',
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
    boxHeight: {
      type: String,
      default: '400',
    },
  },
  computed: {},
  mounted() {
    const twitterScript = document.createElement('script')
    twitterScript.setAttribute(
      'src',
      'https://platform.twitter.com/widgets.js',
    )
    document.head.appendChild(twitterScript)
  },
}
</script>

<style lang="scss">
.social-media-component__container {
  .nav.nav-tabs .nav-item {
    width: 50%;
    &:nth-child(2) {
      text-align: right;
    }

    .nav-link {
      &:hover {
        border: 0;
      }
      &.active {
        color: #000;
        background-color: #fff;
        border: 0;
        width: fit-content;
      }
    }
  }
  .twitter-feed__nav {
    display: flex;
    gap: 1.5rem;
    i {
      font-size: 1.5rem;
    }
  }
  .timeline-Widget h1.timeline-Header-title {
    line-height: unset;
    font-size: 1rem;
  }
}
</style>
