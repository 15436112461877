import eventDashboardApi from '../../api/eventDashboard'

const eventDashboard = {
  namespaced: true,
  state: {
    eventDashboard: null,
    eventDashboardPromise: null,
  },
  mutations: {
    setEventDashboard(state, payload) {
      state.eventDashboard = payload
    },
    setEventDashboardPromise(state, payload) {
      state.eventDashboardPromise = payload
    },
  },
  actions: {
    async load({ commit, state }, programGrade) {
      if (state.eventDashboard) {
        return state.eventDashboard
      }
      if (state.eventDashboardPromise) {
        return state.eventDashboardPromise
      }
      const fetchPromise = eventDashboardApi.getEventDashboard().then((res) => {
        commit('setEventDashboard', res)
      })
      commit('setEventDashboardPromise', fetchPromise)

      return fetchPromise
    },
  },
  getters: {
    eventDashboard(state) {
      return state.eventDashboard
    },
  },
}

export default eventDashboard
