import VueCookies from 'vue-cookies'
import Vue from 'vue'
import store from '~/store'
import userState from '../states/user'

import trackingApi from '../api/tracking'
import tracking from '../utilities/tracking'

Vue.use(VueCookies)

export default async function ({ next, to }) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']

  const [backendConfig] = store.getters['config/configForKey']('app_config')?.config
  const user = await userState.getUser(instance)

  if (user) {
    await store.dispatch('user/loadUser')
    await store.dispatch('vmUser/loadVmUser')
  }
  const userData = store.getters['user/user']
  if (user && userData) {
    //  TODO: make dynamic: check for boothguru, add the ticket in an array in the config
    if (event === 'ectrims2021') {
      const userTickets = store.getters['userTicket/userTicket']
      // eslint-disable-next-line max-len
      const isExhibitor = (userTickets?.every((ticket) => ticket.ParticipantTypeName?.toLowerCase() === 'free registration exhibitor'
        || ticket.ParticipantTypeName?.toLowerCase() === 'additional registration exhibitor'
        || ticket.ParticipantTypeName?.toLowerCase() === 'free registration sponsor') || null)
      if (isExhibitor) localStorage.setItem('salesRepId', userData?.Email)
      else localStorage.removeItem('salesRepId')
    }
    if (
      userData.SelectedInterests === null
      || (userData.SelectedInterests?.length === 1 && userData.SelectedInterests[0] === 'Empty Collection')
    ) {
      Vue.$cookies.set('userinterests', '')
    } else {
      Vue.$cookies.set('userinterests', userData.SelectedInterests.join('|'))
    }
    // replace after 9.6.0.0
    if (
      userData.Contact?.CountryId
      && userData.CountryListV2
      && !!userData.CountryListV2.find((item) => item.Id === userData.Contact.CountryId)
    ) {
      Vue.$cookies.set('user_country', userData.CountryListV2.find((item) => item.Id === userData.Contact.CountryId).Name)
    } else {
      Vue.$cookies.set('user_country', null)
    }
    if (
      userData.Contact?.CountryId
      && userData.CountryListV2
      && !!userData.CountryListV2.find((item) => item.Id === userData.CountryPracticeId)
    ) {
      Vue.$cookies.set('user_countryPractice', userData.CountryListV2.find((item) => item.Id === userData.CountryPracticeId).Name)
    } else {
      Vue.$cookies.set('user_countryPractice', null)
    }
    if (
      userData.Contact?.CountryId
      && userData.CountryListV2
      && !!userData.CountryListV2.find((item) => item.Id === userData.CountryResidenceId)
    ) {
      Vue.$cookies.set(
        'user_countryResidence',
        userData.CountryListV2.find((item) => item.Id === userData.CountryResidenceId).Name,
      )
    } else {
      Vue.$cookies.set('user_countryResidence', null)
    }
    if (userData.Contact?.City) {
      Vue.$cookies.set('user_city', userData.Contact.City)
    } else {
      Vue.$cookies.set('user_city', userData.Contact.City)
    }
    if (userData.Gender !== null) {
      if (userData.Gender === true) {
        Vue.$cookies.set('user_gender', 'male')
      } else if (userData.Gender === false) {
        Vue.$cookies.set('user_gender', 'female')
      } else {
        Vue.$cookies.set('user_gender', null)
      }
    } else {
      if(userData.GenderExtended) {
        Vue.$cookies.set('user_gender', userData.GenderExtended)
      } else {
        Vue.$cookies.set('user_gender', null)

      }
    }


   

    if (backendConfig?.metricTracker?.trackImportId) {
      if (userData.ImportId) Vue.$cookies.set('sso_id', userData.ImportId)
      else Vue.$cookies.set('sso_id', null)
    } else {
      Vue.$cookies.set('sso_id', null)
    }

    Vue.$cookies.set('email', userData.Email)
    Vue.$cookies.set('firstname', userData.Firstname)
    Vue.$cookies.set('lastname', userData.Surname)
    Vue.$cookies.set('m_userid', userData.UserId)
    localStorage.setItem('userId', userData.UserId)
    Vue.$cookies.set('profession', userData.Profession)
    Vue.$cookies.set('hcp', userData.HealthCareProfessional)
    //  TODO: make dynamic: either config or if only for ers then check for instance and not event?
    if (to.params.event === 'ers2021') {
      const birthday = new Date(userData.Birthday)
      Vue.$cookies.set('birthyear', birthday.getFullYear().toString())
      Vue.$cookies.set('gender', userData.Gender)
    }
  }
  const vmUserData = store.getters['vmUser/vmUser']
  if (user && vmUserData) {
    Vue.$cookies.set('access_token', vmUserData.access_token)
    Vue.$cookies.set('event_id', vmUserData.event_id)
    Vue.$cookies.set('user_id', vmUserData.user_id)
  }

  if (backendConfig.metricTracker?.isActive) {
    const { UserId } = store.getters['user/user'] || ''
    trackingApi.trackingInit(UserId)
    if (localStorage.getItem('VMXUtil') && UserId) {
      if (backendConfig.metricTracker?.ipifyKey && backendConfig.metricTracker?.ipTracking) {
        // ipify geolocation
        trackingApi.trackIp(UserId, backendConfig.metricTracker.ipifyKey)
        localStorage.removeItem('VMXUtil')
      }
    }
  }
  if (backendConfig && backendConfig.tracking) {
    tracking.init(backendConfig.tracking).then(() => {})
    next()
  } else {
    next()
  }
}
