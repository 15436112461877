<template>
  <modal
    :show="show"
  >
    <div id="ratingDialog" class="ratingDialog-overlay">
      <div class="ratingDialog-contentDiv d-flex justify-content-center">
        <div class="ratingDialog-content">
          <!-- Button to close the ratingDialog-overlay navigation -->
          <p class="ratingDialog-centeredContent">
            <span>
              <h6 class="ratingDialog-title" v-html="$t('VMX.rating_modal_title')" />
            </span>
            <br />
            <span>
              <span v-html="$t('VMX.rating_modal_description')" />
            </span>

            <br />
            <br />
            <br />
            <br />
            <b-button
              variant="primary"
              @click.prevent="closeModal()"
            >
              <span>{{ $t("VMX.rating_modal_accept_btn") }}</span>
            </b-button>
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import modal from 'm-anage.com.vmx-components/src/Modal'

export default {
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  components: { modal },
  mounted() {
    this.show = true
  },
  methods: {
    closeModal() {
      this.show = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>

.ratingDialog-centeredContent {
  padding: 3% 3%;
  .ratingDialog-title {
    font-size: 24px;
  }
}

.no-access-text-decoration {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.ratingDialog-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(0, 13, 38, 0.9);
  overflow-x: hidden;
}
.ratingDialog-contentDiv {
  position: relative;
  top: 25vh;
}
.ratingDialog-content {
  width: 600px;
  min-height: 222px;
  background-color: white;
  margin: 0 30px;
}

.ratingDialog-content .text {
  font-size: 16px;
}

</style>
