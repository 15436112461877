import apiBase from './base'
import store from '../store'

async function getEventDashboard() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/EventDashboard?eventid=${eventId}&language=${locale}`
  const response = await apiBase.get(url)
  return response.data
}


export default {
    getEventDashboard,
}
