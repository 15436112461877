import highlightedSessionsApi from '../../api/highlightedSessions'

const highlightedSessions = {
  namespaced: true,
  state: {
    lists: {},
    sessionsList: [],
    sessionFetchPromises: [],
    fetchPromises: {},
    presentationLists: [],
    fetchPresentationPromises: [],
    authorLists: [],
    fetchAuthorPromises: [],
  },
  mutations: {
    setList(state, payload) {
      state.lists[payload.programGrade] = payload.list
    },
    setSessionsList(state, payload) {
      state.sessionsList = payload
    },
    setFetchPromise(state, payload) {
      state.fetchPromises[payload.programGrade] = payload.fetchPromise
    },
    setSessionFetchPromise(state, payload) {
      state.fetchPromises = payload
    },
    setPresentationList(state, payload) {
      state.presentationLists = payload.presentationLists
    },
    setPresentationFetchPromise(state, payload) {
      state.fetchPresentationPromises = payload.fetchPresentationPromises
    },
    setAuthorList(state, payload) {
      state.authorLists = payload.authorLists
    },
    setAuthorFetchPromise(state, payload) {
      state.fetchAuthorPromises = payload.fetchAuthorPromises
    },
  },
  actions: {
    async load({ commit, state }, payload) {
      const { programGrade } = payload
      if (state.lists?.programGrade) {
        return state.lists[payload.programGrade]
      }
      if (state.fetchPromises?.programGrade) {
        return state.fetchPromises[payload.programGrade]
      }
      const fetchPromise = highlightedSessionsApi.getHighlightedSessions(payload).then((list) => {
        commit('setList', { programGrade, list })
      })
      commit('setFetchPromise', { programGrade, fetchPromise })
      return fetchPromise
    },
    async selectedSessionsLoad({ commit }, sessionIds) {
      const fetchPromise = highlightedSessionsApi.getSelectedSessions(sessionIds).then((list) => {
        commit('setSessionsList', list)
      })
      commit('setSessionFetchPromise', fetchPromise )
      return fetchPromise
    },
    async loadPresentations({ commit, state }, payload) {
      if (state.presentationLists.length > 0) {
        return state.presentationLists
      }
      if (state.fetchPresentationPromises.length > 0) {
        return state.fetchPresentationPromises
      }
      const fetchPresentationPromises = highlightedSessionsApi.getHighlightedPresentations(payload).then((presentationLists) => {
        commit('setPresentationList', { presentationLists })
      })
      commit('setPresentationFetchPromise', { fetchPresentationPromises })
      return fetchPresentationPromises
    },
    async loadAuthors({ commit, state }) {
      if (state.authorLists.length > 0) {
        return state.authorLists
      }
      if (state.fetchAuthorPromises.length > 0) {
        return state.fetchAuthorPromises
      }
      const fetchAuthorPromises = highlightedSessionsApi.getHighlightedAuthors().then((authorLists) => {
        commit('setAuthorList', { authorLists })
      })
      commit('setAuthorFetchPromise', { fetchAuthorPromises })
      return fetchAuthorPromises
    },
  },
  getters: {
    list: (state) => (programGrade) => {
      if (Object.prototype.hasOwnProperty.call(state.lists, programGrade)) {
        return state.lists[programGrade]
      }
      return null
    },
    sessionsList(state) {
      return state.sessionsList
    },
    presentationList(state) {
      return state.presentationLists
    },
    authorList(state) {
      return state.authorLists
    },
  },
}

export default highlightedSessions
