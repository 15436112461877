import viewCountApi from '../api/viewCount'
import store from '~/store'

export default async function ({ next, to }) {
  const eventId = store.getters['instancesState/getEventId']
  const exhibitorId = to.params.companyAccountId

  viewCountApi.viewCount(eventId, exhibitorId)

  next()
}
