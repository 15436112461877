<template>
  <div
    v-if="componentIsActive"
    class="highlighted-sessions"
  >
    <h2
      class="h2 highlighted-sessions__title"
      v-if="title"
    >
      {{ title }}
    </h2>

    <div class="highlighted-sessions__list">
      <div
        class="highlighted-sessions__list__item"
        v-for="(session, idx) in sessions"
        :key="idx"
      >
        <session-card :session="session" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import SessionCard from '../sessionCard.vue'

export default {
  name: 'HighlightedSessions',
  components: { SessionCard },
  data() {
    return {
      sessions: null,
    }
  },
  mixins: [
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  props: {
    programGrade: {
      type: String,
      default: 'Scientific',
    },
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      list: 'highlightedSessions/list',
    }),
  },
  methods: {
    ...mapActions({
      load: 'highlightedSessions/load',
    }),
  },
  async created() {
    await this.load({programGrade: this.programGrade})
    this.sessions = this.list(this.programGrade)
  },
}
</script>

<style lang="scss" scoped>

  .highlighted-sessions__title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .highlighted-sessions__list {
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: hidden;
    min-width: 100%;
  }

  .highlighted-sessions__list__item {
    margin: 0 10px 10px 0 ;
    flex: 0 0 calc(100% - 10px);
    width: calc(100% - 10px);
  }

  /* tablet */
  @media (min-width: 768px) {
      .highlighted-sessions__list__item {
        flex-basis: calc(50% -  10px);
        width: calc(50% -  10px);
      }

    .component-grid__item--is-one-third {
      .highlighted-sessions__list__item {
        flex-basis: calc(100% -  10px);
      }
    }
  }

  /* Desktop */
  @media (min-width: 992px) {
    .highlighted-sessions__list__item {
      flex-basis: calc(33.333% -  10px);
      width: calc(33.333% - 10px);
    }

    .component-grid__item--is-two-thirds {
      .highlighted-sessions__list__item {
        flex-basis: calc(50% - 10px);
      }
    }

    .component-grid__item--is-one-third {
      .highlighted-sessions__list__item {
        flex-basis: calc(100% - 10px);
      }
    }

  }
</style>
