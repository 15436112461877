import exhibitionApi from '../../../api/exhibition'

const exhibitorProfile = {
  namespaced: true,
  state: {
    profiles: [],
    profilesIdIndexMapping: {},
    symposias: [],
    loadingProfile: 0,
  },
  mutations: {
    addProfile(state, payload) {
      const idx = state.profiles.push(payload) - 1
      state.profilesIdIndexMapping[payload.CompanyAccountId] = idx
    },

    startLoadingProfile(state) {
      state.loadingProfile++
    },
    stopLoadingProfile(state) {
      state.loadingProfile--
    },
  },
  actions: {
    async getProfileById({ commit, state }, id) {
      if (!Object.prototype.hasOwnProperty.call(state.profilesIdIndexMapping, id)) {
        commit('startLoadingProfile', id)
        const companyAccountProfile = await exhibitionApi.getCompanyAccountProfile(id)
        if (companyAccountProfile) {
          commit('addProfile', companyAccountProfile)
        }
        commit('stopLoadingProfile', id)
      }

      if (Object.prototype.hasOwnProperty.call(state.profilesIdIndexMapping, id)) {
        const idx = state.profilesIdIndexMapping[id]
        return state.profiles[idx]
      }
      return null
    },
  },
  getters: {
    loadingProfile(state) {
      return state.loadingProfile
    },
  },
}
export default exhibitorProfile
