import apiBase from './base'
import store from '../store'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getSessionsForEvaluation() {
  const eventId = store.getters['instancesState/getEventId']
  let url = ''
  if (useApiMockData) {
    url = ''
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/SessionsForEvaluation?eventid=${eventId}`
  }
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function getSurveyModel(data) {
  let url = ''
  if (useApiMockData) {
    url = ''
  } else {
    url = `${store.getters['instancesState/getSurveyApi']}/Survey/Surveyjs`
  }
  const response = await apiBase.post(url, data)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function getSurvey(sessionId) {
  const eventId = store.getters['instancesState/getEventId']
  const uId = store.getters['user/user'].UserId

  let url = ''
  if (useApiMockData) {
    url = ''
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/Survey/Session?eventid=${eventId}&userid=${uId}&id=${sessionId}`
  }
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function getEventSurvey() {
  const eventId = store.getters['instancesState/getEventId']
  const uId = store.getters['user/user'].UserId

  let url = ''
  if (useApiMockData) {
    url = ''
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/Survey/Event?eventid=${eventId}&userid=${uId}`
  }
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function setEvaluationDone(surveyContext, sessionId) {
  const userId = store.getters['user/user'].UserId
  const apiBaseUrl = store.getters['instancesState/getApiBaseUrl']
  let response = null
  if (surveyContext === 'Event') {
    response = await apiBase.post(`${apiBaseUrl}/api/Survey/EventEvaluationDone?userid=${userId}&eventid=${store.getters['instancesState/getEventId']}`)
  } else if (surveyContext === 'Session') {
    response = await apiBase.post(`${apiBaseUrl}/api/Survey/SessionEvaluationDone?userid=${userId}&sessionid=${sessionId}`)
  }
  if (response && response.status === 200) {
    await store.dispatch('sessionsForEvaluation/reloadSessions')
  }
  return null
}

async function saveSurvey(context, contextShort, instance, result, surveySetId, surveyEnitityId, surveyContext, sessionId) {
  const url = `${store.getters['instancesState/getSurveyApi']}/Survey/SaveSurvey`
  const userId = store.getters['user/user'].UserId
  const data = {
    Context: context,
    ContextShort: contextShort,
    Instance: instance,
    Result: JSON.stringify(result),
    SurveyItemSetId: surveySetId,
    SurveyEnitity: { Id: surveyEnitityId },
    UserId: userId,
  }
  const response = await apiBase.post(url, data)
  if (response && response.status === 200) {
    await setEvaluationDone(surveyContext, sessionId)
  }
  return null
}

export default {
  getSessionsForEvaluation,
  getSurvey,
  saveSurvey,
  getSurveyModel,
  getEventSurvey,
  setEvaluationDone,
}
