import axios from 'axios'
import store from '~/store'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
// import apiBase from './base'

async function getAuthToken() {
  const authToken = Vue.$cookies.get('pag_auth_token')
  if (authToken) {
    return authToken
  }
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const params = {
    username: 'mobileApps@m-anage.com',
    password: 'vw9oGP3yz?NZw3eZ>9XXRT^K',
  }
  const config = {
    method: 'post',
    url: `${store.getters['instancesState/getApiBaseUrl']}/api/Core/AuthenticationToken`,
    headers,
    params,
  }
  const response = await axios(config)
    .then((result) => result.headers.authenticationtoken)
    .catch((error) => console.log(error))

  Vue.$cookies.set('pag_auth_token', response)
  return response
}

async function sendCustomMail(params) {
  const authToken = await getAuthToken()

  const {
    ToAddresses,
    FromAddresses,
    Subject,
    Content,
  } = params
  const data = {
    ToAddresses,
    FromAddresses,
    Subject,
    Content,
  }
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Mail/SendCustomMail?eventid=${eventId}&mailType=ContactSpeaker`
  const headers = {
    Authorization: `AuthenticationToken ${authToken}`,
    'Content-Type': 'application/json',
  }
  const config = {
    method: 'post',
    url,
    headers,
    data,
  }
  const result = await axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response.status
      }

      return false
    })
    .catch((error) => {
      console.log(error)
      return false
    })
  return result
}

async function getComments(params) {
  const authToken = await getAuthToken()
  const headers = {
    Authorization: `AuthenticationToken ${authToken}`,
    'Content-Type': 'application/json',
  }

  const eventId = store.getters['instancesState/getEventId']
  const {
    userId,
    sessionId,
    presentationId,
    isNote,
  } = params
  const data = ''
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/GetNotes?id=${eventId}&pUserId=${userId}&pSessionId=${sessionId}&pIsNote=${isNote}`
  if (presentationId) url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/GetNotes?id=${eventId}&pUserId=${userId}&pPresentationId=${presentationId}&pIsNote=${isNote}`
  const config = {
    method: 'get',
    url,
    headers,
    data,
  }
  const response = await axios(config)
    .then((result) => result.data)
    .catch((error) => console.log(error))
  return response
}

async function createSessionComment(params) {
  const authToken = await getAuthToken()
  const eventId = store.getters['instancesState/getEventId']

  const { sessionId, userId, isNote, content, visibility, parentId } = params

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/CreateNote?id=${eventId}&pUserId=${userId}&pSessionId=${sessionId}&pContent=${content}&pIsNote=${isNote}&pVisibility=${visibility}&parentId=${parentId}`
  const headers = {
    Authorization: `AuthenticationToken ${authToken}`,
    'Content-Type': 'application/json',
  }
  const data = ''
  const config = {
    method: 'post',
    url,
    headers,
    data,
  }

  const result = await axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      console.log(error)
      return false
    })

  return result
}

async function createPresentationComment(params) {
  const authToken = await getAuthToken()
  const eventId = store.getters['instancesState/getEventId']

  const { presentationId, userId, isNote, content, visibility, parentId } = params

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/CreatePresentationNote?id=${eventId}&pUserId=${userId}&pPresentationId=${presentationId}&pContent=${content}&pIsNote=${isNote}&pVisibility=${visibility}&parentId=${parentId}`
  const headers = {
    Authorization: `AuthenticationToken ${authToken}`,
    'Content-Type': 'application/json',
  }
  const data = ''
  const config = {
    method: 'post',
    url,
    headers,
    data,
  }

  const result = await axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      console.log(error)
      return false
    })

  return result
}
async function getCommentLike(params) {
  const authToken = await getAuthToken()
  const eventId = store.getters['instancesState/getEventId']

  const { noteId } = params

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/SetNoteLikeCount?eventid=${eventId}&noteid=${noteId}`
  const headers = {
    Authorization: `AuthenticationToken ${authToken}`,
    'Content-Type': 'application/json',
  }
  const data = ''
  const config = {
    method: 'get',
    url,
    headers,
    data,
  }

  const result = await axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      console.log(error)
      return false
    })

  return result
}

async function getInappropriateComment(params) {
  const authToken = await getAuthToken()
  const eventId = store.getters['instancesState/getEventId']

  const { noteId } = params

  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/App/SetNoteInappropriate?id=${eventId}&pNoteId=${noteId}`
  const headers = {
    Authorization: `AuthenticationToken ${authToken}`,
    'Content-Type': 'application/json',
  }
  const data = ''
  const config = {
    method: 'post',
    url,
    headers,
    data,
  }

  const result = await axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response
      }
      return false
    })
    .catch((error) => {
      console.log(error)
      return false
    })

  return result
}

export default {
  sendCustomMail,
  getComments,
  createSessionComment,
  createPresentationComment,
  getCommentLike,
  getInappropriateComment,
}
