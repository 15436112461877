export default {
  accessDeniedDialog() {
    const event = new CustomEvent('accessDenied', {
      detail: {
        message: 'accessDenied',
      },
    })
    window.dispatchEvent(event)
  },

  boothGuruDialog() {
    const event = new CustomEvent('boothGuru', {
      detail: {
        message: 'boothGuru',
      },
    })
    window.dispatchEvent(event)
  },

  hcpDialog() {
    const event = new CustomEvent('hcpDialog', {
      detail: {
        message: 'hcpDialog',
      },
    })
    window.dispatchEvent(event)
  },

  contactFormDialog(firstname, surname, email) {
    const speaker = {}
    speaker.Firstname = firstname
    speaker.Surname = surname
    speaker.Email = email

    const event = new CustomEvent('contactForm', {
      detail: {
        message: 'contactForm',
        info: speaker,
      },
    })
    window.dispatchEvent(event)
  },

  symposiaLeadsDialog(data, callback) {
    const event = new CustomEvent('symposiaLeads', {
      detail: {
        message: 'symposiaLeads',
        info: data,
        callback,
      },
    })
    window.dispatchEvent(event)
  },

  ratingReminderDialog() {
    const event = new CustomEvent('ratingReminder', {
      detail: {
        message: 'ratingReminder',
      },
    })
    window.dispatchEvent(event)
  },
}
