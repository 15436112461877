<template>
  <div
    v-if="componentIsActive"
    class="company-logo-grid"
  >
    <h2
      class="h2 company-logo-grid__title"
      v-if="title"
      v-html="title"
    />

    <div
      class="company-logo-grid-container"
      :style="gridStyles"
    >
      <div
        v-for="companyAccount in companyAccounts"
        :key="companyAccount.companyAccountId"
        class="company-logo-grid__item"
      >
        <template v-if="companyAccount.CustomLogoLink">
          <a
            :href="companyAccount.CustomLogoLink"
            target="_blank"
          >
            <div class="company-logo-grid__item__content">
              <img
                v-lazy="companyAccount.LogoPath"
                class="company-logo-grid__item__image"
              />
            </div>
          </a>
        </template>
        <template v-else-if="companyAccount.isPublic === false">
          <div
            class="company-logo-grid__item__content"
          >
            <img
              v-lazy="companyAccount.LogoPath"
              class="company-logo-grid__item__image"
            />
          </div>
        </template>
        <template v-else>
          <router-link
            :to="getRoute(companyAccount)"
          >
            <div
              class="company-logo-grid__item__content"
              @click="sponsorTrackClick(companyAccount)"
            >
              <img
                v-lazy="companyAccount.LogoPath"
                class="company-logo-grid__item__image"
              />
            </div>
          </router-link>
        </template>

        <b-button
          class="company-logo-grid__item__button"
          v-if="companyAccount.CustomPdfLink"
          variant="primary"
          @click.prevent="openLink(companyAccount.CustomPdfLink)"
        >
          <span
            v-if="companyAccount.CustomButtonTextLabel"
            v-html="companyAccount.CustomButtonTextLabel"
          />
          <span
            v-else
            v-html="$t('VMX.CustomButtonDefaultTextLabel')"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import utils from '../../utilities/utils'
import themeUtil from '../../utilities/theme'
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import trackingApi from '~/api/tracking'

export default {
  name: 'CompanyLogoList',
  mixins: [
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  props: {
    title: {
      type: String,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    filterBy: {
      type: Object,
      default() {
        return {}
      },
    },
    sorting: {
      type: String,
      default: 'alphabetically',
    },
  },
  data() {
    return {
      companyAccounts: [],
    }
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
    }),
    gridStyles() {
      return {
        backgroundColor: this.replCssVar(this.backgroundColor),
      }
    },
  },
  methods: {
    replCssVar(val) {
      return themeUtil.replaceCssVar(val)
    },
    ...mapActions({
      filterList: 'publicExhibitorList/filterList',
    }),
    openLink(link) {
      window.open(link, '_blank')
    },
    getRoute(companyAccount) {
      const route = {
        name: 'ProfilePage',
        params: {
          companyAccountId: companyAccount.CompanyAccountId,
        },
      }
      utils.appendDefaultParamsToRoute(route)
      return route
    },
    async sponsorTrackClick(companyAccount) {
      trackingApi.trackCustomAction('vmx-sponsorgrid-action', companyAccount.CompanyAccountId, this.$route.name)
    },
  },
  async created() {
    this.companyAccounts = await this.filterList({ filterBy: this.filterBy, sorting: this.sorting })
    // ERS HACK REMOVE PLEASE AFTER CONGRESS.....
    const { config } = this.configForKey('industry_config')
    if (config?.companyLogoGridData) {
      if (this.title === 'Platinum Partner') {
        const items = config?.companyLogoGridData.Platinum
        if (items) {
          items.forEach((i) => {
            if (!this.companyAccounts.find((c) => c.CompanyAccountId === i.CompanyAccountId)) {
              this.companyAccounts.push(i)
            }
          })
        }
      }
      if (this.title === 'Gold Partner') {
        const items = config?.companyLogoGridData.Gold
        if (items) {
          items.forEach((i) => {
            if (!this.companyAccounts.find((c) => c.CompanyAccountId === i.CompanyAccountId)) {
              this.companyAccounts.push(i)
            }
          })
        }
      }
      if (this.title === 'Bronze Partner') {
        const items = config?.companyLogoGridData.Bronze
        if (items) {
          items.forEach((i) => {
            if (!this.companyAccounts.find((c) => c.CompanyAccountId === i.CompanyAccountId)) {
              this.companyAccounts.push(i)
            }
          })
        }
      }
    }
    //
  },
}
</script>

<style lang="scss">
  .company-logo-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
  }
  .company-logo-grid__item__button{
    position: absolute;
    bottom: 0;
    font-size: .7rem;
  }

  h2.company-logo-grid__title {
    font-size: 26px;
    font-weight: 700;
    margin-top: var(--link-box-grid-gap);
    margin-bottom: calc(var(--link-box-grid-gap)*1.5);
  }
  .company-logo-grid__item {
    display: flex;
    flex: 0 0 205px;

    position: relative;
    width: 205px;

    background-color: #fff;
    justify-content: center;
    margin: 10px;

    &:after {
      content: "";
      // 16/9
      padding-top: 56.25%;
    }

  }

  .company-logo-grid__item__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
  }

  .company-logo-grid__item__image {
    width: 100%;
    height: 100%;

    object-fit: contain;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;

  }
</style>
