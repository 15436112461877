import base from '~/api/base'
import store from '~/store/'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getTermsAndConditions() {
  const locale = store.getters['instancesState/getLanguage']
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/custominterfaces/oauth/UserConsent/TermsAndConditions?pContext=default&pLanguage=${locale}`
  if (useApiMockData) {
    url = '/mock-data/user-document.json'
  }
  const response = await base.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

async function getTermsAndConditionsOrg() {
  const locale = store.getters['instancesState/getLanguage']
  const eventId = store.getters['instancesState/getEventId']
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/custominterfaces/oauth/UserConsent/TermsAndConditions?pContext=event&pContextId=${eventId}&pLanguage=${locale}`
  if (useApiMockData) {
    url = '/mock-data/user-document.json'
  }
  const response = await base.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

export default {
  getTermsAndConditions,
  getTermsAndConditionsOrg,
}
