import axios from 'axios'
import store from '~/store'


const getSsoUrl = () => {
  return `${store.getters['ModuleConfig/moduleConfig'].ssoConf}`
}

const getEventShortName = () => {
  return `${store.getters['ModuleConfig/moduleConfig'].eventShort}`
}

const getIdentityUrl = () => {
  return `${store.getters['ModuleConfig/moduleConfig'].identityUrl}`
}

const getApiUrl = () => {
  return `${store.getters['ModuleConfig/moduleConfig'].apiUrl}`;
}

const getReturnUrl = () => {
  return `${store.getters['ModuleConfig/moduleConfig'].returnUrl}`
}

const getCurrentUrl = () => {
  return location.origin + location.pathname;
}

async function configuration() {
  const url = `${getApiUrl()}/v2/event/${getEventShortName()}/config/sso/links`
  // if node env == local -> url = `http://localhost:49154/v2/event/${getEventShortName()}/config/sso/links`
  const response = await axios.get(url);
  return response;
}

async function correlate(payload) {
  const returnUrl = payload.returnUrl
  const response = await axios.get(`${getIdentityUrl()}/api/token/external/correlate?returnUrl=${returnUrl}`, { withCredentials: true })
  return response
}

async function registerExternalAccount() {
  const response = await axios.get(`${getIdentityUrl()}/api/token/external/register?acceptedTerms=true&returnUrl=${getCurrentUrl()}`, { withCredentials: true })
  return response
}

async function acceptTermsAndConditions() {
  const url = `${getApiUrl()}/api/core/SetTermsAccepted?context=Event&shortName=${getEventShortName()}`
  const token = `${store.getters['singleSignOn/ssoUserToken']}`
  const options = { headers: { Authorization: `Bearer ${token}`, withCredentials: true } }
  //const returnUrl = `${store.getters['singleSignOn/setRedirectUrl']}` // returned from correlate funct    
  //const response = await axios.get(`${url}&jwt_token=${token}&returnUrl=${returnUrl}`, options)
  //asp - not sure with both parameters because the endpoint doesnt have them
  const response = await axios.get(`${url}`, options)
  return response
}

async function userPasswordConfirm(formData, returnUrl) {
  const url = `${getIdentityUrl()}/api/token/external/confirm?returnUrl=${returnUrl}&acceptedTerms=True`
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
  return response
}

async function externalRegisterAccount(formData) {
  const url = `${getIdentityUrl()}/api/token/external/register/password`
  const response = await axios.post(url, formData, { withCredentials: true })
  return response
}

async function loadExternalUserCompareData() {
  const token = `${store.getters['singleSignOn/ssoUserToken']}`
  const options = { headers: { Authorization: `Bearer ${token}`, withCredentials: true } }
  const url = `${getApiUrl()}/api/user/ExternalUserCompareData?context=Event&shortName=${getEventShortName()}`
  const response = await axios.get(url, options)
  return response.data
}

async function syncExternalUser(doSync) {
  const token = `${store.getters['singleSignOn/ssoUserToken']}`
  const options = { headers: { Authorization: `Bearer ${token}`, withCredentials: true } }
  const url = `${getApiUrl()}/api/user/SyncExternalUser?context=Event&shortName=${getEventShortName()}&doSyncContact=${doSync}`
  const response = await axios.post(url, null, options)
  return response
}

export default {
  configuration,
  correlate,
  registerExternalAccount,
  acceptTermsAndConditions,
  userPasswordConfirm,
  externalRegisterAccount,
  loadExternalUserCompareData,
  syncExternalUser
}