import axios from 'axios'

import userState from '../states/user'
import store from '../store'

// todo: match multiple patters
// const exceptions = 'SetCompanyAccountViewCount'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status !== 401) {
      store.commit('Error/addError', error)
    }
    if (error.response) {
      console.error(error.response)
      store.commit('Error/addError', error)
    }
    if (error.response?.status === 503) {
      store.commit('instancesState/setMaintenance', true)
    }
  },
)

function getHeaders() {
  const headers = {}
  const user = userState.getUser(store.getters['instancesState/getInstance'])
  if (user) {
    headers.Authorization = `Bearer ${user.accessToken}`
  }
  return headers
}

function getMEventsHeaders() {
  const headers = {}
  headers.Authorization = 'Basic m-events:kims'
  return headers
}

function getBoothGuruHeaders() {
  const headers = {}
  headers['Content-Type'] = 'application/json'
  return headers
}

function getFileUploadHeaders() {
  const headers = {}
  const user = userState.getUser(store.getters['instancesState/getInstance'])
  if (user) {
    headers.Authorization = `Bearer ${user.accessToken}`
  }
  headers['Content-Type'] = 'multipart/form-data'
  return headers
}

async function postWithFileUploadHeaders(url, data) {
  const headers = getFileUploadHeaders()
  return axios.post(url, data, {
    headers,
  })
}
async function callGet(url, headers, optionsIn) {
  const options = optionsIn || {}
  options.headers = headers
  return axios.get(url, options)
}

async function get(url, options, token) {
  const headers = getHeaders()
  return callGet(url, headers, options)
}

async function pdfGet(url, options, token) {
  const headers = getPDFHeaders(token)
  return callGet(url, headers, options)
}

function getPDFHeaders(token) {
  console.log(token)
  const headers = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return headers
}

async function getWithMEvents(url, options) {
  const headers = getMEventsHeaders()
  return callGet(url, headers, options)
}

async function getWithoutHeaders(url, options) {
  return callGet(url, {}, options)
}

async function callDelete(url) {
  const headers = getHeaders()
  await axios.delete(url, {
    headers,
  })
}

async function post(url, data, optionsIn) {
  const headers = getHeaders()
  const options = optionsIn || {}
  options.headers = headers
  return axios.post(url, data, options)
}

async function postWithBoothGuru(url, data) {
  const headers = getBoothGuruHeaders()
  return post(url, data, {
    headers,
  })
}
export default {
  get,
  pdfGet,
  getWithMEvents,
  getWithoutHeaders,
  postWithBoothGuru,
  callDelete,
  post,
  postWithFileUploadHeaders,
}
