import utils from '../utilities/utils'
import store from '~/store'

export default async function ({ next, to, router }) {
  const currentCompanyAccountId = to.params.companyAccountId

  // config
  const modelConfig = store.getters['instancesState/getModelConfig']
  if (utils.ObjectHasNestedProperty(modelConfig, 'SpecialProfiles')) {
    const specialProfiles = modelConfig.SpecialProfiles
    const customProfile = specialProfiles.find((companyAccountId) => (
      companyAccountId.id === currentCompanyAccountId
    ))

    if (customProfile && customProfile.page) {
      router.push({
        name: 'IndustryLandingPage',
        params: {
          instance: store.getters['instancesState/getInstance'],
          language: store.getters['instancesState/getLanguage'],
          event: store.getters['instancesState/getEvent'],
          page: customProfile.page,
          companyAccountId: currentCompanyAccountId,
        },
      })
    } else {
      next()
    }
  } else {
    next()
  }
}
