import store from '../store'

export default async function ({ next }) {
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']

  await Promise.all([
    store.dispatch('ILPIndustryModel/loadModel', { event, language }),
    store.dispatch('config/loadConfig'),
  ])

  const model = store.getters['ILPIndustryModel/model']
  const industryConfig = store.getters['config/configForKey']('industry_config')

  store.commit('instancesState/setCompanyId', model.CompanyId)
  store.commit('instancesState/setModelConfig', industryConfig.config)
  next()
}
