<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js"
      oncontextmenu="return false;"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import utils from '~/utilities/utils'

export default {
  name: 'VideoPlayer',
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  props: {
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      player: null,
      divisor: 25, // percent buckets ( 25%-75% )
      videos_status: {},
      videos: null,
    }
  },
  mounted() {
    this.loadAllScript()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },
  methods: {
    loadAllScript() {
      this.$loadScript('https://d2t0unnjxes6lt.cloudfront.net/data/player/assets/video.7.7.4.min.js').then(() => {
        this.loadStylesheet('https://d2t0unnjxes6lt.cloudfront.net/data/player/assets/video-js.7.7.4.css')
        this.loadStylesheet('https://d2t0unnjxes6lt.cloudfront.net/data/player/assets/videojs-vr.css')
        this.loadStylesheet('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css')
        this.initializePlayer()
      }).catch(() => {
        console.log('failed load video.7.7.4.min.js')
      })
    },
    initializePlayer() {
      this.player = window.videojs(
        this.$refs.videoPlayer,
        this.options,
        () => {
          // console.log('onPlayerReady', this);
        },
      )
      this.videos = document.getElementsByTagName('video')
      for (let i = 0; i < this.videos.length; i += 1) { // TODO: check if for is really needed
        // In order to have some id to reference in our status object,
        // we are adding an id to the video objects
        // that don't have an id attribute.
        let videoTagId
        if (!this.videos[i].getAttribute('id')) {
          // Generate a random alphanumeric string to use is as the id
          videoTagId = `html5_video_${Math.random().toString(36).slice(2)}`
          this.videos[i].setAttribute('id', videoTagId)
        } else { // Current video has alredy a id attribute, then let's use it
          videoTagId = this.videos[i].getAttribute('id')
        }
        // Video Status Object declaration
        this.videos_status[videoTagId] = {}
        // save the highest percent mark played by the user in the current video.
        this.videos_status[videoTagId].greatest_marker = 0
        // set the progress markers, so we can know afterwards which ones have been already sent.
        this.videos_status[videoTagId]._progress_markers = {}
        for (let j = 0; j < 100; j += 1) { // TODO: check if for is really needed
          this.videos_status[videoTagId].progress_point = this.divisor * Math.floor(j / this.divisor)
          this.videos_status[videoTagId]._progress_markers[
            this.videos_status[videoTagId].progress_point
          ] = false
        }
        // On page DOM, all players currentTime is 0
        this.videos_status[videoTagId].current = 0
        //  setting the event listeners.
        this.videos[i].addEventListener('play', this.eventHandler, false)
        this.videos[i].addEventListener('pause', this.eventHandler, false)
        this.videos[i].addEventListener('ended', this.eventHandler, false)
        this.videos[i].addEventListener('timeupdate', this.eventHandler, false)
      }
    },
    tracking(event, elementSrc) {
      if (utils.advancedTracking()) {
        this.TrackingUtil.trackEvent(
          'HTML5 Video',
          event,
          utils.advancedTrackingFormat(
            this.user.UserId,
            'Page',
            'Video',
            elementSrc,
          ),
        )
      }
    },
    loadStylesheet(url) {
      const ss = document.styleSheets
      for (let i = 0, max = ss.length; i < max; i += 1) {
        if (ss[i].href === url) {
          return
        }
      }
      const link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', url)
      document.head.appendChild(link)
    },
    eventHandler(e) {
      // This is the funcion that is gonna handle the event sent by the player listeners
      // This event type is sent everytime the player updated it's current time,
      // We're using for the % of the video played. // TODO: check if for is really needed
      if (e.type === 'timeupdate') {
        // Let's set the save the current player's video time in our status object
        this.videos_status[e.target.id].current = Math.round(
          e.target.currentTime,
        )
        // We just want to send the percent events once
        const pct = Math.floor(
          (100 * this.videos_status[e.target.id].current) / e.target.duration,
        )
        // TODO: check if for is really needed
        for (const j in this.videos_status[e.target.id]._progress_markers) {
          if (
            pct >= j
            && j > this.videos_status[e.target.id].greatest_marker
          ) {
            this.videos_status[e.target.id].greatest_marker = j
          }
        }
        // current bucket hasn't been already sent to GA?, let's push it to GTM
        if (
          this.videos_status[e.target.id].greatest_marker
          && !this.videos_status[e.target.id]._progress_markers[
            this.videos_status[e.target.id].greatest_marker
          ]
        ) {
          this.videos_status[e.target.id]._progress_markers[
            this.videos_status[e.target.id].greatest_marker
          ] = true
          this.tracking(
            `${this.videos_status[e.target.id].greatest_marker}%`,
            decodeURIComponent(e.target.currentSrc),
          )
        }
      } else if (e.type === 'play') {
        this.tracking(
          'Played video',
          decodeURIComponent(e.target.currentSrc),
        )
      } else if (e.type === 'pause') {
        this.tracking(
          'Paused video',
          decodeURIComponent(e.target.currentSrc),
        )
      } else if (e.type === 'ended') {
        // If the user ends playing the video, an Finish video will be pushed
        // ( This equals to % played = 100 )
        this.tracking('100%', decodeURIComponent(e.target.currentSrc))
      }
    },
  },
}
</script>
