import accessDeniedDialog from 'm-anage.com.vmx-components/src/Dialogs/AccesDeniedDialog'
import boothGuruDialog from '~/components/industryLandingPage/boothGuruDialog.vue'
import modalDialog from '~/utilities/modalDialog'
import accessControl from '~/utilities/accessControl'
import ContactFormModal from '~/components/speakerContactModal.vue'
import hcpDialog from '~/components/hcpDialog.vue'
import ratingReminder from '~/components/industryLandingPage/ratingModal.vue'

const dialogMixins = {
  data() {
    return {
      symposiaId: null,
      initialized: false,
      callback: null,
      eventFrame: null,
      speaker: null,
      contactFormInitialized: false,
      showContactFormModal: false,
      showSymposiaLeadsModal: false,
      showAccessDeniedModal: false,
      showBoothGuruModal: false,
      hcpInitialized: false,
      showHCPModal: false,
      ratingReminderInitialized: false,
      showRatingReminderModal: false,
    }
  },
  methods: {
    handleClose() {
      this.initialized = false
      this.showSymposiaLeadsModal = false
    },
    handleCloseContactForm() {
      this.contactFormInitialized = false
      this.showContactFormModal = false
    },
    handleCloseRatingReminder() {
      this.ratingReminderInitialized = false
      this.showRatingReminderModal = false
    },
    handleCloseHCPForm() {
      this.hcpInitialized = false
      this.showHCPModal = false
    },
    callAccessControlSession() {
      window.accessControlSession = function assignId(id) {
        if (!accessControl.accessControlSession(id)) {
          modalDialog.accessDeniedDialog()
          return false
        }
        return accessControl.accessControlSession(id)
      }
    },
    callSymposiaLeads() {
      window.symposiaLeadsDialog = function assignId(id, callback) {
        modalDialog.symposiaLeadsDialog(id, callback)
      }
    },
    callContactForm() {
      window.contactFormDialog = function assignId(firstname, surname, email) {
        modalDialog.contactFormDialog(firstname, surname, email)
      }
    },
    callRatingReminder() {
      window.callRatingReminder = function assignId() {
        modalDialog.ratingReminderDialog()
      }
    },
    callBoothGuruDialog() {
      window.boothGuruDialog = function assignData(data) {
        modalDialog.boothGuruDialog(data)
      }
    },
    callHCPDialog() {
      window.hcpDialog = function assignData() {
        modalDialog.hcpDialog()
      }
    },
  },
  components: {
    symposiaLeadsDialog: () => import('m-anage.com.vmx-components/src/Leads/symposiaLeads/index.vue'),
    accessDeniedDialog,
    boothGuruDialog,
    ContactFormModal,
    hcpDialog,
    ratingReminder,
  },
}

export default dialogMixins
