<template>
  <div
    v-if="componentIsActive"
    class="ui-content-box"
  >
    <template v-if="activateCustomContent && showBoxForExhibitor">
      <div
        v-html="customContent"
        :class="classes"
      />
    </template>
    <template v-else-if="activateCustomContent">
      <div
        v-html="customContent"
        :class="classes"
      />
    </template>
    <template v-else>
      <div :class="classes">
        <div v-html="$t(resource)" />
      </div>
    </template>
  </div>
</template>

<script>
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import utils from '~/utilities/utils'

export default {
  data() {
    return {
      script: null,
      embeds: {},
    }
  },
  mixins: [
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  props: {
    showExhibitorForId: {
      type: String,
      default: '',
    },
    resourceSetName: {
      type: String,
      default: '',
    },
    resourceName: {
      type: String,
      default: '',
    },
    activateCustomContent: {
      type: Boolean,
      default: false,
    },
    customContent: {
      type: String,
      default: '',
    },
    customScripts: {
      type: Array,
      default: () => [],
    },
    src: {
      type: String,
      default: '',
    },
    customScript: {
      type: String,
      default: '',
    },
    customScriptBody: {
      type: String,
      default: '',
    },
    flocklerId: {
      type: String,
      default: '',
    },
  },
  computed: {
    resource() {
      return `${this.resourceSetName}.${this.resourceName}`
    },
    classes() {
      return `content-box ${this.resourceName}`
    },
    showBoxForExhibitor() {
      if (this.showExhibitorForId && this.$route.params.companyAccountId === this.showExhibitorForId) {
        return true
      }
      return false
    },
  },
  methods: {
    checkScript(script) {
      return !$(`script[src='${script}']`).length > 0
    },
    renderEmbed(embedUuid) {
      this.embeds[embedUuid] = window.flcklr.Embeds.create(
        window.flcklr.EmbedConfigs[embedUuid],
      )
      window.renderEmbed = this.embeds[embedUuid]
    },
    destroyEmbed(embedUuid) {
      window.destroyEmbed = this.embeds[embedUuid].destroy()
      this.embeds[embedUuid] = null
      // document.querySelector('#flockler-embed-' + embedUuid).innerHTML = ''
    },
  },
  created() {
    if (this.customScript && this.activateCustomContent && this.showBoxForExhibitor) {
      setTimeout(() => {
        this.script = document.createElement('script')
        this.script.innerHTML = this.customScript
        if (this.checkScript(this.script)) {
          document.head.appendChild(this.script)
        }
      }, 200)
    }
    if (this.showBoxForExhibitor && this.activateCustomContent && window.Tawk_API.showWidget()) window.Tawk_API.showWidget()
  },
  mounted() {
    if (this.customScripts.length > 0) {
      this.customScripts.forEach((item) => {
        if (item.script.includes('flockler') && item.flocklerId) {
          this.$loadScript(item.script).then(() => {
            setTimeout(() => {
              this.renderEmbed(item.flocklerId)
            }, 500)
          })
        } else if (this.checkScript(item.script)) {
          if (item.scriptProps) {
            utils.loadScript(item.script, 0, item.scriptProps)
          } else {
            utils.loadScript(item.script)
          }
        }
      })
    }
    if (this.customScript?.includes('flockler') && this.flocklerId && this.activateCustomContent) {
      this.$loadScript(this.customScript).then(() => {
        setTimeout(() => {
          this.renderEmbed(this.flocklerId)
        }, 500)
      })
    }
    if (this.customScriptBody && this.activateCustomContent) {
      setTimeout(() => {
        this.script = document.createElement('script')
        this.script.innerHTML = this.customScriptBody
        if (this.checkScript(this.script)) {
          document.body.appendChild(this.script)
        }
      }, 400)
    }
  },
  beforeDestroy() {
    if (this.customScript) {
      this.$unloadScript(this.src)
      if (this.script) {
        this.script.parentNode.removeChild(this.script)
      }
      if (this.flocklerId) {
        this.destroyEmbed(this.flocklerId)
      }
    }
    if (this.customScripts.length) {
      const checkFlockler = this.customScripts.filter(({ flocklerId }) => flocklerId)
      if (checkFlockler.length) {
        checkFlockler.map((i) => this.destroyEmbed(i.flocklerId))
      }
    }
    if (window.Tawk_API) window.Tawk_API.hideWidget()
  },
}
</script>
