import programApi from '../../api/program'

const personalProgram = {
  namespaced: true,
  state: {
    rawList: {},
    lists: {},
    fetchPromises: {},
    symposiaList: [],
    presentationList: [],
    speakerList: [],
    personalList: [],
  },
  mutations: {
    setList(state, payload) {
      state.rawList = payload.list
      state.lists = payload.list
    },
    setSymposiaFavorites(state, payload) {
      state.symposiaList = payload.symposiaList
    },
    setPresentationFavorites(state, payload) {
      state.presentationList = payload.presentationList
    },
    setSpeakerFavourites(state, payload) {
      state.speakerList = payload.speakerList
    },
    setFetchPromise(state, payload) {
      state.fetchPromises = payload.fetchPromise
    },
    resetState(state) {
      state.lists = {}
      state.fetchPromises = {}
      state.symposiaList = []
    },
  },
  actions: {
    async load({ commit, state }, payload) {
      const params = { ...payload }
      if (!params?.loadScheduleNew) {
        if (Object.prototype.hasOwnProperty.call(state.lists)) {
          return state.lists
        }
        if (Object.prototype.hasOwnProperty.call(state.fetchPromises)) {
          return state.fetchPromises
        }
      }

      const fetchPromise = programApi.getPersonalProgram().then(async (listResult) => {
        const list = listResult.Result ? listResult.Result : listResult
        commit('setList', { list })
        // set symposia id's
        let symposiaList = list?.Schedule ? list?.Schedule.map((item) => item.SessionId) : []
        if (symposiaList.length > 0) symposiaList = symposiaList.filter((item) => item !== 0)
        // set presentation id's
        let presentationList = list?.Schedule ? list?.Schedule.map((item) => item.PresentationId) : []
        if (presentationList.length > 0) presentationList = presentationList.filter((item) => item !== 0)
        // set speaker id's
        let speakerList = list?.Schedule ? list?.Schedule.map((item) => item.UserId) : []
        if (speakerList.length > 0) speakerList = speakerList.filter((item) => item !== 0)
        localStorage.setItem('favoriteSessions', JSON.stringify(symposiaList.map(String)))
        localStorage.setItem('favoritePresentations', JSON.stringify(presentationList.map(String)))
        localStorage.setItem('favoriteSpeakers', JSON.stringify(speakerList.map(String)))
        commit('setSymposiaFavorites', { symposiaList })
        commit('setPresentationFavorites', { presentationList })
        commit('setSpeakerFavourites', { speakerList })
      })
      commit('setFetchPromise', { fetchPromise })
      return true
    },

    reset({ commit }) {
      commit('resetState')
    },

    set({ commit, state }, payload) {
      const idx = state.lists.Schedule
        .findIndex((item) => item.SessionId === payload.session.SessionId)
      state.lists.Schedule.splice(idx, 1)
      state.lists.Schedule.push(payload.session)
      const list = state.lists
      commit('setList', { list })
    },

    symposiaList({ commit, state }, payload) {
      state.symposiaList.push(payload)
      const { symposiaList } = state
      commit('setSymposiaFavorites', { symposiaList })
    },

    presentationList({ commit, state }, payload) {
      state.presentationList.push(payload)
      const { presentationList } = state
      commit('setPresentationFavorites', { presentationList })
    },

    speakerList({ commit, state }, payload) {
      state.speakerList.push(payload)
      const { speakerList } = state
      commit('setSpeakerFavourites', { speakerList })
    },
  },
  getters: {
    list(state) {
      return state.lists
    },
    rawList(state) {
      return state.rawList
    },
    symposiaIdList(state) {
      return state.symposiaList
    },
    presentationIdList(state) {
      return state.presentationList
    },
    speakerIdList(state) {
      return state.speakerList
    },
  },
}

export default personalProgram
