<template>
  <social-media-container>
    <social-media-icon
      v-for="item in socialMediaItems"
      :key="item.icon"
    >
      <a
        :href="item.url"
        :target="item.target"
      >
        <i
          :class="'fab ' + item.icon"
        /></a>
    </social-media-icon>
  </social-media-container>
</template>

<script>
import headerMixins from '~/mixins/headerMixins'
import styled from '@u-rogel/vue-emotion'

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: none;
  }
`({
  breakpoint: Number,
})

const SocialMediaIcon = styled.span`
  i {
    background-color: var(--primary);
    color: var(--primary-contrast);
    font-size: 18px;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`()

export default {
  name: 'SocialMediaIcons',
  mixins: [headerMixins],
  data() {
    return {
      socialMediaItems: [],
    }
  },
  components: { SocialMediaContainer, SocialMediaIcon },
  computed: {
  },
  created() {
    const backendConfig = this.configForKey('app_config').config[0]
    const socialMediaConfig = backendConfig.defaults?.header?.menu?.socialMediaItems
    if (socialMediaConfig) {
      this.socialMediaItems = socialMediaConfig
    }
  },
}
</script>
