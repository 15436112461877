import exhibitionApi from '../../../api/exhibition'

const exhibitorSymposias = {
  namespaced: true,
  state: {
    symposiaList: [],
    loadingSymposiaList: 0,
  },
  mutations: {

    addSymposiaList(state, payload) {
      state.symposiaList[payload.id] = payload.symposiaList
    },
    startLoadingSymposiaList(state) {
      state.loadingSymposias++
    },
    stopLoadingSymposiaList(state) {
      state.loadingSymposias--
    },
  },
  actions: {
    async getSymposiaListForId({ commit, state }, { id, onlyPublic = true }) {
      if (!Object.prototype.hasOwnProperty.call(state.symposiaList, id)) {
        commit('startLoadingSymposiaList', id)

        const symposiaList = exhibitionApi.getCompanyAccountExhibitionSymposiaList(id, onlyPublic)
        commit('addSymposiaList', {
          id,
          symposiaList,
        })
        commit('stopLoadingSymposiaList', id)
      }
      return Object.prototype.hasOwnProperty.call(state.symposiaList, id) ? state.symposiaList[id] : []
    },
  },

  getters: {
    loadingSymposiaList(state) {
      return state.loadingSymposiaList
    },
  },
}
export default exhibitorSymposias
