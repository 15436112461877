import exhibitionApi from '../../api/exhibition'

function sortByNumber(a, b) {
  return a[0] - b[0]
}

const publicExhibitorList = {
  namespaced: true,
  state: {
    list: null,
    fetchListPromise: null,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    setFetchPromise(state, payload) {
      state.fetchListPromise = payload
    },
  },
  actions: {
    async load({
      commit,
      state,
    }) {
      if (state.list !== null) {
        return state.list
      }
      if (state.fetchPromise) {
        return state.fetchPromise
      }
      const fetchPromise = exhibitionApi.getPublicCompanyAccountsList().then((config) => {
        commit('setList', config)
      })
      commit('setFetchPromise', fetchPromise)
      return fetchPromise
    },
    async filterList({
      state,
      dispatch,
    }, payload) {
      await dispatch('load')
      const { filterBy, sorting } = payload
      const filterByKeys = Object.keys(filterBy)
      if (!filterByKeys.length) {
        return state.list
      }
      const filterByKey = filterByKeys[0]
      const filteredCompanyList = state.list.filter((item) => {
        if (filterBy[filterByKey].include) {
          const includeFilter = filterBy[filterByKey].include
          return includeFilter.includes(item[filterByKey])
        }
        const excludeFilter = filterBy[filterByKey].exclude
        return !excludeFilter.includes(item[filterByKey])
      })
      if (sorting === filterByKey) {
        const sortArray = filterBy[filterByKey].include
        const result = filteredCompanyList.map((item) => {
          const n = sortArray.indexOf(item[sorting])
          sortArray[n] = ''
          return [n, item]
        }).sort(sortByNumber).map((j) => j[1])

        return result
      }
      if (sorting === 'alphabetically') {
        filteredCompanyList.sort((a, b) => {
          const textA = a.CompanyAccountName.toUpperCase()
          const textB = b.CompanyAccountName.toUpperCase()
          // eslint-disable-next-line no-nested-ternary
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
        })
      }
      return filteredCompanyList
    },
  },
  getters: {
    list(state) {
      return state.list
    },
  },
}

export default publicExhibitorList
