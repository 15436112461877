import apiBase from './base'
import store from '../store'

async function getServerTime() {
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Core/ServerTime`

  const response = await apiBase.get(url)
  if (response?.data) {
    return response.data
  }
  return null
}

export default {
  getServerTime,
}
