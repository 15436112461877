const errorStore = {
  namespaced: true,
  state: {
    errors: [],
  },
  mutations: {
    addError(state, payload) {
      state.errors.push(payload)
    },
    clearErrors(state) {
      state.errors = []
    },
  },
  actions: {
    async addError({ commit }, error) {
      commit('addError', error)
    },
    async clearErrors({ commit }) {
      commit('clearErrors')
    },
  },
  getters: {
    errors(state) {
      return state.errors
    },
    firstError(state) {
      return state.errors.length > 0 ? state.errors[0] : null
    },
    lastError(state) {
      return state.errors.length > 0 ? state.errors[state.errors.length - 1] : null
    },
  },
}
export default errorStore
