import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      freshComponentKey: 1,
    }
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
    }),
    backendConfig() {
      const backendConfig = this.configForKey('app_config')?.config[0]
      if (backendConfig) return backendConfig
      return null
    },
    FreshChatAllPagesConfig() {
      return this.backendConfig.FreshChatAllPages
    },
  },
  components: {
    FreshChat: () => import('~/components/FreshChat.vue'),
  },
}
