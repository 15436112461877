<template v-if="networkingActive">
  <div
    ref="networkingSidebarWidget"
    id="networking-widget__sidebar"
    class="networking-widget__sidebar"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '~/utilities/utils'
import trackingApi from '~/api/tracking'
import EventBus from '~/states/event-bus'
import accessControl from '~/utilities/accessControl'

export default {
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  data() {
    return {
      fileCSSDev: `https://demo.conference2web.com/jme/ntw_ece/js/widget-side/ntwCss.css?${new Date().getTime()}`,
      fileJSDev: `https://demo.conference2web.com/jme/ntw_ece/js/widget-side/ntwMain.js?${new Date().getTime()}`,
      widgetInit: false,
    }
  },
  created() {
    if (this.networkingActive && this.checkJS()) {
      // eslint-disable-next-line max-len
      if (this.instance === 'staging' || this.instance === 'dev' || this.instance === 'qa' || window.location.origin.includes('vmx-dev')) {
        utils.loadScript(this.fileJSDev)
        utils.loadStylesheet(this.fileCSSDev)
        // eslint-disable-next-line max-len
        this.$loadScript('https://d3klq1qh6r64da.cloudfront.net/api_uploads/tav-chat-master/jme/jmeOwnChatMain.js?v=1').then(() => {}).catch(() => {
          console.log('failed ntw chat')
        })
        utils.loadStylesheet('https://d3klq1qh6r64da.cloudfront.net/api_uploads/tav-chat-master/jme/jmeOwnChatCss.css?v=1')
      } else {
        utils.loadScript(this.fileJS)
        utils.loadStylesheet(this.fileCSS)
        // eslint-disable-next-line max-len
        this.$loadScript('https://d3klq1qh6r64da.cloudfront.net/api_uploads/tav-chat-master/jme/jmeOwnChatMain.js?v=1').then(() => {}).catch(() => {
          console.log('failed ntw chat')
        })
        utils.loadStylesheet('https://d3klq1qh6r64da.cloudfront.net/api_uploads/tav-chat-master/jme/jmeOwnChatCss.css?v=1')
      }
    }
  },
  mounted() {
    if (this.networkingActive) {
      let time = 1
      const interval = setInterval(() => {
        if (!this.widgetInit && time <= 20) {
          this.loadNetworkingSidebar()
          time += 1
        } else {
          clearInterval(interval)
        }
      }, 4000)
      window.areaOfInterest = () => {
        if (window.jmeNetworking) {
          window.jmeNetworking.openAreaOfInterest({}, window.$('#areaOfInterest')[0])
        }
      }
      window.joinToGroup = (groupId) => {
        window.jmeNetworking.joinToGroup(groupId, window.$('#networkingJoinToGroup')[0])
      }
      window.openAd = () => {
        if (this.widgetInit) window.jmeNetworking.myAd.open()
      }
      window.disableAd = () => {
        if (this.widgetInit) window.jmeNetworking.myAd.off()
      }
      window.closeAd = () => {
        if (this.widgetInit) window.jmeNetworking.myAd.close()
      }
    }
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      instance: 'instancesState/getInstance',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      loungeRedirectDisabled: 'instancesState/getCompanyLoungeRedirectDisabled',
      isAppContainer: 'instancesState/getIsAppContainer',
    }),
    appConfigV2() {
      const appConfig = this.configForKey('app_config')
      if (appConfig.configV2) {
        return appConfig.configV2
      }
      return null
    },
    networkingActive() {
      const [config] = this.configForKey('app_config').config
      const access = accessControl.accessControlKey('networkingWidget')
      if (this.isAppContainer && 'networkingWidgetForApp' in config) return config.networkingWidgetForApp
      if (config.networkingWidget && access) return true
      return false
    },
    appConfig() {
      const appConfig = this.configForKey('app_config')
      if (appConfig) {
        return appConfig.config[0]
      }
      return null
    },
    virtualMeetingUrl() {
      return this.appConfig ? this.appConfig.ExternalVirtualMeetingUrl : null
    },
    industryOnly() {
      return this.appConfig ? this.appConfig.IndustryOnly : null
    },
    fileJS() {
      return (this.appConfig && this.appConfig.networkingWidgetJS)
        ? `${this.appConfig.networkingWidgetJS}?${new Date().getTime()}`
        : `https://mevents-api-uploads.s3.amazonaws.com/api_uploads/ntw/v2/ntwMain.js?${new Date().getTime()}`
    },
    fileCSS() {
      return (this.appConfig && this.appConfig.networkingWidgetCSS)
        ? `${this.appConfig.networkingWidgetCSS}?${new Date().getTime()}`
        : `https://mevents-api-uploads.s3.amazonaws.com/api_uploads/ntw/v2/ntwCss.css?${new Date().getTime()}`
    },
  },
  methods: {
    customActionTrack(customParams) {
      if (utils.advancedTracking()) {
        const page = this.$route.name
        this.TrackingUtil.trackEvent(
          page,
          customParams.Action,
          utils.advancedTrackingFormat(
            this.user.UserId,
            'Event',
            customParams.ActionContext,
            customParams.Entity,
          ),
        )
      } else {
        this.TrackingUtil.trackEvent(
          customParams.ActionContext,
          customParams.Action,
          `id: ${customParams.Entity})`,
        )
      }

      trackingApi.trackCustomAction(
        customParams.Action,
        customParams.Entity,
        customParams.ActionContext,
      )
    },
    popupCloseAction() {
      // eslint-disable-next-line max-len
      if (this.loungeRedirectDisabled && !this.industryOnly) window.location.href = `${window.location.origin}/home/${this.instance}/${this.event}/${this.language}?companyLoungeRedirectDisabled=false`
    },
    networkingRoute(redirectStr) {
      const data = redirectStr?.split('/')
      if (redirectStr.startsWith('http')) {
        window.open(redirectStr, '_self')
      // } else if (data[0] === 'program') {
        // eslint-disable-next-line max-len
      //   this.$router.push(utils.getRouteWithDefaultParams('ProgramPage', {}, { filter: 'myprogramme' })).catch((err) => {})
      } else {
        this.$router.push(utils.getRouteWithDefaultParams('MyAreaPage', { module: data[0], id: data[1] })).catch(() => {})
        utils.showHome()
      }
    },
    loadNetworkingSidebar() {
      const userId = parseInt(this.$cookies.get('user_id'), 10)
      const accessToken = this.$cookies.get('access_token')
      const eventId = parseInt(this.$cookies.get('event_id'), 10)
      const widgetSettings = {
        user: {
          id: userId,
          token: accessToken,
        },
        useManageIds: true,
        settingsJson: {
          event_id: eventId,
        },
        pageURLS: {
          chat_groups: 'chatgroup/',
          user_groups: 'groups',
          inbox_page: 'inbox',
          user_program: 'program',
          user_network: 'network',
          user_page: this.appConfigV2?.defaultV2?.routeV2
            ? `${window.location.origin}/${this.instance}/${this.event}/${this.language}/faculty/`
            : `${window.location.origin}/program/${this.instance}/${this.event}/${this.language}?user=`,
          resource_page: this.appConfigV2?.defaultV2?.routeV2
            ? `${window.location.origin}/${this.instance}/${this.event}/${this.language}/presentation/`
            : `${window.location.origin}/program/${this.instance}/${this.event}/${this.language}?presentation=`,
          contentsession_page: this.appConfigV2?.defaultV2?.routeV2
            ? `${window.location.origin}/${this.instance}/${this.event}/${this.language}/session/`
            : `${window.location.origin}/program/${this.instance}/${this.event}/${this.language}?session=`,
          mtv_page: `${window.location.origin}/live/${this.instance}/${this.event}/${this.language}?roomid=`,
          mtv_page_session: `${window.location.origin}/live/${this.instance}/${this.event}/${this.language}?roomid=`,
        },
        pageRoutsFunc: this.networkingRoute,
        pageTrackingFn: this.customActionTrack,
        popupCloseFunc: this.popupCloseAction,
        linkTrackingFn: window.tracking,
      }

      if (this.appConfig.websocket_server_address) {
        widgetSettings.settingsJson.websocket_server_address = this.appConfig.websocket_server_address
      }

      if (this.appConfig.api_base_addr) {
        widgetSettings.settingsJson.api_base_addr = this.appConfig.api_base_addr
      }

      if (this.virtualMeetingUrl && this.industryOnly) {
        widgetSettings.pageURLS = {
          user_groups: `${this.virtualMeetingUrl}/#home/groups`,
          inbox_page: `${this.virtualMeetingUrl}/#home/messages`,
          user_program: `${this.virtualMeetingUrl}/#home/program`,
        }
      }
      if (userId && accessToken) {
        if (window.jmeNetworking) {
          window.jmeNetworking.sidebarWidget(widgetSettings, this.$refs.networkingSidebarWidget)
          this.widgetInit = true
          if (this.appConfig.networkingWidgetPadding === false) {
            this.$emit('networkingPaddingOff')
          }
          this.$emit('networkingActive')
          EventBus.$emit('networkingActive', this.networkingActive)
        }
      }
      const element = document.getElementsByClassName('hasSplashscreen')
      if (element.length || (this.$route.name !== 'IndustryLandingPage' && this.$route.name !== 'HomePage')) {
        window.disableAd()
      } else if (this.$route.name === 'IndustryLandingPage' || this.$route.name === 'HomePage') {
        window.openAd()
      }
    },
    checkJS() {
      return !document.querySelectorAll(`script[src='${this.fileJS}']`).length > 0
    },
  },
}

</script>

<style lang="scss">
  .networking-widget__sidebar {
    position: fixed;
    right: 0;
    z-index: 11 !important;
    //height: 100vh;
    .ntw-myad {
      visibility: hidden;
    }
  }
  .IndustryLandingPage .networking-widget__sidebar .ntw-myad, .HomePage .networking-widget__sidebar .ntw-myad {
    visibility: visible;
  }
</style>
