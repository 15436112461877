import sso from '../../api/sso'

const singleSignOn = {
  namespaced: true,
  state: {
    user: '',
    ssoLinks: [], // sso configuration and sso login links
    ssoEvaluatedUser: false, // Res from outside login link // why false if it should be an object
    token: false, // Login Token
    redirectUrl: false, // Redirect URL from backend,
    transfer: false,
    areTermsAccepted: false,
    ssoError: false,
    isUserComparisonNeeded: null
  },
  mutations: {
    setConfLinks(state, payload) {
      state.ssoLinks = payload
    },
    setEvaluatedUser(state, payload) {
      state.ssoEvaluatedUser = payload
    },
    setUserToken(state, payload) {
      state.token = payload
    },
    setRedirectUrl(state, payload) {
      state.redirectUrl = payload
    },
    setTransferData(state, payload) {
      state.transfer = payload
    },
    setTermsAndConditions(state, payload) {
      state.areTermsAccepted = payload
    },
    setSsoError(state, payload) {
      state.ssoError = payload
    },
    setUserDataComparisonNeeded(state, payload) {
      state.isUserComparisonNeeded = payload
    }
  },
  actions: {
    async ssoLoadConfiguration({ commit }) {
      await sso.configuration().then((res) => {
        res.status === 200 ? commit('setConfLinks', res.data) : commit('setConfLinks', false)
      })
    },

    async ssoEvaluateCurrentUser({ commit }, payload) {
      return await sso.correlate(payload).then((res) => {
        if (res.status == 200 && res.data) {
          commit('setEvaluatedUser', res.data)
          commit('setTermsAndConditions', res.data.areTermsAccepted)
          commit('setTransferData', res.data?.transfer || null)
          commit('setUserDataComparisonNeeded', res.data.userDataComparisonNeeded)

          if (res.data.redirect) {
            commit('setUserToken', res.data.redirect.token)
            commit('setRedirectUrl', res.data.redirect.redirectUrl)
          }

          if (res.data.statusAction == 'ExternalUserReadyToLogin' && res.data.areTermsAccepted) {
            return 'userSync'
          }

          //if (res.data.statusAction == 'ExternalUserReadyToLogin') {
          // if (res.data.areTermsAccepted) {
          //   let redirectUrl = res.data.redirect.redirectUrl
          //   if (payload && 'returnUrl' in payload && payload.returnUrl) {
          //     redirectUrl = `${redirectUrl}&returnUrl=${encodeURIComponent(`${window.location.origin}${payload.returnUrl}`)}`
          //   }

          //   return window.location.href = redirectUrl
          // }
          // if (res.data.statusAction == 'ExternalUserWithIdentityAndNoSSOLink') {
          //   // Pending
          //   //commit('setTermsAndConditions', res.data.areTermsAccepted)
          // }
        }
      })
    },

    async ssoRegisterExternalAccount({ commit, state }) {
      return await sso.registerExternalAccount().then((res) => {
        if (res.status == 200) {
          if (res.data.redirectUrl) commit('setRedirectUrl', res.data.redirectUrl)
          if (res.data.token) commit('setUserToken', res.data.token)
        }
        return res
      }).catch((res) => {
        return res
      })
    },

    async ssoAcceptTermsAndConditions({ state }) {
      await sso.acceptTermsAndConditions().then((res) => {
        if (res.status == 200) {
          return res
        }

        return null
      }).catch((err) => err)
    },

    async ssoUserConfirmPassword({ commit }, payload) {
      const { password, returnUrl } = payload
      return await sso.userPasswordConfirm(password, returnUrl).then((res) => {
        if (res && res.status === 200) {
          //return window.location.href = res.data.redirectUrl
          if (res.data.redirectUrl) commit('setRedirectUrl', res.data.redirectUrl)
          if (res.data.token) commit('setUserToken', res.data.token)
        }
        if (res == undefined || res.status == 401) {
          commit('setSsoError', 'Something went wrong. Please check your password and try again.')
        }

        return res
      }).catch((err) => {
        console.log('This error' + err)
      })
    },

    async ssoExternalRegisterAccount({ commit }, payload) {
      const data = payload
      return await sso.externalRegisterAccount(data).then((res) => {
        if (res.status == 200) {
          if (res.data.redirectUrl) commit('setRedirectUrl', res.data.redirectUrl)
          if (res.data.token) commit('setUserToken', res.data.token)
        }

        return res
      })
    },

    async loadExternalUserCompareData() {
      return await sso.loadExternalUserCompareData().then((res) => {
        return res
      })
    },

    async ssoSyncExternalUser({ commit }, doSync) {
      return await sso.syncExternalUser(doSync).then((res) => {
        return res.status
      })
    },

    async ssoSetTokenData({ commit }, data) {
      commit('setUserToken', data.token)
      commit('setRedirectUrl', data.redirectUrl)
    }
  },
  getters: {
    ssoLinks: state => {
      return state.ssoLinks
    },
    ssoUser: state => {
      return state.ssoEvaluatedUser.user
    },
    ssoEvaluatedUserStatusAction: state => {
      return state.ssoEvaluatedUser.statusAction
    },
    ssoAreTermsAccepted: state => {
      //return state.ssoEvaluatedUser.areTermsAccepted
      return state.areTermsAccepted
    },
    ssoUserToken: state => {
      return state.token
    },
    ssoRedirectUrl: state => {
      return state.redirectUrl
    },
    ssoTransferUser: state => {
      return state.transfer
    },
    ssoError: state => {
      return state.ssoError
    },
    ssoUserDataComparisonNeeded: state => {
      return state.isUserComparisonNeeded
    }
  },
}

export default singleSignOn