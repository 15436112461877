import apiBase from './base'
import store from '../store'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getIndustryModel(eventName, language) {
  // industry model
  let manageConfigUrl = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/IndustryLandingModel?shortname=${eventName}&language=${language}`
  if (useApiMockData) {
    manageConfigUrl = '/api-mock/industry-landing-model.json'
  }
  const manageResponse = await apiBase.get(manageConfigUrl)
  if (manageResponse && manageResponse.status === 200) {
    return manageResponse.data
  }
  return null
}

export default {
  getIndustryModel,
}
