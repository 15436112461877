import { mapGetters } from 'vuex'

const instanceStateMixins = {

  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      instance: 'instancesState/getInstance',
      event: 'instancesState/getEvent',
      eventId: 'instancesState/getEventId',
      language: 'instancesState/getLanguage',
      boothGuru: 'instancesState/getBoothGuru',
      instanceLinkPage: 'instancesState/getLinkPage',
      tnc: 'TermsAndConditions/tnc',
      data: 'TermsAndConditions/data',
      firstError: 'Error/firstError',
      lastError: 'Error/lastError',
      errors: 'Error/errors',
      superUserToggle: 'instancesState/getSuperUserToggle',
      superUser: 'instancesState/getSuperUser',
      preview: 'instancesState/getPreview',
      themeStyles: 'instancesState/getThemesStyles',
      history: 'instancesState/getHistory',
      identityUrl: 'instancesState/getIdentityUrl',
      apiUrl: 'instancesState/getApiBaseUrl',
      disableHeader: 'instancesState/getDisableHeader',
    }),
    boothGuruActive() {
      return this.boothGuru
    },
    config() {
      return this.configForKey('industry_config').config
    },
  },

}

export default instanceStateMixins
