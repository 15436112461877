import apiBase from './base'
import store from '../store'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getCompanyAccountsList() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  if (useApiMockData) {
    url = '/api-mock/exhibitors-list.json'
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/CompanyAccounts?eventid=${eventId}&language=${locale}`
  }
  const response = await apiBase.get(url)
  const list = response.data
  for (let i = 0, len = list.length; i < len; i += 1) { // TODO: check if for is really needed
    if (!Object.prototype.hasOwnProperty.call(list[i], 'Tags')) {
      list[i].Tags = []
    }
  }
  return list
}

async function getCompanyAccountsListV2() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  if (useApiMockData) {
    url = '/api-mock/exhibitors-list.json'
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/CompanyAccountsV2?eventid=${eventId}&language=${locale}`
  }
  const response = await apiBase.get(url)
  const list = response.data
  for (let i = 0, len = list.length; i < len; i += 1) { // TODO: check if for is really needed
    if (!Object.prototype.hasOwnProperty.call(list[i], 'Tags')) {
      list[i].Tags = []
    }
  }
  return list
}

async function getCompanyAccountProfile(id) {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  let companyAccount = null
  if (useApiMockData) {
    url = '/api-mock/exhibitors-profile.json'
    const response = await apiBase.get(url)
    const profiles = response.data
    if (Object.prototype.hasOwnProperty.call(profiles, id)) {
      companyAccount = profiles[id]
    }
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/CompanyAccount/${eventId}/${locale}/${id}`
    const response = await apiBase.get(url)
    companyAccount = response?.data
    if (!Object.prototype.hasOwnProperty.call(companyAccount, 'Tags')) {
      companyAccount.Tags = []
    }
  }
  return companyAccount
}

/** Chatmodel containing the returnId (to restore a chat) and user information to use in the freshChat widget. */
async function getChatModel(companyAccountId) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/getChatModel?eventid=${eventId}&id=${companyAccountId}`
  const response = await apiBase.get(url)
  if (response && response.status === 200) return response.data
  return null
}

/** Save the freshchat restoreId for a user. */
async function saveChatModel(companyAccountId, restoreId) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/saveChatModel?eventid=${eventId}&id=${companyAccountId}&restoreId=${restoreId}`
  const response = await apiBase.post(url)
  return response.data
}
/** get vmx booth data */
async function getVmxBoothData(companyAccountId) {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/CompanyAccountVMXBooth/${eventId}/${locale}?id=${companyAccountId}`
  const response = await apiBase.get(url)
  return response.data
}
/** get pdfviewer pdf data */
async function getProductPdfUrl(pdfviewerurl) {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/GetDistributionFile?distributionUrl=${pdfviewerurl}`
  const response = await apiBase.get(url)
  return response.data
}
async function getCompanyAccountExhibitionProducts(id) {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  let exhibitorProducts = []
  if (useApiMockData) {
    url = '/api-mock/exhibitor-products.json'
    const response = await apiBase.get(url)
    const exhibitorProductsByCAId = response.data
    if (Object.prototype.hasOwnProperty.call(exhibitorProductsByCAId, id)) {
      exhibitorProducts = exhibitorProductsByCAId[id]
    }
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/ExhibitiorProducts/${eventId}/${locale}/${id}`
    const response = await apiBase.get(url)
    exhibitorProducts = response.data
  }
  return exhibitorProducts
}

async function getCompanyAccountExhibitionMeetings(id) {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  let exhibitorMeetings = []
  if (useApiMockData) {
    url = '/api-mock/exhibitor-meetings.json'
    const response = await apiBase.get(url)
    const exhibitorMeetingsByCAId = response.data
    if (Object.prototype.hasOwnProperty.call(exhibitorMeetingsByCAId, id)) {
      exhibitorMeetings = exhibitorMeetingsByCAId[id]
    }
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/ExhibitiorMeetings/${eventId}/${locale}/${id}`
    const response = await apiBase.get(url)
    exhibitorMeetings = response.data
  }
  return exhibitorMeetings
}

async function getExhibitionMeetingsForEvent() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/ExhibitiorMeetingsForEvent/${eventId}/${locale}`

  if (useApiMockData) {
    url = '/api-mock/exhibitor-meetings-array.json'
  }
  const response = await apiBase.get(url)
  return response.data
}

async function getPublicCompanyAccountsList() {
  const eventId = store.getters['instancesState/getEventId']
  const language = store.getters['instancesState/getLanguage']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Public/CompanyAccounts?eventid=${eventId}&language=${language}`
  if (useApiMockData) {
    // TODO
  }
  const response = await apiBase.get(url)
  return response.data
}

async function getCompanyAccountExhibitionSymposiaList(id, onlyPublic = true) {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  let exhibitorSymposia = []
  if (useApiMockData) {
    url = '/api-mock/exhibitor-symposias.json'
    const response = await apiBase.get(url)
    const exhibitorSymposiaByCAId = response.data
    if (Object.prototype.hasOwnProperty.call(exhibitorSymposiaByCAId, id)) {
      exhibitorSymposia = exhibitorSymposiaByCAId[id]
    }
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/ExhibitiorSymposia/${eventId}/${locale}/${id}?onlypublic=${onlyPublic}`
    const response = await apiBase.get(url)
    exhibitorSymposia = response.data
  }
  return exhibitorSymposia
}

async function getExhibitorWebcasts(id) {
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/ExhibitiorWebcasts`
  const response = await apiBase.get(url, {
    params: {
      eventid: store.getters['instancesState/getEventId'],
      language: store.getters['instancesState/getLanguage'],
      id,
    },
  })
  const webcasts = response.data
  return webcasts
}

async function getExhibitorPresentations(id) {
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/ExhibitiorPresentations`
  const response = await apiBase.get(url, {
    params: {
      eventid: store.getters['instancesState/getEventId'],
      language: store.getters['instancesState/getLanguage'],
      id,
    },
  })
  const presentations = response.data
  return presentations
}

async function getExhibitionSymposiaList() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  if (useApiMockData) {
    url = '/api-mock/symposia-list.json'
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/Symposia/${eventId}/${locale}`
  }
  const response = await apiBase.get(url)
  const list = response.data
  for (let i = 0, len = list.length; i < len; i += 1) { // TODO: check if for is really needed
    if (!Object.prototype.hasOwnProperty.call(list[i], 'Tags')) {
      list[i].Tags = []
    }
  }
  return list
}

// todo: rating endpoint
async function getAllExhibitorProducts() {
  const eventId = store.getters['instancesState/getEventId']
  const locale = store.getters['instancesState/getLanguage']
  let url = ''
  let exhibitorProducts = []
  if (useApiMockData) {
    url = '/api-mock/products-list.json'
    const response = await apiBase.get(url)
    const exhibitorProductsAll = response.data
    if (exhibitorProductsAll) {
      exhibitorProducts = exhibitorProductsAll
    }
  } else {
    url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/ExhibitiorProductsForEvent/${eventId}/${locale}`
    const response = await apiBase.get(url)

    exhibitorProducts = response.data
  }
  return exhibitorProducts
}

async function createRatingForProduct(id, rating) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/exhibition/CreateProductRating?eventid=${eventId}&pExhibitorProductId=${id}&pRatingValue=${rating}`

  const response = await apiBase.post(url)
  return response.data
}
/** get vmx exh. lounge data */
async function getExhibitorLoungeData(companyAccountId) {
  const eventId = store.getters['instancesState/getEventId']
  // eslint-disable-next-line max-len
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Exhibition/ExhibitorLounge/${eventId}/de-DE?id=${companyAccountId}`

  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}

export default {
  getCompanyAccountsList,
  getCompanyAccountsListV2,
  getCompanyAccountProfile,
  getCompanyAccountExhibitionProducts,
  getAllExhibitorProducts,
  getCompanyAccountExhibitionMeetings,
  getExhibitionMeetingsForEvent,
  getCompanyAccountExhibitionSymposiaList,
  getExhibitionSymposiaList,
  createRatingForProduct,
  getPublicCompanyAccountsList,
  getExhibitorPresentations,
  getChatModel,
  saveChatModel,
  getVmxBoothData,
  getProductPdfUrl,
  getExhibitorLoungeData,
  getExhibitorWebcasts,
}
