import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

import ILPExhibitorList from './modules/industryLandingPage/exhibitorList'
import ILPExhibitorProfile from './modules/industryLandingPage/exhibitorProfile'
import ILPExhibitorSymposias from './modules/industryLandingPage/exhibitorSymposias'
import ILPExhibitorProducts from './modules/industryLandingPage/exhibitorProducts'
import ILPExhibitorMeetings from './modules/industryLandingPage/exhibitorMeetings'
import ILPExhibitorProductsAll from './modules/industryLandingPage/exhibitorProductsAll'
import ILPSymposiaList from './modules/industryLandingPage/symposiaList'
import ILPIndustryModel from './modules/industryLandingPage/industryModel'

import charity from './modules/charity'
import header from './modules/header'
import meetings from './modules/meetings'
import config from './modules/config'
import userTicket from './modules/userTicket'
import program from './modules/program'
import visibilityConfig from './modules/componentVisibility'
import singleSignOn from './modules/singleSignOn'


import user from './modules/user'
import auth from './modules/auth'
import AuthV2 from './modules/authV2'
import vmUser from './modules/vmUser'
import contactForm from './modules/contactForm'
import publicExhibitorList from './modules/publicExhibitorList'
import highlightedSessions from './modules/highlightedSessions'
import sessionsForEvaluation from '~/store/modules/sessionsForEvaluation'
import instancesState from './modules/instancesState'
import personalProgram from './modules/personalProgram'
import UserDocuments from './modules/userDocuments/UserDocuments'
import TermsAndConditions from './modules/termsAndConditions'
import advertisements from './modules/advertisements'
import pushnotifications from './modules/pushnotifications'
import eventDashboard from './modules/eventDashboard'
import serverTime from './modules/serverTime'


import ModuleConfig from './modules/userDocuments/ModuleConfig'

import Error from './modules/error'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['personalProgram'],
  })],
  modules: {
    singleSignOn,
    config,
    userTicket,
    user,
    auth,
    contactForm,
    charity,
    vmUser,
    header,
    meetings,
    ILPIndustryModel,
    ILPExhibitorList,
    ILPExhibitorProfile,
    ILPExhibitorSymposias,
    ILPSymposiaList,
    ILPExhibitorProducts,
    ILPExhibitorMeetings,
    ILPExhibitorProductsAll,
    publicExhibitorList,
    highlightedSessions,
    Error,
    sessionsForEvaluation,
    instancesState,
    personalProgram,
    UserDocuments,
    ModuleConfig,
    TermsAndConditions,
    advertisements,
    pushnotifications,
    program,
    AuthV2,
    visibilityConfig,
    serverTime,
    eventDashboard,
  },
  actions: {
    clearAll({ dispatch }) {
      dispatch('personalProgram/reset')
      dispatch('userTicket/reset')
      dispatch('user/reset')
    },
  },
})
export default store
