<template>
  <div class="vmx-footer-container">
    <div class="component-grid__flex-layout">
      <component-grid
        v-for="(grid, idx) in grids"
        :key="idx"
        v-bind="gridProps(grid)"
      >
        <component-grid-item
         :hidden="'draft' in grid ? grid.draft : false"
          v-for="(comp, cIdx) in grid.components"
          :key="cIdx"
          :size="comp.size"
          :grid-areas="comp.gridAreas"
          :draft="comp.draft"
          :in-app-appearance-active="comp.inAppAppearanceActive"
        >
          <component :is="comp.name" v-bind="comp.props" />
        </component-grid-item>
      </component-grid>
    </div>
  </div>
</template>
<script>
import { replaceCssVar } from "m-anage.com.vmx-components/src/utilities/theme";

import componentPageMixins from "~/mixins/componentPageMixins";
import SocialMediaFooter from "./socialMediaFooter.vue";
import FooterBox from "./footerBox.vue";

export default {
  data() {
    return {
      grids: null,
    };
  },
  mixins: [componentPageMixins],
  components: {
    SocialMediaFooter,
    FooterBox,
  },
  async created() {
    await this.loadConfig();
    let footerConfig = this.configForKey("footer_config");
    if (footerConfig?.grids && footerConfig?.grids.length > 0) {
      this.grids = footerConfig.grids;
    } else {
      footerConfig = this.configForKey("industry_config");
      if (footerConfig?.config?.Footer?.footerGrids) {
        this.grids = footerConfig.config.Footer.footerGrids;
      } else {
        this.grids = [];
      }
    }
  },
};
</script>

<style lang="scss">
.vmx-footer-container {
  background-color: var(--footer-background-color);
  div,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span {
    color: var(--primary-contrast);
  }
  hr {
    background-color: var(--primary-contrast);
  }
}
</style>
