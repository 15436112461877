import userDocumentApi from '~/api/userDocuments'

const userDocuments = {
  namespaced: true,
  state: {
    userDocuments: null,
    fetchUserDocumentPromise: null,
  },
  mutations: {
    setUserDocuments(state, payload) {
      state.userDocuments = payload
    },
    setFetchPromise(state, payload) {
      state.fetchUserDocumentPromise = payload
    },
  },
  actions: {
    async loadUserDocuments({ commit, state }) {
      if (state.userDocuments !== null) {
        return state.userDocuments
      }
      if (state.fetchUserDocumentPromise) {
        return state.fetchUserDocumentPromise
      }

      const fetchUserDocumentPromise = userDocumentApi.getUserDocuments().then((u) => {
        commit('setUserDocuments', u)
      })

      commit('setFetchPromise', fetchUserDocumentPromise)
      return fetchUserDocumentPromise
    },
    async search({ state, dispatch }, payload) {
      if (state.userDocuments == null) {
        await dispatch('loadUserDocuments')
      }
      let result = state.userDocuments
      if (payload && payload.events) {
        if (payload.events.length) {
          result = result.filter((data) => payload.events.map((e) => e.toLowerCase()).includes(data.EventShort.toLowerCase())
              || payload.events.map((e) => e.toLowerCase()).includes(data.SocietyShort.toLowerCase()))
        }
      }

      if (payload && payload.documentTypes) {
        if (payload.documentTypes.length) {
          result = result.filter((data) => payload.documentTypes.includes(data.FileType))
        }
      }

      return result
    },
  },
  getters: {
    userDocuments(state) {
      return state.userDocuments
    },
  },
}

export default userDocuments
