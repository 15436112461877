<script>
import styled from '@u-rogel/vue-emotion'

export default styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 1025px) and (max-width:1100px) {
    gap: 4vw;
  } 
  @media (min-width: 1101px) and (max-width:1280px) {
    gap: 8vw;
  }
  @media (min-width: 1280px) {
    gap: min(10vw, 200px);
  }
`()
</script>
