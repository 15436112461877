import uniFetch from '../../api/uniFetch'

const initialState = {
  config: {
    root: {},
    charity: {},
  },
  projects: [],
  challenges: [],
  highscores: {
    Projects: [],
    Challenges: [],
    Companies: [],
    Participants: [],
    selectedProject: {
      id: null,
      scores: {
        Projects: [],
        Companies: [],
        Participants: [],
      },
    },
  },
  exhibitorChallenges: {
    companyId: '',
    challenges: [],
    selectedId: '',
    survey: null,
  },
}

const getters = {
  projectById: (state) => (id) => {
    const project = state.projects.find((item) => item.Id === id)
    if (project) {
      const parsedProject = { ...project }
      parsedProject.banners = parsedProject.BannerFiles.map((item) => ({
        imageUrl: item.Key,
      }))
      return parsedProject
    }
    return project
  },
  selectedChallenge: (state) => {
    const { selectedId, challenges } = state.exhibitorChallenges
    const selectedCh = challenges.find((item) => item.Id === selectedId)
    return selectedCh
  },
  highscore: (state) => (key, nested) => {
    const selectedScores = nested ? state.highscores[key].scores[nested] : state.highscores[key]
    if (!selectedScores) {
      return null
    }
    const sortedScores = [...selectedScores]
    sortedScores.sort((a, b) => {
      if (a.Points > b.Points) {
        return -1
      }
      if (a.Points < b.Points) {
        return 1
      }
      return 0
    })
    return nested ? { scores: sortedScores, id: state.highscores[key].id } : sortedScores
  },
  pageConfig: (state) => (key) => state.config[key],
}

const actions = {
  fetchConfig({ commit }) {
    uniFetch`${(p) => p.apiBase}/api/Modules/GetConfigResource`({
      query: {
        eventId: (p) => p.eventId,
        resourcename: 'charity-page',
      },
    })
      .then((res) => {
        console.log('Charity config', res)
        const info = res[0]
        commit('setState', {
          name: 'config',
          data: JSON.parse(info.resourcevalue),
        })
      })
      .catch((err) => {
        console.log('got fetch error:', err)
      })
  },
  fetchProjects({ commit, dispatch, state }) {
    if (!state.challenges.length) {
      uniFetch`${(p) => p.apiBase}/api/Exhibition/EventProjects/${(p) => p.eventId}/${(p) => p.language}`()
        .then((res) => {
          commit('setState', {
            name: 'projects',
            data: res,
          })
          dispatch('fetchProjectHighscores', res[0].Id)
        })
    }
  },
  fetchChallenges({ commit, state }) {
    if (!state.challenges.length) {
      uniFetch`${(p) => p.apiBase}/api/Exhibition/EventChallenges/${(p) => p.eventId}/${(p) => p.language}`()
        .then((res) => {
          commit('setState', {
            name: 'challenges',
            data: res,
          })
        })
    }
  },
  fetchHighscores({ commit, state }) {
    uniFetch`${(p) => p.apiBase}/api/Exhibition/CharityHighscore/${(p) => p.eventId}/${(p) => p.language}`()
      .then((res) => {
        commit('setState', {
          name: 'highscores',
          data: {
            ...state.highscores,
            ...res,
          },
        })
      })
  },
  fetchProjectHighscores({ commit, state }, projectId) {
    uniFetch`${(p) => p.apiBase}/api/Exhibition/CharityHighscoreForProject/${(p) => p.eventId}/${(p) => p.language}`({
      query: {
        projectId,
      },
    })
      .then((res) => {
        commit('setState', {
          name: 'highscores',
          data: {
            ...state.highscores,
            selectedProject: {
              id: projectId,
              scores: {
                ...res,
              },
            },
          },
        })
      })
  },
  fetchExhibitorChallenges({ commit }, { companyId, selectedId }) {
    uniFetch`${(p) => p.apiBase}/api/Exhibition/ExhibitorChallenges/${(p) => p.eventId}/${(p) => p.language}`({
      query: {
        id: companyId,
      },
    }).then((res) => {
      commit('setState', {
        name: 'exhibitorChallenges',
        data: {
          ...selectedId ? { selectedId } : {},
          companyId,
          challenges: res,
          survey: null,
        },
      })
    })
  },
  setSelectedChallenge({ commit, state }, selectedId) {
    const { selectedId: formerSelectedId, ...rest } = state.exhibitorChallenges
    if (selectedId === formerSelectedId) {
      return
    }
    commit('setState', {
      name: 'exhibitorChallenges',
      data: {
        ...rest,
        selectedId,
        survey: null,
      },
    })
    if (selectedId) {
      const { challenges } = state.exhibitorChallenges
      const selectedCh = challenges.find((ch) => ch.Id === selectedId)
      uniFetch`${(p) => p.surveyApiBase}/Survey/Surveyjs`({
        method: 'post',
        data: selectedCh.SurveyRequest,
      }).then((getSurveyRes) => {
        commit('setState', {
          name: 'exhibitorChallenges',
          data: {
            ...state.exhibitorChallenges,
            survey: getSurveyRes,
          },
        })
      })
    }
  },
  submitSurveyPoints({ commit, state }, { projectId, points }) {
    uniFetch`${(p) => p.apiBase}/api/Survey/ChallengeEvaluationDone/${(p) => p.eventId}/${(p) => p.language}`({
      method: 'post',
      query: {
        userid: (p) => p.userId,
        challengeid: state.exhibitorChallenges.selectedId,
        charityProjectId: projectId,
        correctedAnswers: points,
      },
    }).then((res) => {
      const { selectedId, challenges } = state.exhibitorChallenges
      const selectedChIdx = challenges.findIndex((ch) => ch.Id === selectedId)
      const selectedCh = challenges[selectedChIdx]
      const finishedCh = {
        ...selectedCh,
        SurveyRequest: {
          ...selectedCh.SurveyRequest,
          EvaluationDone: true,
        },
      }
      challenges[selectedChIdx] = finishedCh
      commit('setState', {
        name: 'exhibitorChallenges',
        data: {
          ...state.exhibitorChallenges,
          challenges,
          selectedId,
          survey: null,
        },
      })
    })
  },
}

const mutations = {
  setState: (state, { name, data }) => {
    state[name] = data
  },
}

export default {
  state: initialState,
  actions,
  getters,
  mutations,
  namespaced: true,
}
