import 'core-js/stable'
import 'regenerator-runtime/runtime'
//import './registerServiceWorker'

import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import CKEditor from 'ckeditor4-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import {
  faFacebookSquare,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
  faFacebookF,
  faTwitterSquare,
  faFlickr,
  fab,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Hammer from 'hammerjs'
// import LoadScript from 'vue-plugin-load-script'
import VueLoadScript from 'vue-load-script-plus'
import VueLazyLoad from 'vue-lazyload'
import store from './store'
import InfiniteLoading from './components/vue-infinite-loading'
import NotificationHub from './states/notification'

// mEvents player things

import i18n from './i18n'
import router from './router'
import App from './App.vue'

window.Hammer = Hammer

function jqueryHammer($, Hammer) {
  function hammerify(el, options) {
    const $el = $(el)
    if (!$el.data('hammer')) {
      $el.data('hammer', new Hammer($el[0], options))
    }
  }

  $.fn.hammer = function (options) {
    return this.each(function () {
      hammerify(this, options)
    })
  }

  // extend the emit method to also trigger jQuery events
  Hammer.Manager.prototype.emit = (function (originalEmit) {
    return function (type, data) {
      originalEmit.call(this, type, data)
      $(this.element).trigger({
        type,
        gesture: data,
      })
    }
  }(Hammer.Manager.prototype.emit))
}

jqueryHammer(window.$, window.Hammer)
if (window) {
  window.onerror = function (message, source, lineno, colno, error) {
    console.error(error)
  }
}

Vue.config.errorHandler = function (err) {
  console.error(err)
}

Vue.use(CKEditor)
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(IconsPlugin)
Vue.use(InfiniteLoading, {})
Vue.use(VueCookies)
Vue.use(NotificationHub)
// Vue.use(LoadScript)
Vue.use(VueLoadScript)
Vue.use(VueLazyLoad)

library.add(fas, far, fab, faFacebookF, faFacebookSquare, faTwitter, faInstagram, faLinkedin, faYoutube, faTwitterSquare, faFlickr, faXTwitter)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n: i18n.i18n,
  render: (h) => h(App),
  data() {
    return {}
  },
}).$mount('#app')
