import Vue from 'vue'
import VueCookies from 'vue-cookies'

import apiBase from './base'
import store from '../store'


Vue.use(VueCookies)

async function loginUser(Credentials) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/Token/ValidateLoginFromBody`
  const {
    Email, Password, AcceptTermsOfUse, RememberMe, returnUrl,
  } = Credentials
  // eslint-disable-next-line max-len
  const redirectUrl = returnUrl || `${window.location.origin}/home/${instance}/${event}/${language}`
  const data = {
    Email,
    Password,
    AcceptTermsOfUse,
    RememberMe,
    ContextModel: {
      EContext: 'Event',
      Short: event,
      DefaultReturnUrl: redirectUrl,
    },
  }
  const response = await apiBase.post(url, data)
  if (response?.status === 200) {
    if (response?.data === 'Unconfirmed' || response?.data === 'Lockout' || response?.data === 'Terms') {
      return response.data
    }
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error.length === 0) return 401
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function createUser(Credentials) {
  const returnPage = store.getters['instancesState/getCreateAccountReturnPage']
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const returnUrl = localStorage.getItem('vmxReturnUrl')
  // eslint-disable-next-line max-len
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/Token/CreateAccountFromBody?confirmUrl=${window.location.origin}/confirmation/${instance}/${event}/${language}`
  const redirectUrl = returnUrl || `${window.location.origin}/${returnPage}/${instance}/${event}/${language}`
  const {
    Firstname, Surname, Email, Password, ConfirmPassword, AcceptTermsOfUse,
  } = Credentials
  const data = {
    Firstname,
    Surname,
    Email,
    Password,
    ConfirmPassword,
    AcceptTermsOfUse,
    ContextModel: {
      EContext: 'Event',
      Short: event,
      DefaultReturnUrl: redirectUrl,
    },
  }
  const response = await apiBase.post(url, data)
  if (response?.status === 200) {
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 403
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function transferUser(userData) {
  const instance = store.getters['instancesState/getInstance']
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  // eslint-disable-next-line max-len
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/Token/ManageTransferFromBody?confirmUrl=${window.location.origin}/confirmation/${instance}/${event}/${language}`
  const {
    Email, Encrypted, ManageUserId, Password, ConfirmPassword, AcceptTermsOfUse,
  } = userData
  const data = {
    Email,
    Encrypted,
    ManageUserId,
    Password,
    ConfirmPassword,
    AcceptTermsOfUse,
    ContextModel: {
      EContext: store.getters['ModuleConfig/moduleConfig']?.context,
      Short: store.getters['ModuleConfig/moduleConfig']?.eventShort,
      DefaultReturnUrl: `${window.location.origin}/home/${instance}/${event}/${language}`,
    },
  }

  const response = await apiBase.post(url, data, { withCredentials: true })
  if (response?.status === 200) {
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 403
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function forgotPassword(Credentials) {
  const instance = store.getters['instancesState/getInstance']
  const locale = store.getters['ModuleConfig/moduleConfig']?.language
  const context = store.getters['ModuleConfig/moduleConfig']?.context
  const short = store.getters['ModuleConfig/moduleConfig']?.eventShort
  // eslint-disable-next-line max-len
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/Token/ForgotPasswordFromBody?context=${context}&shortname=${short}&language=${locale}&returnUrl=${window.location.origin}/resetPassword/${instance}/${short}/${locale}`
  const { Email } = Credentials
  const data = {
    Email,
  }
  const response = await apiBase.post(url, data)

  if (response?.status === 200) {
    return response
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function sendConfirmation(Credentials) {
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/Token/SendConfirmationFromBody`
  const { Email } = Credentials
  const data = {
    Email,
    ContextModel: {
      EContext: store.getters['ModuleConfig/moduleConfig']?.context,
      Short: store.getters['ModuleConfig/moduleConfig']?.eventShort,
      DefaultReturnUrl: `${window.location.href}`,
    },
  }
  const response = await apiBase.post(url, data)

  if (response?.status === 200) {
    return response
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }

  return null
}

async function logoutUser(returnUrl) {
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/Token/LougoutFromBody?returnUrl=${returnUrl}`
  const response = await apiBase.post(url)

  if (response?.status === 200) {
    return response?.data
  }
  return null
}

async function confirmEmail(data) {
  const locale = store.getters['ModuleConfig/moduleConfig']?.language
  const {
    code, context, language, shortName, user, returnUrl,
  } = data
  // eslint-disable-next-line max-len
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/token/ConfirmEmailFromBody?userId=${user}&code=${code}&context=${context}&shortName=${shortName}&returnUrl=${returnUrl}&language=${locale}`
  const response = await apiBase.post(url, {})

  if (response?.status === 200) {
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response.status === 404
      || error[error.length - 1].response.status === 401
      || error[error.length - 1].response.status === 400) {
      return error[error.length - 1].response
    }
  }
  return null
}

async function resetPassword(data) {
  const locale = store.getters['ModuleConfig/moduleConfig']?.language
  const {
    context, language, shortName, returnUrl, Email, Password, ConfirmPassword, Code,
  } = data
  // eslint-disable-next-line max-len
  const url = `${store.getters['ModuleConfig/moduleConfig']?.identityUrl}/api/token/ResetPasswordFromBody?context=${context}&shortName=${shortName}&language=${locale}&returnUrl=${returnUrl}`
  const d = {
    Email,
    Password,
    ConfirmPassword,
    Code,
  }
  const response = await apiBase.post(url, d)
  if (response?.status === 200) {
    return response.data
  }

  if (response === undefined) {
    const error = [...store.getters['Error/errors']]
    if (error[error.length - 1].response?.status === 404
      || error[error.length - 1].response?.status === 401
      || error[error.length - 1].response?.status === 400) {
      return error[error.length - 1].response
    }
  }
  return null
}

async function ValidateRegistrationLogin(name, number) {
  const eventId = store.getters['instancesState/getEventId']
  const url = `${store.getters['instancesState/getApiBaseUrl']}/api/Modules/ValidateRegistrationLogin?eventid=${eventId}&surname=${name}&regnumber=${number}`
  const response = await apiBase.post(url)
  if (response) return response

  return null
}

async function getExternalUserData() {
  const externalAuthCode = Vue.$cookies.get('externalAuthorizationCode') ? Vue.$cookies.get('externalAuthorizationCode') : ''
  const eventId = store.getters['instancesState/getEventId']

  let url = `${store.getters['instancesState/getApiBaseUrl']}/api/custominterfaces/EventsAir/EventsAir/ExternalUserData?eventid=${eventId}&externalAuthorizationCode=${externalAuthCode}`
  const response = await apiBase.get(url)
  if (response && response.status === 200) {
    return response.data
  }
  return null
}


export default {
  loginUser,
  createUser,
  forgotPassword,
  sendConfirmation,
  transferUser,
  logoutUser,
  confirmEmail,
  resetPassword,
  ValidateRegistrationLogin,
  getExternalUserData,
}
