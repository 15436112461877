import { mapGetters } from 'vuex'
import userState from '~/states/user'

export default {
  data() {
    return {
      dataTransferModal: false,
      url: '',
    }
  },
  computed: {
    ...mapGetters({
      instance: 'instancesState/getInstance',
    }),
  },
  components: {
    DataTransferModal: () => import('~/components/industryLandingPage/DataTransferModal.vue'),
  },
  methods: {
    checkHref(href) {
      if (href && typeof href !== 'object') {
        const externalDataTransfer = this.$cookies.get('externalDataTransfer')
        if (externalDataTransfer === 'true') {
          // eslint-disable-next-line max-len
          return href?.includes('LeadRedirect') ? `${href}&includeLeadData=True&token=${userState.getUser(this.instance).accessToken}` : href
        }
        if (externalDataTransfer === 'false') {
          // eslint-disable-next-line max-len
          return href?.includes('LeadRedirect') ? `${href}&includeLeadData=False&token=${userState.getUser(this.instance).accessToken}` : href
        }
        return href?.includes('LeadRedirect') ? null : href
      }
      return href
    },
    formatHref(href) {
      const externalDataTransfer = this.$cookies.get('externalDataTransfer')
      if (externalDataTransfer === 'true') {
        // eslint-disable-next-line max-len
        return href?.includes('LeadRedirect') ? `${href}&includeLeadData=True&token=${userState.getUser(this.instance).accessToken}` : href
      }
      if (externalDataTransfer === 'false') {
        // eslint-disable-next-line max-len
        return href?.includes('LeadRedirect') ? `${href}&includeLeadData=False&token=${userState.getUser(this.instance).accessToken}` : href
      }
      return href
    },
    cursorCheck(href) {
      return href ? 'cursor: pointer' : null
    },
  },
}
