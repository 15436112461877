import { mapGetters } from 'vuex'
import headerMixins from '~/mixins/headerMixins'

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      isMobile: false,
    }
  },
  mixins: [
    headerMixins,
  ],
  methods: {
    onResize(e) {
      this.windowWidth = window.innerWidth
      if (this.windowWidth >= this.breakpoint) {
        this.isMobile = false
      }
      else {
        this.isMobile = true
      }
    },
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
    }),
    defaultV2Config() {
      const defaultV2Config = this.configForKey('app_config').configV2
      if ('defaultV2' in defaultV2Config) return defaultV2Config.defaultV2
      return null
    },
    backButton() {
      if (this.defaultV2Config && 'backButton' in this.defaultV2Config && !this.backLinkInNavigation) {
        return this.defaultV2Config.backButton
      }
      if (this.defaultV2Config && 'backButton' in this.defaultV2Config && this.backLinkInNavigation && this.isMobile) {
        return false
      }
      return this.defaultV2Config.backButton
    },
    backButtonBottom() {
      if (this.defaultV2Config && 'backButtonBottom' in this.defaultV2Config && !this.backLinkInNavigation) {
        return this.defaultV2Config.backButtonBottom
      }
      if (this.defaultV2Config && 'backButtonBottom' in this.defaultV2Config && this.backLinkInNavigation && this.isMobile) {
        return false
      }
      return this.defaultV2Config.backButtonBottom
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  mounted() {
    this.onResize()
  },
  components: {
    historyBack: () => import('m-anage.com.vmx-components/src/histroyBack'),
  },
}
