<template>
  <modal
    :show="show"
    @close="closeModal"
  >
    <div class="leadsContentDiv d-flex justify-content-center">
      <div class="exhibitorLeads">
        <div class="my-3 mx-4">
          <h4 class="form-label">
            {{ $t('VMX.send_message_to') }} {{ fullName }}
          </h4>
          <br />
          <template v-if="initForm">
            <b-form-group
              id="email-input-group"
              :label="emailLabel"
              class="form-label"
              label-for="email-input-disabled"
            >
              <b-form-input
                id="email-input-disabled"
                v-model="email"
                type="email"
                disabled
              />
            </b-form-group>
            <b-form-group
              id="subject-input-group"
              :label="subjectLabel"
              class="form-label"
              label-for="subject-input-enabled"
            >
              <b-form-input
                id="subject-input-enabled"
                v-model="subject"
                type="text"
                required
              />
            </b-form-group>
            <b-form-group
              id="message-input-group"
              :label="messageLabel"
              class="form-label"
              label-for="message-input"
            >
              <b-form-textarea
                id="message-inputtextarea-small"
                size="md"
                :placeholder="messagePlaceholder"
                rows="6"
                v-model="message"
              />
            </b-form-group>
            <template>
              <b-button
                variant="primary"
                @click.prevent="sendMail()"
              >
                <span
                  v-html="$t('VMX.send_message_contact')"
                />
              </b-button>
              <b-button
                variant="outline-primary"
                @click.prevent="closeModal()"
              >
                <span v-html="$t('VMX.cancel_message_contact')" />
              </b-button>
            </template>
          </template>
          <template v-else>
            <div class="message-sent-container">
              <font-awesome-icon
                size="4x"
                :icon="['fas', 'check']"
              />
              <h4 class="form-label">
                {{ $t('VMX.message_sent') }}
              </h4>
            </div>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modal from 'm-anage.com.vmx-components/src/Modal'

export default {
  data() {
    return {
      subject: '',
      message: '',
      show: false,
      initForm: true,
    }
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      user: 'user/user',
    }),
    subjectLabel() {
      return this.$t('VMX.subject_label_form')
    },
    emailLabel() {
      return this.$t('VMX.email_label_form')
    },
    messageLabel() {
      return this.$t('VMX.message_label_form')
    },
    messagePlaceholder() {
      return this.$t('VMX.message_placeholder_form')
    },
    fullName() {
      if (this.speaker) {
        return `${this.speaker.Firstname} ${this.speaker.Surname}`
      }
      return ''
    },
    userFullName() {
      if (this.user) {
        return `${this.user.Firstname} ${this.user.Surname}`
      }
      return ''
    },
    config() {
      if (this.configForKey('app_config')) return this.configForKey('app_config')
      return null
    },
    email() {
      return this.user.Email
    },
  },
  mounted() {
    if (this.config?.config[0]?.programme[0]?.contactSpeakerSubject) this.subject = this.config.config[0].programme[0].contactSpeakerSubject
    if (this.speaker) this.show = true
  },
  methods: {
    ...mapActions({
      sendCustomMail: 'contactForm/sendCustomMail',
    }),
    closeModal() {
      this.show = false
      this.$emit('close')
    },
    async sendMail() {
      const ToAddresses = [{ Name: this.fullName, Address: this.speaker.Email }]
      const FromAddresses = [{ Name: this.userFullName, Address: this.user.Email }]
      const Subject = this.subject
      const Content = this.message

      await this.sendCustomMail({ ToAddresses, FromAddresses, Subject, Content }).then((res) => {
        if (res === 200) {
          this.initForm = false
        }
        setTimeout(() => {
          this.closeModal()
        }, 3000)
      })
    },
  },
  props: {
    speaker: {
      type: Object,
      default: null,
    },
  },
  components: { modal },

}
</script>

<style lang="scss" scoped>
  .exhibitorLeads{
    border-radius: 5px !important;
  }
  button {
    margin: 0 10px 0 0;
    border-radius: 5px !important;
  }
  .form-label{
    color: var(--primary);
    font-weight: 700;
  }

  .message-sent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary);
    min-height: 300px;
    padding-top: 70px;
  }
</style>
