import apiBase from './base'
import store from '~/store'

const setName = 'IndustryLandingPageTickerText'

const useApiMockData = process.env.VUE_APP_USE_API_MOCK ? process.env.VUE_APP_USE_API_MOCK == 'true' : false

async function getTickerText(eventId, language) {
  let data = []
  if (useApiMockData) {
    const url = `/api-mock/${setName}.json`
    const response = await apiBase.get(url)
    data = response.data
  } else {
    const url = `${store.getters['instancesState/getApiBaseUrl']}/api/modules/GetResources?eventid=${eventId}&setname=${setName}&language=${language}`
    const response = await apiBase.get(url)
    if (!response) {
      return
    }
    data = response.data
  }
  const texts = {}
  data.forEach((text) => {
    const key = text.resourcename
    texts[key] = text.resourcevalue
  })
  if (!texts.text) {
    return
  }
  const tickerText = texts.text.replace(/^\[|\]$/g, '').split('; ')
  return tickerText
}

export default {
  getTickerText,
}
