<script>
import styled from '@u-rogel/vue-emotion'

export default styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
`()
</script>
