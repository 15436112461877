<template>
  <container class="bannerMenu_my-area_user-section">
    <user-img class="bannerMenu_my-area_user-section-img">
      <img
        :src="userPicture"
        alt="mobile-user-picture"
      />
    </user-img>
    <username class="bannerMenu_my-area_user-section-user">
      <span v-if="welcomeMessage">{{ welcomeMessage }}</span><span>{{ `${userFirstname} ${userSurname}` }}</span>
      <router-link :to="{ name: 'UserFormPage' }">
        {{ $t("VMX.mobile_nav_edit_profile") }}
      </router-link>
      <user-information v-if="!isMobile || mobileDisplayUserInformation" class="user-info-custom"></user-information>
    </username>
  </container>
</template>

<script>
import styled from '@u-rogel/vue-emotion'
import { mapGetters } from 'vuex'
import UserInformation from 'm-anage.com.vmx-components/src/UserCard/UserInformation.vue';

const Container = styled.div`
  padding: 10px 15px;
  color: var(--header-navbar-bg-contrast);
  @media (min-width: 280px) {
    display: flex;
    align-items: center;
    gap: 5px;
}
`()

const UserImg = styled.div`
  img {
    border: solid 2px var(--header-navbar-bg-contrast);
    border-radius: 24px;
    height: 50px;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
    object-fit: contain;
  }
`()

const Username = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  span {
    font-weight: bold;
    font-size: 15px;
  }
  a, a:hover {
    padding: 0;
    font-size: 12px;
    overflow: hidden;
  }
`()

export default {
  name: 'UserInfo',
  components: {
    Container,
    UserImg,
    Username,
    UserInformation,
  },
  props: {
    welcomeMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768 
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768; 
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    mobileDisplayUserInformation() {
      const config = this.$store.getters['config/configForKey']('app_config')?.configV2?.defaultV2
      if (config && 'mobileDisplayUserInformation' in config) {
        return config.mobileDisplayUserInformation
      }
      return false
    },
    userPicture() {
      if (this.user?.ProfilePicturePath) {
        return this.user.ProfilePicturePath
      }
      return '/assets/img/user-no-pic.jpeg'
    },
    userFirstname() {
      if (this.user?.Firstname) {
        return this.user.Firstname
      }
      return ''
    },
    userSurname() {
      if (this.user?.Surname) {
        return this.user.Surname
      }
      return ''
    },
  },
}
</script>