import evaluationAPI from '~/api/evaluation'

function filterByValue(array, value) {
  return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1)
}

function ascendingSessionName(a, b) {
  // Use toUpperCase() to ignore character casing
  let SessionNameA
  let SessionNameB

  if (a.SessionName != null) {
    SessionNameA = a.SessionName.toUpperCase()
  }
  if (b.SessionName != null) {
    SessionNameB = b.SessionName.toUpperCase()
  }
  let comparison = 0
  if (SessionNameA > SessionNameB) {
    comparison = 1
  } else if (SessionNameA < SessionNameB) {
    comparison = -1
  }
  return comparison
}

const sessionForEval = {
  namespaced: true,
  state: {
    sessions: null,
    fetchSessionsPromise: null,
  },
  mutations: {
    setSessions(state, payload) {
      state.sessions = payload
    },
    setFetchPromise(state, payload) {
      state.fetchSessionsPromise = payload
    },

  },
  actions: {
    async loadSessions({ commit, state }) {
      if (state.sessions !== null) {
        return state.sessions
      }
      if (state.fetchSessionsPromise) {
        return state.fetchSessionsPromise
      }
      const fetchSessionsPromise = evaluationAPI.getSessionsForEvaluation().then((s) => {
        commit('setSessions', s)
      })
      commit('setFetchPromise', fetchSessionsPromise)
      return fetchSessionsPromise
    },
    async reloadSessions({ commit }) {
      evaluationAPI.getSessionsForEvaluation().then((s) => {
        commit('setSessions', s)
      })
    },
    async search({ state, dispatch }, payload) {
      if (state.sessions == null) {
        await dispatch('loadSessions')
      }
      let result = state.sessions
      if (payload && payload.filter) {
        result = filterByValue(result, payload.filter)
      }
      if (result && payload && payload.sortBy) {
        if (payload.sortBy === 'title') {
          result = result.sort(ascendingSessionName)
        }
        if (payload.sortBy === 'status') {
          result = result.sort((a, b) => a.EvaluationDone - b.EvaluationDone)
        }
      }
      return result
    },
  },
  getters: {
    sessions(state) {
      return state.sessions
    },
  },
}

export default sessionForEval
