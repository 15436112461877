<template>
  <div
    class="new-footer__box"
  >
    <template
      v-if="footerBox"
    >
      <h6
        v-if="title"
        class="new-footer__box-title"
        v-html="title"
      />
      <span
        v-for="(fd, idx) in footerBox"
        :key="idx"
      >
      <img
            v-if="'iconImagePath' in fd"
            class="app-navigation-top-toggle__icon-image-path"
            :src="fd.iconImagePath"
          />
        <a
          class="new-footer__box-link"
          v-if="fd.url && fd.target"
          @click.prevent="openLink(fd.name, fd.url, fd.target)"
        >
          <span v-html="fd.name" />
        </a>
        <span
          v-else
          class="new-footer__box-text"
          v-html="fd.name"
        />

      </span>
    </template>
    <template
      v-if="footerBoxAdressDetail || footerBoxAdressEmail"
    >
      <h6
        class="new-footer__box-title"
        v-html="title"
      />
      <div
        v-for="(fbad, idx) in footerBoxAdressDetail"
        :key="idx"
      >
        <span v-html="fbad" />
      </div>
      <a
        v-if="footerBoxAdressTelNumber"
        @click.prevent="openNumber(footerBoxAdressTelNumber.name, footerBoxAdressTelNumber.number)"
      >
        <span v-html="footerBoxAdressTelNumber.name" />
      </a>
      <a
        v-if="footerBoxAdressWebsite"
        @click.prevent="openLink(footerBoxAdressWebsite.name, footerBoxAdressWebsite.url, footerBoxAdressWebsite.target)"
      >
        <span v-html="footerBoxAdressWebsite.name" />
      </a>
      <a
        v-if="footerBoxAdressEmail"
        @click.prevent="openEmail(footerBoxAdressEmail.name, footerBoxAdressEmail.url)"
      >
        <span v-html="footerBoxAdressEmail.name" />
      </a>
    </template>
  </div>
</template>
<script>
import utils from '~/utilities/utils'
import trackingApi from '~/api/tracking'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    footerBox: {
      type: Array,
      default: null,
    },
    footerBoxAdressDetail: {
      type: Array,
      default: null,
    },
    footerBoxAdressEmail: {
      type: Object,
      default: null,
    },
    footerBoxAdressWebsite: {
      type: Object,
      default: null,
    },
    footerBoxAdressTelNumber: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openLink(name, url, target = '_blank') {
      trackingApi.trackCustomAction(
        'vmx-footer-box',
        name,
        url,
      )
      if (utils.isObject(url)) {
        this.$router.push(utils.getRouteWithDefaultParams(url.name, url.params, url.hash, url.query))
      } else {
        window.open(url, target)
      }
    },
    openNumber(name, url) {
      trackingApi.trackCustomAction(
        'vmx-footer-box',
        name,
        url,
      )
      window.open(`tel:${url}`, '_system')
    },
    openEmail(name, url) {
      trackingApi.trackCustomAction(
        'vmx-footer-box',
        name,
        url,
      )
      window.open(`mailto:${url}`, '_self')
    },
  },
}
</script>
<style lang="scss">
.new-footer__box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.new-footer__box-title{
  font-size:1.5rem;
}

.new-footer__icons,
.new-footer__box {
    margin: 20px;
}
.new-footer__icons svg {
    transition: all ease-in .25s;
}
.new-footer__box *,
.new-footer__icons * {
    color: var(--footer-text-color);
}

div#page-footer a:hover {
    text-decoration:underline;
}

.new-footer__icons svg:hover {
    transform: scale(1.2);
}

div#page-footer a,
.new-footer__box-title,
.vmx-footer-container .link-box__description h4 {
    font-weight:bold;
}
</style>
