import industryModelApi from '../../../api/industryLandingPage'

const industryModel = {
  namespaced: true,
  state: {
    model: null,
    fetchModelPromise: null,
  },
  mutations: {
    setModel(state, payload) {
      state.model = payload
    },
    setFetchPromise(state, payload) {
      state.fetchModelPromise = payload
    },
  },
  actions: {
    async loadModel({ commit, state }, { event, language }) {
      if (state.model !== null) {
        return state.model
      }
      if (state.fetchConfigPromise) {
        return state.fetchModelPromise
      }
      const fetchModelPromise = industryModelApi.getIndustryModel(event, language).then((model) => {
        commit('setModel', model)
      })
      commit('setFetchPromise', fetchModelPromise)
      return fetchModelPromise
    },
  },
  getters: {
    model(state) {
      return state.model
    },
  },
}

export default industryModel
