<template>
  <div
    class="vmx-cookie-notification"
    v-if="showCookieBanner"
  >
    <b-alert
      v-model="showBottom"
      class="position-fixed fixed-bottom m-0 rounded-0 no-border"
      style="z-index: 10000; padding-left: 0;"
    >
      <b-container fluid>
        <b-row>
          <b-col>
            <div class="cookie-container">
              <div class="text-container">
                <p v-html="$t('VMX.cookie_notification_text')" />
              </div>
              <div class="btn-container">
                <div
                  class="btn-customise"
                  @click.prevent="openOptions()"
                >
                  <a href v-html="$t('VMX.cookie_customise_text')" />
                </div>
                <b-button
                  class="btn-accept"
                  @click="acceptNecessary"
                >
                  <h6 v-html="$t('VMX.cookie_accept_necessary_text')" />
                </b-button>
                <template v-if="showAnalyticsText">
                  <b-button
                    class="btn-accept"
                    @click="acceptAll"
                  >
                    <h6 v-html="$t('VMX.cookie_accept_text')" />
                  </b-button>
                </template>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="showOptions"
          class="border-pad"
        >
          <div class="options-container">
            <b-row>
              <b-col>
                <div class="necessary-cookies">
                  <b-form-checkbox
                    v-model="necessaryChecked"
                    name="always-active"
                    :disabled="true"
                    switch
                    size="lg"
                    class="necessary-checkbox"
                  >
                    <h2 v-html="$t('VMX.cookie_necessary_title')" />
                  </b-form-checkbox>

                  <p v-html="$t('VMX.cookie_necessary_text')" />
                </div>
              </b-col>

              <b-col v-if="showAnalyticsText">
                <div class="analytics-cookies">
                  <b-form-checkbox
                    v-model="analyticChecked"
                    name="toggle-active"
                    class="cb-style"
                    switch
                    size="lg"
                  >
                    <h2 v-html="$t('VMX.cookie_analytics_title')" />
                  </b-form-checkbox>

                  <p v-html="$t('VMX.cookie_analytics_text')" />
                </div>
              </b-col>

              <b-col v-if="showAdobeTypekitText">
                <div class="analytics-cookies">
                  <b-form-checkbox
                    v-model="adobeChecked"
                    name="toggle-active"
                    class="cb-style"
                    switch
                    size="lg"
                  >
                    <h2 v-html="$t('VMX.cookie_adobe_title')" />
                  </b-form-checkbox>

                  <p v-html="$t('VMX.cookie_adobe_text')" />
                </div>
              </b-col>
            </b-row>
            <template v-if="showAnalyticsText || showAdobeTypekitText">
              <b-row>
                <b-col>
                  <div class="btn-container text-right">
                    <b-button
                      class="btn-accept"
                      @click="hide"
                    >
                      <h6 v-html="$t('VMX.cookie_accept_custom_text')" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-row>
      </b-container>
    </b-alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showBottom: false,
      showOptions: false,
      necessaryChecked: true,
      analyticChecked: false,
      adobeChecked: false,
    }
  },
  computed: {
    ...mapGetters({
      configForKey: 'config/configForKey',
      event: 'instancesState/getEvent',
    }),
    showCookieBanner() {
      const [config] = this.configForKey('app_config').config
      const { defaults } = config
      if (defaults && 'showCookieBanner' in defaults) {
        return defaults.showCookieBanner ? defaults.showCookieBanner : defaults.showCookieBanner
      } return true
    },
    showAnalyticsText() {
      const [config] = this.configForKey('app_config').config
      const { defaults } = config
      if (defaults && 'showAnalyticsText' in defaults) {
        return defaults.showAnalyticsText ? defaults.showAnalyticsText : defaults.showAnalyticsText
      }
      return false
    },
    showAdobeTypekitText() {
      const [config] = this.configForKey('app_config').config
      const { defaults } = config
      if (defaults && 'showAdobeTypekitText' in defaults) {
        return defaults.showAdobeTypekitText ? defaults.showAdobeTypekitText : defaults.showAdobeTypekitText
      }
      return false
    },
  },
  methods: {
    openOptions() {
      this.showOptions = !this.showOptions
    },
    hide() {
      if (this.analyticChecked && this.adobeChecked) {
        this.$cookies.set(`${this.event}.cookie`, 'accepted', '14d')
        this.$cookies.set(`${this.event}.cookie.typekit`, 'accepted', '14d')
      } else if (this.analyticChecked) {
        this.$cookies.set(`${this.event}.cookie`, 'accepted', '14d')
        this.$cookies.set(`${this.event}.cookie.typekit`, 'declined', '14d')
      } else if (this.adobeChecked) {
        this.$cookies.set(`${this.event}.cookie.typekit`, 'accepted', '14d')
        this.$cookies.set(`${this.event}.cookie`, 'declined', '14d')
      } else {
        this.$cookies.set(`${this.event}.cookie`, 'declined', '14d')
        this.$cookies.set(`${this.event}.cookie.typekit`, 'declined', '14d')
      }
      this.showBottom = !this.showBottom
      window.location.reload()
    },
    acceptAll() {
      this.$cookies.set(`${this.event}.cookie`, 'accepted', '14d')
      this.$cookies.set(`${this.event}.cookie.typekit`, 'accepted', '14d')
      this.showBottom = !this.showBottom
      window.location.reload()
    },
    acceptNecessary() {
      this.$cookies.set(`${this.event}.cookie`, 'declined', '14d')
      this.$cookies.set(`${this.event}.cookie.typekit`, 'declined', '14d')
      this.showBottom = !this.showBottom
    },
  },
  mounted() {
    if (this.$cookies.get(`${this.event}.cookie`) == null) {
      this.showBottom = !this.showBottom
    }
  },
}
</script>

<style lang="scss">
.vmx-cookie-notification {
  .alert {
    border: 0 !important;
    background: #262626;
    color: #fff;
    display: flex;
    justify-content: space-between;
  }
@media only screen and (max-width: 600px) {
 .cookie-container {
    display: block !important;

    .btn-container {
      display: block !important;

      .btn-accept {
        margin-left: 20px;
        background-color: #fff;
        border-color: var(--header-navbar-bg);
        position:relative;
        top: 9px;
        left: -20px;
        h6{
          margin:0;
          color: #262626;
        }
      }

      .btn-customise {
        cursor: pointer;
        a {
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }
}
  .cookie-container {
    display: flex;
    justify-content: space-between;
    .btn-container {
      display: inline-flex;
      align-items: center;
      .btn-accept {
        margin-left: 20px;
        background-color: #fff;
        border-color: var(--header-navbar-bg);
        h6{
            margin:0;
            color: #262626;

        }
      }

      .btn-customise {
        cursor: pointer;
        a {
          text-decoration: underline;
          color: #fff;
        }
      }
    }
  }

  .options-container {
    margin-top: 20px;
    .custom-control-label::before {
      color: #fff;
      border-color: var(--header-navbar-bg) !important;
      background-color: #fff !important;
    }

    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background-color: var(--header-navbar-bg) !important;
    }
    .btn-container {
      margin-bottom: 1rem;
      .btn-accept {
        background-color: #fff;
        border-color: var(--header-navbar-bg);
        h6{
          margin:0;
          color: #262626;
        }
      }
    }
  }

  .border-pad {
    border-top: 1px solid;
    width: 100vw;
    padding-left: 15px;
    margin-top: 10px;
    padding-top: 15px;
  }

  .necessary-checkbox{
      .custom-control-label::before {
     opacity: 0.2;
    }

    .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #fff;
}
  }
}
</style>
