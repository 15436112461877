import store from '~/store'
import userState from '../states/user'

const redirectToUserform = (params) => {
  const { router, next, condition } = params
  const event = store.getters['instancesState/getEvent']
  const language = store.getters['instancesState/getLanguage']
  const instance = store.getters['instancesState/getInstance']

  if (condition) {
    router.push({
      name: 'UserFormPage',
      params: {
        instance,
        event,
        language,
      },
    })
  } else {
    next()
  }
}

export default async function ({ next, router, to }) {
  const instance = store.getters['instancesState/getInstance']
  const preview = store.getters['instancesState/getPreview']
  const user = await userState.getUser(instance)
  const [backendConfig] = store.getters['config/configForKey']('app_config').config
  const superUser = store.getters['instancesState/getSuperUserToggle']
  if(backendConfig.defaults?.prefillUserform && user && 'useUserFormV2' in backendConfig.defaults && backendConfig.defaults.useUserFormV2) {
    await store.dispatch('user/userFormCompleteV2')
    const userFormFilledV2 = store.getters['user/userFormFilledV2'].length === 0
    redirectToUserform({router: router, next: next, condition: !userFormFilledV2 && !superUser && !preview && user != null && to.name !== 'AssemblyPage'})

  } else if (backendConfig.defaults?.prefillUserform && user) {
    await store.dispatch('user/userFormComplete')
    const userFormFilled = store.getters['user/userFormFilled']
    redirectToUserform({router: router,next: next, condition: !userFormFilled && !superUser && !preview && user != null && to.name !== 'AssemblyPage'})
  }
  next()
  
}
